angular
    .module('garanteasyApp')
    .component('cardShop', {
        templateUrl: 'app/components/cards/shop/shop.component.html',
        controller: CardShopComponentController,
        controllerAs: 'vm',
        bindings: {
            shop: '<'
        }
    });

CardShopComponentController.$inject = ['DataUtils'];

function CardShopComponentController(DataUtils) {
    var vm = this;
    vm.abbreviate = DataUtils.abbreviate;
}
