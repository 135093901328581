(function() {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('WarrantyStatus', WarrantyStatus);

    WarrantyStatus.$inject = ['$resource'];

    function WarrantyStatus ($resource) {
        var resourceUrl =  'api/merchant-dashboard/warrantyStatus/';

        return $resource(resourceUrl, {}, {
            'getWarrantyStatusTotal': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getWarrantyStatusCustomer': {
                method: 'GET',
                url: '/api/merchant-dashboard/customerWarrantyStatus/:customer_id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
