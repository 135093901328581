(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('ProfileController', ProfileController);

    ProfileController.$inject = ['$state', 'Auth'];

    function ProfileController($state, Auth) {
        var vm = this;
        vm.logout = logout;

        function logout() {
            Auth.logout();
            $state.go('home');
        }

    }
})();
