(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('SearchController', SearchController);

    SearchController.$inject = ['$stateParams', '$rootScope', 'CustomerSearch', 'ParseLinks', '$q', 'Dashboard', 'ShopSearch'];

    function SearchController($stateParams, $rootScope, CustomerSearch, ParseLinks, $q, Dashboard, ShopSearch) {
        var vm = this;
        vm.totalCount = 0;
        vm.pagination = {
            documents: {
                totalCount: 0,
                link: {},
                page: 0
            },
            customers: {
                totalCount: 0,
                link: {},
                page: 0
            },
            shops: {
                totalCount: 0,
                link: {},
                page: 0
            },
            products: {
                totalCount: 0,
                link: {},
                page: 0
            },
            warrenties: {
                totalCount: 0,
                link: {},
                page: 0
            }
        }
        vm.totalItems = 0;
        var initialSortState = {
            customers: { field: 'legalName', type: 'asc' },
          documents: { field: 'shop.owner.name', type: 'desc' },
          shops: { field: 'owner.name', type: 'desc' }
        };
        var s = ($stateParams.s ? JSON.parse($stateParams.s) : initialSortState);
        while (typeof s === 'string' || s instanceof String) {
            s = JSON.parse(s);
        }
        vm.model = {
            documents: $stateParams.documents === "true",
            shops: $stateParams.shops === "true",
            customers: $stateParams.customers === "true",
            products: $stateParams.products === "true",
            warrenties: $stateParams.warrenties === "true",
            search: $stateParams.search,
            page: $stateParams.page || 0,
            size: $stateParams.size || 20,
            s: s,
            documentsList: [],
            customersList: [],
            shopsList: [],
            productsList: [],
            warrentiesList: [],
            list: []
        };

        vm.gridView = $rootScope.gridView;
        vm.isLoading = false;

        function loadAllCustomers(data) {
            var d = $q.defer();
            if (data.page > vm.pagination.customers.link.last || !vm.model.customers) {
                d.resolve([]);
            } else {
                CustomerSearch.getCustomerSearch(data,
                    function (result, headers) {
                        vm.pagination.customers.link = ParseLinks.parse(headers('link'));
                        vm.pagination.customers.totalCount = +headers('X-Total-Count');
                        d.resolve(result);
                    }, function (result, headers) {
                        d.resolve([]);
                    });
            }
            return d.promise;
        }
        function loadAllReceipts(data) {
            var d = $q.defer();
            if (data.page > vm.pagination.documents.link.last || !vm.model.documents) {
                d.resolve([]);
            } else {
                Dashboard.searchReceipts(data, function (result, headers) {
                    vm.pagination.documents.link = ParseLinks.parse(headers('link'));
                    vm.pagination.documents.totalCount = +headers('X-Total-Count');
                    d.resolve(result);
                }, function (result, headers) {
                    d.resolve([]);
                });
            }
            return d.promise;
        }

        function loadAllShop(data) {
            var d = $q.defer();
            if (data.page > vm.pagination.shops.link.last || !vm.model.shops) {
                d.resolve([]);
            } else {
                ShopSearch.query(data, function (result, headers) {
                    vm.pagination.shops.link = ParseLinks.parse(headers('link'));
                    vm.pagination.shops.totalCount = +headers('X-Total-Count');
                    d.resolve(result);
                }, function (result, headers) {
                    d.resolve([]);
                });
            }
            return d.promise;
        }

        function loadAll() {
            vm.isLoading = true;
            if (!vm.model.search) {
                vm.model.list = [];
                return;
            }
            var data = {
                query: vm.model.search,
                page: vm.model.page,
                size: vm.model.size
            };
            var dataCustomer = Object.assign({}, data, {
                sort: vm.model.s.customers.field + ',' + vm.model.s.customers.type
            });
            var dataDocuments = Object.assign({}, data, {
                sort: vm.model.s.documents.field + ',' + vm.model.s.documents.type
            });
            var dataShops = Object.assign({}, data, {
                sort: vm.model.s.shops.field + ',' + vm.model.s.shops.type
            });
            $q.all([
                loadAllCustomers(dataCustomer),
                loadAllReceipts(dataDocuments),
                loadAllShop(dataShops)

            ]).then(function (data, headers) {
                vm.model.customersList = vm.model.customersList.concat(data[0].map(function (x) { return { type: "customer", obj: x }; }));
                vm.model.documentsList = vm.model.documentsList.concat(data[1].map(function (x) { return { type: "document", obj: x }; }));
                vm.model.shopsList = vm.model.shopsList.concat(data[2].map(function (x) { return { type: "shop", obj: x }; }));
                // vm.model.warrentiesList = vm.model.warrentiesList.concat(data[3].map(function (x) { return { type: "warrenty", obj: x }; }));
                vm.model.list = vm.model.documentsList.concat(vm.model.shopsList).concat(vm.model.customersList);
                vm.totalCount = +vm.pagination.documents.totalCount + vm.pagination.shops.totalCount + vm.pagination.customers.totalCount;
            }).then(function () {
                vm.isLoading = false;
            }).catch(function (e) {
                vm.isLoading = false;
            });
        }
        vm.loadPage = function (page) {
            if (+vm.model.page !== page) {
                vm.model.page = page;
                loadAll();
            }
        };
        loadAll();
        vm.disableInfiniteScroll = function () {
            return vm.isLoading;
        };
    }
})();
