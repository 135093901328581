(function() {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('PurchasedProduct', PurchasedProduct);

    PurchasedProduct.$inject = ['$resource', 'DateUtils'];

    function PurchasedProduct ($resource, DateUtils) {
        var resourceUrl =  'api/purchased-products/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.deliveryDate = DateUtils.convertLocalDateFromServer(data.deliveryDate);
                        data.giftNotificationDate = DateUtils.convertLocalDateFromServer(data.giftNotificationDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                	var dataToSend = angular.copy(data);
                	dataToSend.deliveryDate = DateUtils.convertLocalDateToServer(dataToSend.deliveryDate);
                	dataToSend.giftNotificationDate = DateUtils.convertLocalDateToServer(dataToSend.giftNotificationDate);
                    return angular.toJson(dataToSend);
                }
            },
            'updateList': {
                method: 'PUT',
                url: 'api/purchased-products/purchased-products-list',
                transformRequest: function (data) {
                	angular.forEach(data, function (item) {
                        item.deliveryDate = DateUtils.convertLocalDateToServer(item.deliveryDate);
                        item.giftNotificationDate = DateUtils.convertLocalDateToServer(item.giftNotificationDate);
                    })
                    var dataToSend = angular.copy(data);
                    return angular.toJson(dataToSend);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                	var dataToSend = angular.copy(data);
                	dataToSend.deliveryDate = DateUtils.convertLocalDateToServer(dataToSend.deliveryDate);
                	dataToSend.giftNotificationDate = DateUtils.convertLocalDateToServer(dataToSend.giftNotificationDate);
                    return angular.toJson(dataToSend);
                }
            }
        });
    }
})();
