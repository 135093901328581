(function() {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('Card', Card);

    Card.$inject = ['$resource', 'DateUtils'];

    function Card ($resource, DateUtils) {
        var resourceUrl =  'api/cards/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryActive': {
                url: 'api/cards/active/:receipt',
                method: 'POST',
                isArray: true,
                /*params: {receipt: "-1"},*/
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    return angular.toJson(dataToSend);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        console.log(data);
                    }
                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.activationDate = DateUtils.convertLocalDateFromServer(data.activationDate);
                        data.expirationDate = DateUtils.convertLocalDateFromServer(data.expirationDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                	var dataToSend = angular.copy(data);
                    if(dataToSend.activationDate) {
                        dataToSend.activationDate = DateUtils.convertLocalDateToServer(dataToSend.activationDate);
                    }
                    if(dataToSend.expirationDate) {
                        dataToSend.expirationDate = DateUtils.convertLocalDateToServer(dataToSend.expirationDate);
                    }
                    return angular.toJson(dataToSend);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {

                	var dataToSend = angular.copy(data);
                	if(dataToSend.activationDate) {
                        dataToSend.activationDate = DateUtils.convertLocalDateToServer(dataToSend.activationDate);
                    }
                    if(dataToSend.expirationDate) {
                        dataToSend.expirationDate = DateUtils.convertLocalDateToServer(dataToSend.expirationDate);
                    }
                    return angular.toJson(dataToSend);

                },
                transformResponse: function(data, headers){
                    var response = {};
                    response = angular.fromJson(data);
                    return response;
                }
            },

            'getCards4Customer' : {
            url: 'api/cards/active/:customerId',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                if (data) {
                    data = angular.fromJson(data);
                }
                return data;
            }
        }
        });
    }
})();
