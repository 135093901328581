(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('WarrantyClaimNewController', WarrantyClaimNewController);

  WarrantyClaimNewController.$inject = ['$stateParams', '$state', 'WarrantyClaim', 'Principal', 'SelectedMerchantService', 'AlertService', "Receipt", "entity", '$translate', 'CmsService'];

  function WarrantyClaimNewController($stateParams, $state, WarrantyClaim, Principal, SelectedMerchantService, AlertService, Receipt, entity, $translate, CmsService) {
    var vm = this;
    vm.ppId;
    vm.cancelClick = cancelClick;
    vm.onSubmit = onSubmit;
    vm.account;
    vm.message;
    vm.hiddenField;
    vm.receipt = entity;
    vm.merchant = entity.shop.merchant;
    vm.shop = entity.shop;
    vm.clientData = {
      "clientName": (vm.receipt.customer.user.login !== vm.receipt.customer.legalName ? vm.receipt.customer.legalName : ''),
      "clientEmail": vm.receipt.customer.user.login,
      "clientPhone": "",
      "cardNumber": vm.receipt.cardNumber
    };
    vm.reasons = [
      "EMAIL_UPDATE",
      "EMAIL_CHANGE",
      "RECEIPT",
      "DELETE_PROFILE",
      "OTHER"
    ];
    vm.warrantyClaim = { receiptId: vm.receipt.id };
    vm.loadCmsShop = function (lang, cmsReference) {
      if (cmsReference) {
        CmsService.getShop(lang, cmsReference).then(function (res) {
          vm.cmsShop = res.data;
          var address = '';

          if (res.data.address_address_line1) {
            address += ' ' + res.data.address_address_line1;
          }
          if (res.data.address_address_line2) {
            address += ' ' + res.data.address_address_line2;
          }
          if (res.data.address_postal_code) {
            address += ' ' + res.data.address_postal_code;
          }
          if (res.data.address_locality) {
            address += ' ' + res.data.address_locality;
          }
          if (res.data.address_country_code) {
            address += ' ' + res.data.address_country_code;
          }

          vm.cmsShop.address = address;

        });
      } else {
        console.log('cmsReference is null', vm.shop);
      }
    };
    init();

    function init() {
      if ($stateParams && $stateParams.receiptId) {
        vm.ppId = $stateParams.receiptId;
      }
      Principal.identity().then(function (account) {
        vm.account = account;
      });
      vm.loadCmsShop($translate.use(), vm.shop.cmsReference);
    }

    function onSubmit() {
      vm.hiddenField = "";
      if ((vm.account || {}).login) {
        vm.hiddenField += "sender: " + vm.account.login + '<br/>';
      }
      if ((vm.shop || {}).email) {
        vm.hiddenField += "shopEmail: " + vm.shop.email + '<br/>';
      }
      if (((vm.shop || {}).address || {}).descFull) {
        vm.hiddenField += "address: " + vm.shop.address.descFull + '<br/>';
      }
      if (((vm.shop || {}).shopSignboard || {}).code) {
        vm.hiddenField += "shopSignboard: " + vm.shop.shopSignboard.code + '<br/>';
      }
      if ((vm.shop || {}).mallName) {
        vm.hiddenField += "mallName: " + vm.shop.mallName + '<br/>';
      }
      if (((vm.shop || {}).merchant || {}).companyName) {
        vm.hiddenField += "companyName: " + vm.shop.owner.name + '<br/>';
      }
      if ((vm.receipt || {}).receiptNumber) {
        vm.hiddenField += "receiptNumber: " + vm.receipt.receiptNumber + '<br/>';
      }
      if ((vm.receipt || {}).buyingDate) {
        vm.hiddenField += "receiptDate: " + vm.receipt.buyingDate + '<br/>';
      }
      if ((vm.receipt || {}).transactionAmount) {
        vm.hiddenField += "receiptAmount: " + vm.receipt.transactionAmount + '<br/>';
      }
      if ((vm.clientData || {}).clientName) {
        vm.hiddenField += "clientName: " + vm.clientData.clientName + '<br/>';
      }
      if ((vm.clientData || {}).clientEmail) {
        vm.hiddenField += "clientEmail: " + vm.clientData.clientEmail + '<br/>';
      }
      if ((vm.clientData || {}).clientPhone) {
        vm.hiddenField += "clientPhone: " + vm.clientData.clientPhone + '<br/>';
      }
      if ((vm.clientData || {}).cardNumber) {
        vm.hiddenField += "cardNumber: " + vm.clientData.cardNumber + '<br/>';
      }

      vm.warrantyClaim.message = vm.hiddenField
        + '<br/>Messaggio dell\'utente:<br/>' + vm.message;
      WarrantyClaim.save(vm.warrantyClaim, onSaveSuccess, onSaveError);
    }
    function onSaveSuccess(result) {
      AlertService.success("Richiesta inviata correttamente");
      $state.go('receipt/detail', {
        id: vm.ppId
      });
    }


    function onSaveError(error) {
      AlertService.error("Errore nell'invio della richiesta");
    }
    function cancelClick() {
      $state.go('receipt/detail', {
        id: vm.ppId
      });
    }
  }
})();
