'use strict';
(function () {

  angular
    .module('garanteasyApp')
    .factory('CmsService', CmsService);

  CmsService.$inject = ['$http', 'CMS_URI', '$log', 'cmsViewMode'];

  function CmsService($http, CMS_URI, $log, cmsViewMode) {
    var service = {
      getCmsData: getCmsData,
      getShop: getShop,
      getNodeData: getNodeData,
      getData: getData,
      getLicenceData: getLicenceData,
      searchBrandFromCode: searchBrandFromCode,
      getBrandImageData: getBrandImageData,
      getBrandByCode: getBrandByCode,
      call: call
    };

    return service;

    function getNodes() {

    }
    function getCmsData(view, lang, pMerchantId) {
      var merchantId = pMerchantId || 'f58052e9-75c9-4320-bf40-2cbfaad1b86e';
      var uri;
      if (view == cmsViewMode.faq) {
        uri = CMS_URI + '/api/view/faq/BUSINESS/FAQ/all/' + (lang || 'it');
      }
      else if (view == cmsViewMode.advertising) {
        uri = CMS_URI + (lang || 'it') + '/api/view/merchant-advertising/' + merchantId;
      }
      else if (view == cmsViewMode.video) {
        uri = CMS_URI + (lang || 'it') + '/api/view/merchant-video/' + merchantId;
      }
      else if (view == cmsViewMode.docs) {
        uri = CMS_URI + (lang || 'it') + '/api/view/merchant-documents/' + merchantId;
      } else {
        uri = CMS_URI + (lang || 'it') + '/api/view/faq/merchant';
      }
      return $http.get(uri);
    }
    function getShop(lang, cmsReference) {
      var uri = CMS_URI + (lang || 'it') + '/api/view/shop/' + cmsReference;
      return $http.get(uri);
    }

    function getNodeData(lang, node, fields) {
      if (lang && node) {
        var uri = CMS_URI + lang + '/api/node/' + node + '?filter[destinatari.name]=Consumer';
        if (fields && fields.length) {
          uri += '&fields[node--' + node + ']=' + fields.join(',');
        }
        return call(uri);
      }
    }

    function getData(lang, node, id, fields) {
      if (lang && node && id) {
        var uri = CMS_URI + lang + '/api/node/' + node + '/' + id + '?filter[destinatari.name]=Consumer';
        if (fields && fields.length) {
          uri += '&fields[node--' + node + ']=' + fields.join(',');
        }
        return call(uri);
      }
    }

    function searchBrandFromCode(lang, code) {
      if (lang && code) {
        var uri = CMS_URI + lang + '/api/node/censimento_brand/?filter[code]=' + code;
        return call(uri);
      }
    }

    function getBrandImageData(lang, cmsBrandReference) {
      if (cmsBrandReference) {
        var uri = CMS_URI + lang + '/api/node/censimento_brand/' + cmsBrandReference + '/immagine_del_brand_completo';
        return call(uri);
      }
    }

    function getLicenceData(lang) {
      var uri = CMS_URI + lang + '/api/view/merchant/legal/GARANTEASY/INSTRUCTIONS/BUSINESS/ECOMMERCE_ONBOARDING/WORLD+IT/' + lang;
      console.log(uri);
      return call(uri).then(function (data) {
        var uniques = [];
        if (Array.isArray(data)) {
          data.forEach(function (licenseData) {
            var found = uniques.find(function (item) {
              return item.id === licenseData.id;
            });
            if (!found) {
              uniques.push(licenseData);
            }
          });
        }
        return uniques;
      });
    }

    function getBrandByCode(brandCode) {
      if (brandCode) {
        var uri = CMS_URI + 'api/view/brand-code/' + brandCode;
        return call(uri);
      }
    }


    function call(uri) {
      return $http.get(uri)
        .then(getData)
        .catch(error);

      function getData(response) {
        return response.data.data || response.data;
      }

      function error(error) {
        $log.error('XHR Failed for cms.' + error.data);
      }
    }

  }
})();
