angular
    .module('garanteasyApp')
    .component('cardProduct', {
        templateUrl: 'app/components/cards/product/product.component.html',
        controller: CardProductComponentController,
        controllerAs: 'vm',
        bindings: {
            product: '<'
        }
    });

CardProductComponentController.$inject = ['DataUtils'];

function CardProductComponentController(DataUtils) {
    var vm = this;
    vm.abbreviate = DataUtils.abbreviate;
}
