(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('BarCodeCameraReaderController', BarCodeCameraReaderController);

    BarCodeCameraReaderController.$inject = ['$scope', 'AlertService', '$stateParams', 'ShopService', '$uibModalInstance'];

    function BarCodeCameraReaderController($scope, AlertService, $stateParams, ShopService, $uibModalInstance) {
        var vm = this;
        vm.cameraIsEnabled = false;
        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.save = function () {
            onSaveSuccess();
        }


        function onSaveSuccess(result) {
            $uibModalInstance.close('ok');
        }

        function onSaveError(error) {
            AlertService.error(error.data.message);
        }

        vm.onFileImputChange = function (e) {
            if (e.target.files && e.target.files.length) {
                vm.decode(URL.createObjectURL(e.target.files[0]));
            }
        }
        vm.decode = function (src) {
            var self = vm,
                config = $.extend({}, self.state, { src: src });

            Quagga.decodeSingle(config, function (result) { });
        }

        navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
        window.URL = window.URL || window.webkitURL || window.mozURL || window.msURL;

        function getUserMedia(constraints, success, failure) {
            navigator.getUserMedia(constraints, function (stream) {
                var videoSrc = (window.URL && window.URL.createObjectURL(stream)) || stream;
                success.apply(null, [videoSrc]);
            }, failure);
        }


        function initCamera(constraints, video, callback) {
            getUserMedia(constraints, function (src) {
                video.src = src;
                video.addEventListener('loadeddata', function () {
                    var attempts = 10;

                    function checkVideo() {
                        if (attempts > 0) {
                            if (video.videoWidth > 0 && video.videoHeight > 0) {
                                console.log(video.videoWidth + "px x " + video.videoHeight + "px");
                                video.play();
                                callback();
                            } else {
                                window.setTimeout(checkVideo, 100);
                            }
                        } else {
                            vm.cameraIsEnabled = false;
                            callback('Unable to play video stream.');
                        }
                        attempts--;
                    }

                    checkVideo();
                }, false);
            }, function (e) {
                vm.cameraIsEnabled = false;
                console.log(e);
            });
        }

        function copyToCanvas(video, ctx) {
            (function frame() {
                ctx.drawImage(video, 0, 0);
                window.requestAnimationFrame(frame);
            }());
        }

        var constraints = {
            video: {
                mandatory: {
                    minWidth: 1280,
                    minHeight: 720
                }
            }
        }
        var video = angular.element(document.createElement('video'));
        var canvas = angular.element(document.createElement('canvas'));

        angular.element(document.body).append(video);
        angular.element(document.body).append(canvas);

        initCamera(constraints, video, function () {
            canvas.setAttribute('width', video.videoWidth);
            canvas.setAttribute('height', video.videoHeight);
            copyToCanvas(video, canvas.getContext('2d'));
        });
    }
})();
