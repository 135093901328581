(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('PurchasedProductController', PurchasedProductController);

    PurchasedProductController.$inject = ['$scope', '$state', 'Principal', 'PurchasedProduct', 'PurchasedProductSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function PurchasedProductController($scope, $state, Principal, PurchasedProduct, PurchasedProductSearch, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;
        $scope.stateData.vm = vm;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.isAdmin = null;
        vm.currentUserId;

        checkAccess();

        function checkAccess() {
            Principal.identity().then(function (account) {
                if (!Principal.isAuthenticated()) {
                    $state.go("home");
                    return;
                }
                vm.currentUserId = account.id;
                Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                    if (result) {
                        vm.isAdmin = true;
                    } else {
                        vm.isAdmin = false;
                    }
                });
            });
        }

        vm.totalNumExpired = 0;
        vm.totalNumValid = 0;
        vm.totalNumExpiring = 0;

        loadAll();

        function loadAll() {
            if (pagingParams.search) {
                PurchasedProductSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                PurchasedProduct.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.purchasedProducts = data;
                vm.page = pagingParams.page;
                for (var i = 0; i < data.length; i++) {
                    vm.totalNumExpired += data[i].totalWarranties.numExpired;
                    vm.totalNumValid += data[i].totalWarranties.numValid;
                    vm.totalNumExpiring += data[i].totalWarranties.numExpiring;
                }
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        /*        var unsubscribe = $rootScope.$on('garanteasyApp:productUpdate', function(event, result) {
                    vm.product = result;
                });
                $scope.$on('$destroy', unsubscribe);
                $scope.$on('$destroy', function(event, next, current) {
                    $('.zoomContainer').remove();
                });*/
    }
})();
