(function () {
  "use strict";

  angular
    .module("garanteasyApp")
    .controller("ReceiptDialogControllerPage", ReceiptDialogControllerPage);

  ReceiptDialogControllerPage.$inject = [
    "$timeout",
    "$scope",
    "$stateParams",
    "$q",
    "entity",
    "Principal",
    "Receipt",
    "Customer",
    "Contact",
    "PurchasedProduct",
    "ShopService",
    "ShopLookup",
    "Merchant",
    "MerchantLookup",
    "BrandLookup",
    "Card",
    "$state",
    "DataUtils",
    "ReceiptFile",
    "ProductLookup",
    "Product",
    "bsScreenSize",
    "CustomerSearch",
    "PrivacyPolicy",
    "Register",
    "APPKEY",
    "$log",
    "$translate"
  ];

  function ReceiptDialogControllerPage(
    $timeout,
    $scope,
    $stateParams,
    $q,
    entity,
    Principal,
    Receipt,
    Customer,
    Contact,
    PurchasedProduct,
    ShopService,
    ShopLookup,
    Merchant,
    MerchantLookup,
    BrandLookup,
    Card,
    $state,
    DataUtils,
    ReceiptFile,
    ProductLookup,
    Product,
    bsScreenSize,
    CustomerSearch,
    PrivacyPolicy,
    Register,
    APPKEY,
    $log,
    $translate
  ) {
    var vm = this;
    vm.ssize = bsScreenSize.state;
    vm.receipt = entity;
    vm.receipt.purchasedProducts = [];
    vm.receipt.receiptType = "RECEIPT";
    vm.clear = clear;
    vm.datePickerOpenStatus = {};
    vm.openCalendar = openCalendar;
    vm.save = save;
    vm.addProductTest = addProductTest;
    vm.images = [];
    vm.documents = [];
    vm.customer = {};
    vm.merchantPrivacyPolicies = [];
    vm.isValidForm = false;
    vm.typeCodes = ["GTIN", "EAN", "UPC"];
    vm.isDisabled = isDisabled;
    vm.contPurchasedProducts = 0;
    vm.receipt.purchasedProducts = [];
    getAccount();
    getAccountMerchants();
    vm.disableAddProduct = disableAddProduct;
    vm.ccc13 = ccc13;
    vm.isValidCode = false;
    // vm.sysdate = new Date();
    vm.receipt.buyingDate = new Date();
    vm.receipt.purchasedProduct = {};
    vm.receiptFiles = [];

    vm.isNewCustomer = false;
    vm.isNewProduct = false;

    function getAccountMerchants() {
      Receipt.getMerchants({},
        function (result) {
          vm.accountMerchants = result;
        },
        function (response) {
          if (response.status === 404) {
            vm.accountMerchants = {};
          }
        }
      );
    }

    vm.getShopByMerchants = function () {
      if (angular.isDefined(vm.merchant)) {
        ShopService.getShopByMerchants({ merchant_id: vm.merchant.id },
          function (result) {
            vm.shops = result;
          },
          function (response) {
            if (response.status === 404) {
              vm.shops = {};
            }
          }
        );
      }
    };

    function getAccount() {
      Principal.identity().then(function (account) {
        vm.account = account;
        vm.isAuthenticated = Principal.isAuthenticated;
      });
    }

    vm.customers = Customer.query({}, function (result) {
      vm.receipt.customer = result[0];
    });

    /*vm.cards = Card.queryActive(vm.receipt);*/
    vm.contacts = Contact.queryActive();
    // vm.shops = ShopService.query({ filter: "receipt-is-null" });
    // $q.all([vm.receipt.$promise, vm.shops.$promise])
    //     .then(function() {
    //         if (!vm.receipt.shopId) {
    //             return $q.reject();
    //         }
    //         return ShopService.get({ id: vm.receipt.shopId }).$promise;
    //     })
    //     .then(function(shop) {
    //         vm.shops.push(shop);
    //     });

    /* $q.all([vm.receipt.$promise, vm.cards.$promise]).then(function() {
                 if (!vm.receipt.buyingDateng || !vm.customerId) {
                     return $q.reject();
                 }
                 return Card.queryActive({receipt : vm.receipt}).$promise;
             }).then(function(card) {
                 vm.cards.push(card);
             });*/

    $timeout(function () {
      angular.element(".form-group:eq(1)>input").focus();
    });
    vm.isValidInput = isValidInput;
    vm.validMerchant = null;
    vm.validAddress = null;

    // Le ricevute inviate da un negozio ufficiale (o in attesa di conferma dopo un claim) sono quasi totalmente readonly
    vm.receipt.source = "MERCHANT";
    vm.isOfficial = true;
    vm.isAdmin = null;
    vm.checkAccess = checkAccess;
    checkAccess();

    function checkAccess() {
      Principal.identity().then(function (account) {
        if (!Principal.isAuthenticated()) {
          $state.go("home");
          return;
        }
        Principal.hasAuthority("ROLE_ADMIN").then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });
      });
    }

    function clear() {
      $uibModalInstance.dismiss("cancel");
    }

    $scope.resetShop = function () {
      vm.receipt.shop = null;
    };

    $scope.resetMerchant = function () {
      if (vm.receipt.shop !== null) {
        vm.receipt.shop.merchant = null;
      }
    };

    $scope.loadShops = function (searchQuery) {
      if (angular.isDefined(vm.merchant)) {
        if (searchQuery && searchQuery.trim().length > 1) {
          ShopService.getShopByMerchants({ merchant_id: vm.merchant.id, query: searchQuery },
            function (result) {
              $scope.shops = result;
            }
          );
        } else {
          ShopService.getShopByMerchants({ merchant_id: vm.merchant.id }, function (
            result
          ) {
            $scope.shops = result;
          });
        }

        PrivacyPolicy.regMerchantCode({ merchant_code: vm.merchant.code },
          onPpSuccess,
          onPpError
        );

        $translate('garanteasyApp.receipt.descriptionPlaceholder', vm.merchant).then(function (headline) {
          vm.receipt.description = headline;
        }, function (translationId) {
          $log.error("Missing translation for", translationId);
        });
      }
    };

    $scope.loadBrands = function (searchQuery) {
      BrandLookup.query({ query: searchQuery },
        function (result) {
          $scope.brands = result;
        }
      );
    };

    $scope.loadMerchants = function (searchQuery) {
      if (searchQuery) {
        MerchantLookup.query({ query: searchQuery },
          function (result) {
            $scope.merchants = result;
          },
          function (response) {
            if (response.status === 404) {
              $scope.merchants = Merchant.query({ isActive: true });
            }
          }
        );
      } else {
        $scope.merchants = Merchant.query({ isActive: true });
      }
    };

    $scope.loadActiveCards = function () {
      if (vm.receipt.customer) vm.receipt.customerId = vm.receipt.customer.id;
      if (vm.receipt.customer && vm.receipt.buyingDate)
        vm.cards = Card.queryActive(vm.receipt);
    };

    $scope.loadProducts = function (searchQuery) {
      if (searchQuery && searchQuery.trim().length > 0) {
        ProductLookup.query({ query: searchQuery },
          function (result) {
            $scope.products = result;
          },
          function (response) {
            if (response.status === 404) {
              $scope.products = Product.query({ isActive: true },
                onSuccess,
                onError
              );
            }
          }
        );
      } else {
        ProductLookup.query({});
      }
    };

    function onSuccess(result) {
      $scope.products = result;
      vm.isSaving = false;
    }

    function onError(result) {
      // $uibModalInstance.close(result);
    }

    // Constraints beyond the ones in the form
    function isValidInput() {
      if (
        vm.receipt.receiptType != null &&
        vm.receipt.description != null &&
        vm.receipt.buyingDate != null &&
        vm.receipt.receiptNumber != null &&
        vm.receipt.shop != null
      ) {
        return true;
      }

      /*        	// And either ecommerce url or address must be valid
                            vm.validAddress =
                              (vm.receipt.shop.ecommerceUrl != null && vm.receipt.shop.ecommerceUrl.trim().length > 0)
                              ||
                              (vm.receipt.shop.address != null &&
                              vm.receipt.shop.address.street != null && vm.receipt.shop.address.street.trim().length > 0
                              && vm.receipt.shop.address.city != null && vm.receipt.shop.address.city.trim().length > 0);
                            return vm.validMerchant && vm.validAddress;*/
    }

    function onSaveSuccess(result) {
      if (vm.receiptFile != null) {
        vm.receiptFile.receiptId = result.id;
        ReceiptFile.save(vm.receiptFile, function (result) {
          vm.images.push(result);
          if (result) {
            vm.image = result;
            vm.images.push(result);
          }
        });
      }
      $scope.$emit("garanteasyApp:receiptUpdate", result);
      vm.isSaving = false;
      $state.go("receipt", null, { reload: true });
    }

    function onSaveError(error) {
      vm.error = error;
      vm.isSaving = false;
    }

    vm.datePickerOpenStatus.buyingDate = false;

    function openCalendar(date) {
      vm.datePickerOpenStatus[date] = true;
    }

    vm.canShow = function (contentType) {
      return (
        contentType != null &&
        contentType.toLowerCase().indexOf("image") == 0 &&
        contentType.toLowerCase().indexOf("tiff") == -1
      );
    };

    vm.uploadFile = function ($file, isProduct) {
      vm.receiptFile = {};
      vm.byteSize = DataUtils.byteSize;
      vm.openFile = DataUtils.openFile;
      vm.fileTooLarge = false;
      if ($file && $file.$error === 'pattern') {
        return;
      }
      if ($file) {
        if ($file.size > 5120000) {
          vm.fileTooLarge = true;
          // Reset data in case there was a previous image selected
          vm.receiptFile.receiptId = null;
          vm.receiptFile.fileData = null;
          vm.receiptFile.contentType = null;
          vm.receiptFile.type = null;
        } else {
          vm.fileTooLarge = false;
          DataUtils.toBase64($file, function (base64Data) {
            $scope.$apply(function () {
              // vm.receiptFile.receiptId = vm.receipt.id;
              vm.receiptFile.fileData = base64Data;
              vm.receiptFile.fileContentType = $file.type;
              vm.receiptFile.fileName = $file.name;
              if (isProduct) {
                if (!vm.receipt.purchasedProduct.receiptFiles) {
                  vm.receipt.purchasedProduct.receiptFiles = [];
                }
                vm.receipt.purchasedProduct.receiptFiles.push(vm.receiptFile);
              } else {
                vm.receiptFiles.push(vm.receiptFile);
              }
              /*if(item.fileContentType && item.fileContentType.toLowerCase().indexOf("image") == 0){*/
              //vm.imageNotPersistent = vm.receiptFile;
              //}
            });
          });
        }
      }
    };

    vm.getImageTest = function (data) {
      return "data:image/jpeg;base64," + data;
    };

    var imagesPath = {};
    vm.getImageUrl = function (receiptFile) {
      if (!receiptFile || !receiptFile.id) {
        imagesPath[receiptFile.id] = null;
      } else if (_.isUndefined(imagesPath[receiptFile.id])) {
        imagesPath[receiptFile.id] =
          "api/receipt-files/content/" +
          receiptFile.id +
          "?width=-&height=377&token=" +
          AuthServerProvider.getToken();
      }
      return imagesPath[receiptFile.id];
    };
    if (vm.receipt.receiptFiles != null && vm.receipt.receiptFiles.length > 0) {
      vm.receipt.receiptFiles.forEach(function (item) {
        if (
          item.fileContentType &&
          item.fileContentType.toLowerCase().indexOf("image") == 0
        )
          vm.images.push(item);
        else vm.documents.push(item);
      });
    }

    if (vm.images.length > 0) {
      vm.image = vm.images[0];
    }

    vm.changeMainImage = function (image) {
      /*$('.zoomContainer').remove();*/
      vm.image = image;
    };

    vm.deleteImage = function (image) {
      ReceiptFile.delete({ id: image.id }, function () {
        var ind = vm.images.indexOf(image);
        vm.images.splice(ind, ind + 1);
        /*$('.zoomContainer').remove();*/
        vm.image = vm.images[0];
      });
    };

    $scope.loadCustomers = function (searchQuery) {
      if (searchQuery && searchQuery.trim().length > 1) {
        CustomerSearch.query({ query: searchQuery },
          function (result) {
            $scope.customers = result;
          },
          function (response) {
            if (response.status === 404) {
              $scope.customers = Customer.query();
            }
          }
        );
      } else {
        Customer.query({}, function (result) {
          $scope.customers = result;
        });
      }
    };

    // se il customer è stato selezionato in pagina bene, altrimenti settare le privacy policy ed inviare tutto al salvataggio

    function save() {
      vm.receipt.creationDate = vm.receipt.buyingDate;
      // temporaneo per evitare bug deserialize
      if (vm.receipt && vm.receipt.shop && vm.receipt.shop.shopSignboard) {
        delete vm.receipt.shop.shopSignboard;
      }

      if (vm.customerSelected) {
        vm.receipt.customerId = vm.customerSelected.id;
        vm.isSaving = true;
        if (vm.receipt.id !== null) {
          Receipt.update(vm.receipt, onSaveSuccessReceipt, onSaveErrorReceipt);
        } else {
          Receipt.save(vm.receipt, onSaveSuccessReceipt, onSaveErrorReceipt);
        }
      } else {
        saveNewCustomer();
      }
    }

    function onSaveSuccessReceipt(result) {
      if (vm.receiptFile != null) {
        vm.receiptFile.receiptId = result.id;
        ReceiptFile.save(vm.receiptFile, function (result) {
          vm.images.push(result);
          if (result) {
            vm.image = result;
            vm.images.push(result);
          }
        });
      }
      $scope.$emit("garanteasyApp:receiptUpdate", result);
      vm.isSaving = false;
      $state.go("receipt", null, { reload: true });
    }

    function onSaveErrorReceipt(error) {
      vm.error = error;
      vm.isSaving = false;
    }

    function saveNewCustomer() {
      vm.customer = {
        email: vm.customer.email,
        login: vm.customer.email,
        business: false,
        appKey: APPKEY,
        langKey: "it",
        merchantCode: vm.merchant.code,
        merchantPrivacyPolicies: vm.merchantPrivacyPolicies,
        legalName: vm.customer.surname + " " + vm.customer.name
      };
      Customer.saveCustomerFromReceipt(
        vm.customer,
        onSaveCustomerSuccess,
        onSaveCustomerError
      );
    }

    function onPpSuccess(privacyPolicy) {
      if (privacyPolicy) {
        vm.merchantPrivacyPolicies = [];
        vm.merchantPrivacyPolicies[0] = {
          approved: true,
          code: privacyPolicy[0].code,
          mandatory: true,
          merchant: vm.merchant,
          merchantCode: vm.merchant.code,
          policyId: privacyPolicy[0].id
        };
      }
    }

    function onPpError(error) {
      vm.error = error;
      $log.error(error);
    }

    function onSaveCustomerSuccess(data) {
      vm.customerSelected = data;
      save();
      // vm.receipt.customer = data;
      // if (vm.receipt.id !== null) {
      //   Receipt.update(vm.receipt, onSaveSuccessReceipt, onSaveErrorReceipt);
      // } else {
      //   Receipt.save(vm.receipt, onSaveSuccessReceipt, onSaveErrorReceipt);
      // }
    }

    function onSaveCustomerError(error) {
      vm.error = error;
      $log.error(error);
    }

    function addProduct() {
      // TODO
      $log.error("TODO");
    }

    function addProductTest() {
      vm.addProduct = false;
      if (
        angular.isDefined(vm.receipt.purchasedProduct) &&
        angular.isDefined(vm.receipt.purchasedProduct.product)
      ) {
        if (angular.isUndefined(vm.receipt.purchasedProduct.giftMakerDescription)) {
          if (vm.customerSelected && vm.customerSelected.legalName !== null) {
            vm.receipt.purchasedProduct.giftMakerDescription = vm.customerSelected.legalName;
          } else if (vm.customerSelected === null) {
            vm.receipt.purchasedProduct.giftMakerDescription = vm.receipt.customer.legalName;
          }

        }
        vm.receipt.purchasedProducts.push(vm.receipt.purchasedProduct);
        vm.receipt.purchasedProduct = {};
      }
    }

    function isDisabled() {
      if (!vm.receipt.buyingDate ||
        !vm.receipt.receiptNumber ||
        !vm.receipt.shop ||
        !vm.merchant
      ) {
        return true;
      } else {
        if (vm.customerSelected) {
          return false;
        } else {
          if (!vm.customer.surname || !vm.customer.email) {
            return true;
          }
        }
      }
    }

    vm.updateCustomerData = function () {
      $log.debug("customer selezionato", vm.customerSelected);
      if (vm.customerSelected) {
        vm.customer.name = vm.customerSelected.legalName
          .split(" ")
          .slice(0, -1)
          .join(" ");
        vm.customer.surname = vm.customerSelected.legalName
          .split(" ")
          .slice(-1)
          .join(" ");
        vm.customer.email = vm.customerSelected.user.login;
      } else {
        vm.customer.name = "";
        vm.customer.surname = "";
        vm.customer.email = "";
      }
    };

    vm.removePurchasedProduct = function (index) {
      vm.receipt.purchasedProducts.splice(index, 1);
    };

    function disableAddProduct() {
      if (vm.receipt.purchasedProduct == null || vm.receipt.purchasedProduct.product == null || vm.receipt.purchasedProduct.product.name == null ||
        (vm.receipt.purchasedProduct.product.eanCodes == null && vm.receipt.purchasedProduct.product.eanCode == null) ||
        vm.receipt.purchasedProduct.product.brand == null ||
        (vm.receipt.gift === 'Y' && vm.receipt.purchasedProduct.giftReceiverEmail == null) ||
        (vm.receipt.purchasedProduct.typeCode !== null && vm.receipt.purchasedProduct.typeCode === 'EAN' && vm.isValidCode === false)
      ) {
        return true;
      } else return false;
    }

    $translate('garanteasyApp.receipt.detail.required').then(function (headline) {
      vm.messageValidationRequired = headline;
      vm.message = headline;
    }, function (translationId) {
      $log.error("Missing translation for", translationId);
    });

    $translate('garanteasyApp.receipt.receiptNewPage.codeValidation').then(function (headline) {
      vm.messageValidationCodeNotValid = headline;
    }, function (translationId) {
      $log.error("Missing translation for", translationId);
    });

    vm.deleteFile = function (receiptFile, isProduct) {
      var index;
      if (isProduct) {
        index = vm.receipt.purchasedProduct.receiptFiles.indexOf(receiptFile);
        return vm.receipt.purchasedProduct.receiptFiles.splice(index, 1);
      }
      index = vm.receiptFiles.indexOf(receiptFile);
      if (!receiptFile.id) {
        vm.receiptFile = {};
        vm.receiptFiles.splice(index, 1);
      } else {
        ReceiptFile.delete({ id: receiptFile.id }, function () {
          vm.receiptFiles.splice(index, 1);
        });
      }
    };



    function ccc13() {
      if (vm.receipt.purchasedProduct.typeCode !== null && vm.receipt.purchasedProduct.typeCode === 'EAN') {
        var factor = 3;
        var sum = 0;
        // var form = {};
        var number = '' + vm.receipt.purchasedProduct.product.eanCode;
        if (number === 'null') {
          vm.message = vm.messageValidationRequired;
        } else
          if (number.length !== 13) {
            vm.isValidCode = false;
            vm.message = vm.messageValidationCodeNotValid;
          } else
            if (number.length === 13) {
              vm.result = number.substring(0, 12);
              for (var index = 12; index > 0; --index) {
                sum = sum + number.substring(index - 1, index) * factor;
                factor = 4 - factor;
              }
              if (number.length !== 13) {
                vm.validationCodeMessage = 'FAI ATTENZIONE! Introdurre 12 digits';
              } else {
                var cc = ((1000 - sum) % 10);
                vm.result = vm.result + cc;
              }
              if (number === vm.result) {
                vm.isValidCode = true;
              } else {
                vm.isValidCode = false;
                vm.message = vm.messageValidationCodeNotValid;
              }
            }
      } else vm.isValidCode = true;
    }
  }
})();
