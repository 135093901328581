(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('MerchantRegistrationController', MerchantRegistrationController);


  MerchantRegistrationController.$inject = ['$translate', '$timeout', 'Auth', 'LoginService', '$location', '$filter', '$state', 'MerchantRegistration', '$scope'];

  function MerchantRegistrationController($translate, $timeout, Auth, LoginService, $location, $filter, $state, MerchantRegistration, $scope) {
    var vm = this;
    vm.doNotMatch = null;
    vm.error = null;
    vm.errorUserExists = null;
    vm.errorServiceConsent = null;
    vm.next = next;
    vm.merchantRegistration = {};
    vm.merchantRegistration.sameReferent = true;
    vm.merchantRegistration.merchant = {};
    vm.merchantRegistration.shop = {};
    vm.merchantRegistration.operators = [{}];
    vm.merchantRegistration.operators[0].role = 'OWNER';
    vm.merchantRegistration.operators[0].role = 'MANAGER';
    vm.success = null;
    var owner = $translate("merchantRegister.form.roleOwner").then(function (t) {
      var x = t;
      $translate("merchantRegister.form.roleManager").then(function (t) {
        var y = t;
        $translate("merchantRegister.form.roleEmployee").then(function (t) {
          var z = t;
          vm.roles = { 'OWNER': x, 'MANAGER': y, 'EMPLOYEE': z }
        })
      })
    })

    $translate("merchantRegister.form.referent").then(function (t) {
      vm.referent = t;
    })
    $translate("merchantRegister.form.operatingReferent").then(function (t) {
      vm.operatingReferent = t;
    })

    // vm.params = pagingParams.operators;
    /*if($state.operators){
        vm.merchantRegistration.operators = $state.operators;
    }*/
    if ($location.search().merchantCode) {
      vm.merchantCode = $location.search().merchantCode.toUpperCase();
    } else {
      vm.merchantCode = 'GARANTEASY';
    }

    $timeout(function () { angular.element('#login').focus(); });


    $scope.resetCategory = function () {
      vm.merchantRegistration.category = null;
    }


    $scope.resetMerchant = function () {
      vm.merchantRegistration.merchant = null;
    }

    $scope.loadMerchants = function (searchQuery) {
      if (searchQuery) {
        MerchantRegistration.searchMerchant({ query: searchQuery }, function (result) {
          $scope.merchants = result;
        }
          /*, function(response) {
              if(response.status === 404) {
                  $scope.merchants = Merchant.query({isActive: true});
              }
          }*/
        );
      }
      /*else {
          $scope.merchants = Merchant.query({isActive: true});
      }*/
    };


    $scope.resetShop = function () {
      vm.merchantRegistration.shop = null;
    }

    $scope.loadShops = function (searchQuery) {
      if (searchQuery) {
        MerchantRegistration.searchShop({ merchant_id: vm.merchantRegistration.merchant.id, query: searchQuery }, function (result) {
          $scope.shops = result;
        }
          /*, function(response) {
              if(response.status === 404) {
                  $scope.shops = Shop.query({isActive: true});
              }
          };*/
        );
      }
      /*else {
          $scope.shops = Shop.query({isActive: true});
      }*/
    };

    function next() {
      vm.merchantRegistration.langKey = $translate.use();
      if (vm.merchantRegistration.operators && vm.merchantRegistration.operators.length > 1) {
        vm.merchantRegistration.operators[1].type = vm.operatingReferent;
      }
      if (vm.merchantRegistration.operators && vm.merchantRegistration.operators.length > 0) {
        vm.merchantRegistration.operators[0].type = vm.referent;
      }
      $state.operators = vm.merchantRegistration.operators;
      vm.doNotMatch = null;
      vm.error = null;
      vm.errorUserExists = null;
      vm.errorEmailExists = null;
      vm.errorServiceConsent = null;
      $state.go('merchantRegister2');
      /*Auth.createAccount(vm.registerAccount).then(function () {
          vm.success = 'OK';
      }).catch(function (response) {
          vm.success = null;
          if (response.status === 400 && response.data === 'login already in use') {
              vm.errorUserExists = 'ERROR';
          } else if (response.status === 400 && response.data === 'e-mail address already in use') {
              vm.errorEmailExists = 'ERROR';
          } else if (response.status === 400 && response.data === 'service consent mandatory') {
              vm.errorServiceConsent = 'ERROR';
          } else {
              vm.error = 'ERROR';
          }
      });*/
    }


    /* OPERATORS --> array
    {
        langKey: string -> optional lingua size(2) (it/en...)
        name: string → required, min(3)
        surname: string → required, min(3)
        email: string →  required
        phoneNumber: string → required
        role: string → optional
        type: string → required, 'REFERENT' or 'OPERATING_REFERENT'
    }*/



  }
})();
