(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('dashboard', {
                parent: 'entity',
                url: '/dashboard?page&sort&search&documents&customers&shops&s',
                ncyBreadcrumb: {
                    label: "{{'garanteasyApp.dashboard.title' | translate}}"
                },
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.dashboard.title'
                },
                views: {
                    'sidebar@': {
                        templateUrl: 'app/layouts/sidebar/sidebar.html'
                    },
                    'content@': {
                        templateUrl: 'app/entities/dashboard/dashboard.html',
                        controller: 'DashboardController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('dashboard');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
