(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('DashboardController', DashboardController);

    DashboardController.$inject = ['$scope', 'AlertService', '$rootScope', 'Principal', 'Receipt', 'Dashboard', 'SelectedMerchantService', '$log', '$state', 'DataUtils'];

    function DashboardController($scope, AlertService, $rootScope, Principal, Receipt, Dashboard, SelectedMerchantService, $log, $state, DataUtils) {
        var vm = this;
        vm.receipts = [];
        vm.productSales = [];
        vm.garanteasyProductSales = [];
        vm.customers = [];
        vm.summary = {};
        vm.model = {
            get merchant() {
                return SelectedMerchantService.merchant;
            },
            set merchant(item) {
                return SelectedMerchantService.merchant = item;
            },
            get shop() {
                return SelectedMerchantService.shop;
            },
            set shop(item) {
                return SelectedMerchantService.shop = item;
            }
        };

        vm.interval = 'SETTIMANE';
        vm.time = ['SETTIMANE', 'MESI'];

        vm.checkAccess = function () {
            Principal.identity().then(function (account) {

                if (!Principal.isAuthenticated()) {
                    $state.go("home");
                    return;
                }
                vm.currentUserId = account.id;
                Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                    if (result) {
                        vm.isAdmin = true;
                    } else {
                        vm.isAdmin = false;
                    }
                    loadData();
                });
            });
        };

        vm.checkAccess();
        vm.showCustomerName = function (customer) {
            return DataUtils.showCustomerName(customer);
        };
        function loadData() {
            if (!vm.model.merchant) {
                return;
            }
            var merchantId = vm.model.merchant.id;
            var shopId;
            if (vm.model.shop) {
                shopId = vm.model.shop.id;
            }
            Receipt.getValid({},
                function (data, headers) { return vm.summary = data; },
                function (e) { return $log.error(e); });


            if (merchantId && !shopId)
                vm.receipts = Dashboard.getReceiptsTopMerchant({}, onSuccessReceipt, onErrorReceipt);
            else if (merchantId && shopId) {
                vm.receipts = Dashboard.getReceiptsTopMerchantShop({ merchant_id: merchantId, shop_id: shopId }, onSuccessReceipt, onErrorReceipt);
            }
            else if (vm.isAdmin) {
                vm.receipts = Dashboard.getReceiptsTop({}, onSuccessReceipt, onErrorReceipt);
            }
            function onSuccessReceipt(data, headers) {
                vm.receipts = data;
            }
            function onErrorReceipt(data, headers) {
                $log.error('Error load receipts');
            }

            // if (merchantId && !shopId)
            //     vm.productSales = Dashboard.getProductSales({ merchant_id: merchantId, intervall: vm.interval });
            // else if (merchantId && shopId) {
            //     vm.productSales = Dashboard.getProductSales({ merchant_id: merchantId, shop_id: shopId, intervall: vm.interval });
            // }
            // else if (vm.isAdmin) {
            //     vm.productSales = Dashboard.getProductSales({ intervall: vm.interval });
            // }
            if (vm.isAdmin) {
                if (merchantId && !shopId)
                    vm.garanteasyProductSales = Dashboard.getGaranteasyProductSales({ merchant_id: merchantId });
                else if (merchantId && shopId) {
                    vm.garanteasyProductSales = Dashboard.getGaranteasyProductSales({ merchant_id: merchantId, shop_id: shopId });
                } else {
                    vm.garanteasyProductSales = Dashboard.getGaranteasyProductSales({});
                }
            }
            if (merchantId) {
                vm.customers = Dashboard.getCustomersTop({ merchant_id: merchantId });
            } else if (vm.isAdmin) {
                vm.customers = Dashboard.getCustomersTop({});
            }
            $log.debug(
                vm.summary,
                vm.receipts,
                vm.productSales,
                vm.garanteasyProductSales,
                vm.customers);
        }

        vm.listenerMerchant = SelectedMerchantService.onMerchantChange(loadData);
        vm.listenerShop = SelectedMerchantService.onShopChange(loadData);

        $scope.$on('$destroy', function () {
            vm.listenerMerchant();
            vm.listenerShop();
        });
    }
})();
