angular
  .module('garanteasyApp')
  .component('warrantyAssistance', {
    templateUrl: 'app/components/warranty-assistance/warranty-assistance.component.html',
    controller: WarrantyAssistanceController,
    controllerAs: 'vm',
    bindings: {
      purchasedProduct: '<',
      receipt: '<',
      legal: '<',
      conventional: '<',
      insurance: '<',
      assistance: '<',
      claims: '<'
    }
  });

WarrantyAssistanceController.$inject = ['$scope', '$element', '$log', 'Receipt', 'ProductLookup', 'BrandLookup', '$state'];

function WarrantyAssistanceController($scope, $element, $log, Receipt, ProductLookup, BrandLookup, $state) {
  var vm = this;
  vm.checkStatus = checkStatus;
  vm.openProductSelect = openProductSelect;
  vm.productSelectToggle = false;
  vm.selectedProduct;
  vm.save = save;
  vm.warrantiesTotal = {};

  function calculateTotalWarranties() {

  }


  function checkStatus(status) {
    switch (status) {
      case "green":
        return "garanteasyApp.purchasedProduct.detail.valid";
      case "white":
        return "garanteasyApp.purchasedProduct.detail.notDefined";
      case "red":
        return "garanteasyApp.purchasedProduct.detail.expired";
      case "orange":
        return "garanteasyApp.purchasedProduct.detail.expiring";
      default:
        break;
    }
  }

  function openProductSelect() {
    vm.productSelectToggle = true;
  }

  $scope.$watch('vm.purchasedProduct', function (newValue) {
    convertWarrantyInfo(newValue);
    console.log(vm.purchasedProduct);
  });

  $scope.$watch('vm.receipt', function (newValue) {
    if (vm.receipt && vm.receipt.totalWarranties) {
      vm.totalWarranties = vm.receipt.totalWarranties;
    }
  });

  $scope.$watch('vm.purchasedProduct.purchasedProductWarranties', function (newValue) {
    if (vm.purchasedProduct) {
      vm.purchasedProduct.purchasedProductWarranties = newValue;
      convertWarrantyInfo(vm.purchasedProduct);
    }
  });

  function convertWarrantyInfo(purchasedProduct) {
    if (purchasedProduct) {
      purchasedProduct.purchasedProductWarranties.forEach(function (item) {
        if (item.status === 'valid') {
          item.color = 'green';
        } else if (item.status === 'aboutToExpire') {
          item.color = 'orange';
        } else if (item.status === 'expired') {
          item.color = 'red';
        } else {
          item.color = 'white';
        }

        if (item.warranty.contractType === 'LEGAL') {
          vm.legal = item.color;
        } else if (item.warranty.contractType === 'CONVENTIONAL') {
          vm.conventional = item.color;
        } else if (item.warranty.contractType === 'SERVICE_CONTRACT') {
          vm.assistance = item.color;
        } else if (item.warranty.contractType === 'INSURANCE_POLICY') {
          vm.assistance = item.color;
        }
      });

    }
  }


  //PRODUCT
  vm.ALL_PRODUCTS = -1;
  vm.PREFERRED_PRODUCTS = 0;
  vm.NEW_PRODUCT = 1;
  vm.isNewProductCollapsed = vm.PREFERRED_PRODUCTS;

  vm.products = [];
  $scope.loadProducts = function (searchQuery) {
    if (vm.isNewProductCollapsed === vm.PREFERRED_PRODUCTS) {
      ProductLookup.query({}, function (results) {
        vm.products = results;
      });
    } else {
      ProductLookup.query({ query: searchQuery },
        function (result) {
          vm.products = result;
          angular.forEach(vm.products, function (product, key) {
            product.description = String(product.description).replace(/<[^>]+>/gm, '');
          });
        },
        function (response) {
          $log.error("Error in productlokup", response);
          AlertService.error("Error: " + response.status);
        }
      );
    }
  };

  function addProduct() {
    vm.addProduct = false;
    if (
      angular.isDefined(vm.receipt.purchasedProduct) &&
      angular.isDefined(vm.receipt.purchasedProduct.product)
    ) {
      if (angular.isUndefined(vm.receipt.purchasedProduct.giftMakerDescription)) {
        vm.receipt.purchasedProduct.giftMakerDescription = vm.receipt.customer.legalName;
      }
      var index = vm.receipt.purchasedProducts.indexOf(vm.receipt.purchasedProduct);
      if (index === -1) {
        vm.receipt.purchasedProducts.push(vm.receipt.purchasedProduct);
      } else {
        vm.receipt.purchasedProducts[index] = vm.receipt.purchasedProduct;
      }
      vm.receipt.purchasedProduct = {};
    }
    console.log(vm.receipt);
  }

  function save() {

    addProduct();
    if (vm.receipt.purchasedProducts && vm.receipt.purchasedProducts.length) {
      vm.receipt.numProducts = Number(vm.receipt.purchasedProducts.length);
    }
    vm.isSaving = true;
    vm.receipt.cardId = vm.useCard ? vm.receipt.cardId : null;
    if (vm.receipt.purchasedProducts && vm.receipt.purchasedProducts.length) {
      vm.receipt.purchasedProducts = vm.receipt.purchasedProducts.map(function (purchasedProduct) {
        if (purchasedProduct.product.id) {
          purchasedProduct.allProductId = purchasedProduct.product.id;
          purchasedProduct.product = {};
        }
        return purchasedProduct;
      });
    }
    if (vm.receipt.id !== null) {
      Receipt.update(vm.receipt, onSaveSuccess, onSaveError);
    } else {
      vm.receipt.purchasedProduct = null;
      // if (!vm.receipt.receiptNumber) {
      //   vm.receipt.receiptNumber = Base64.encode(vm.receipt.buyingDate.toString());
      // }
      Receipt.save(vm.receipt, onSaveSuccess, onSaveError);

    }

  }

  function onSaveSuccess(result) {
    vm.isSaving = false;
    $state.go('warranty-claim.new', { 'idPurchasedProduct': result.purchasedProducts[0].id });
  }

  function onSaveError(error) {
    vm.error = error;
    /*if(error.statusText === 'Forbidden'){
        AlertService.error("zdfsf");
    }*/
    vm.isSaving = false;
    vm.isEdit = false;
    vm.isEdit2 = false;
    vm.isEdit3 = false;
  }

  $scope.loadBrands = function (searchQuery, producer) {
    if (producer && producer.code) {
      searchQuery = '\'(owner.code:' + producer.code + ')AND(official:1)\'' + searchQuery;
    }
    BrandLookup.query({ query: searchQuery },
      function (result) {
        $scope.brands = result;
      }
    );
  };
}
