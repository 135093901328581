(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('MerchantDetailController', MerchantDetailController);

    MerchantDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Principal', 'Merchant', 'User', 'Shop'];

    function MerchantDetailController($scope, $rootScope, $stateParams, previousState, entity, Principal, Merchant, User, Shop) {
        var vm = this;

        vm.merchant = entity;

        vm.isOwner = null;
        vm.isAdmin = null;

        checkAccess();

        function checkAccess() {
            Principal.identity().then(function (account) {
                if (!Principal.isAuthenticated()) {
                    $state.go("home");
                    return;
                }
                vm.isOwner = vm.merchant.createdBy.id == account.id;
                Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                    if (result) {
                        vm.isAdmin = true;
                    } else {
                        vm.isAdmin = false;
                    }
                });
            });
        }

        if (typeof previousState.params.id != 'undefined') {
            vm.previousState = previousState.name + '({id: \'' + previousState.params.id + '\'})';
        } else {
            vm.previousState = previousState.name;
        }

        var unsubscribe = $rootScope.$on('garanteasyApp:merchantUpdate', function (event, result) {
            vm.merchant = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
