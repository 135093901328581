(function() {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('Searchbar', Searchbar);

    Searchbar.$inject = ['$rootScope'];

    function Searchbar ($rootScope) {
        var listGlobal = [];

        return {
            pushList: pushList,
            getList: getList
        };

        function pushList(list) {
            listGlobal = list;
            $rootScope.searchVar = true;
            if(!$rootScope.searchChange) {
                $rootScope.searchChange = true;
            }
            else {
                $rootScope.searchChange = false;
            }
        }

        function getList() {
            return listGlobal;
        }
    }
})();
