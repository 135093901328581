(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('PurchasedProductDetailController', PurchasedProductDetailController);

  PurchasedProductDetailController.$inject = ['$scope', '$rootScope', '$http', '$stateParams', 'previousState', 'entity', 'Principal', 'PurchasedProduct', 'Receipt', 'Gift', 'Product', 'PurchasedProductWarranty', 'WarrantyClaim', 'ProductResources', 'DEFAULT_BARCODE_ENCODING', 'DataUtils', 'bsScreenSize', 'AuthServerProvider'];

  function PurchasedProductDetailController($scope, $rootScope, $http, $stateParams, previousState, entity, Principal, PurchasedProduct, Receipt, Gift, Product, PurchasedProductWarranty, WarrantyClaim, ProductResources, DEFAULT_BARCODE_ENCODING, DataUtils, bsScreenSize, ngGallery, AuthServerProvider) {
    var vm = this;

    vm.purchasedProduct = entity;
    vm.carouselImages = [];
    vm.isOwner = null;
    vm.isAdmin = null;
    vm.isGiftReceiver = null;
    vm.canManageWarranties = null;
    vm.getImageUrl = getImageUrl;
    vm.checkAccess = checkAccess;
    vm.saveSerialNumber = saveSerialNumber;
    checkAccess();
    vm.imagesPath = {};
    vm.barCodes = [];
    vm.productEanCode = '';
    if (((vm.purchasedProduct || {}).eanCodes || []).length) {
      vm.purchasedProduct.eanCodes.forEach(function (item) {
        vm.barCodes.push(item);
      });

    }
    if (((vm.purchasedProduct || {}).sku)) {
      vm.barCodes.push(product.sku);
    }

    buildGallery();
    getProductData();

    function buildGallery() {

      if (vm.purchasedProduct.receiptFiles && vm.purchasedProduct.receiptFiles.length) {
        vm.purchasedProduct.receiptFiles = vm.purchasedProduct.receiptFiles.filter(function (item) {
          return item.purchasedProductId === vm.purchasedProduct.id;
        });
        vm.purchasedProduct.receiptFiles.forEach(function (file) {
          if (file && file.id && vm.canShow(file.fileContentType)) {
            file.src = vm.getImageUrl(file);
            vm.carouselImages.push(file);
          }
        });
      }
    }

    function getImageUrl(receiptFile) {
      if (!receiptFile || !receiptFile.id) {
        vm.imagesPath[receiptFile.id] = null;
      } else
        if (_.isUndefined(vm.imagesPath[receiptFile.id])) {
          vm.imagesPath[receiptFile.id] = "api/receipt-files/content/" + receiptFile.id + "?width=-&height=377&token=" + AuthServerProvider.getToken();
        }
      return vm.imagesPath[receiptFile.id];
    }

    function checkAccess() {
      Principal.identity().then(function (account) {
        if (!Principal.isAuthenticated()) {
          $state.go("home");
          return;
        }
        vm.isOwner = vm.purchasedProduct.receipt.customer.user.id == account.id;
        vm.isGiftReceiver = vm.purchasedProduct.giftReceiverUserId == account.id;
        Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });

        //If this is a gift, after notification warranties can be managed by gift receiver and not by owner
        vm.canManageWarranties = !vm.purchasedProduct.giftId || vm.isAdmin || (vm.isGiftReceiver && vm.purchasedProduct.giftReceiverNotified == 1) || (vm.isOwner && vm.purchasedProduct.giftReceiverNotified == 0);
      });
    }

    function getProductData() {
      Product.get({ id: vm.purchasedProduct.product.id },
        function (product) {
          vm.barCodes = [];
          vm.manuals = (product || {}).manuals;

          if (((product || {}).eanCodes || []).length) {
            product.eanCodes.forEach(function (item) {
              if (item !== product.brandEanCode && !vm.productEanCode) {
                vm.productEanCode = item;
              }
              vm.barCodes.push(item);
            });

          }
          if (((product || {}).sku)) {
            vm.barCodes.push(product.sku);
          }
          if (product && product.images && product.images.length) {
            product.images.forEach(function (image) {
              if (image.localCopy === null || image.localCopy) {
                image.src = 'api/product-resources/image/' + image.id + '?width=-&heigth=350';
              } else {
                image.src = image.value;
              }
              vm.carouselImages.push(image);
            });
            product.images.sort(function (a, b) {
              if (a.main) {
                return -1;
              } else if (b.main) {
                return 1;
              }
              return 0;
            });
          }
        }, function (error) { $log.error(error); });
    }

    function saveSerialNumber() {
      PurchasedProduct.update(vm.purchasedProduct, onSaveSuccess, onSaveError);
    }

    function onSaveSuccess() {

    }

    function onSaveError() {

    }

    $(document).ready(function () {
      window.scrollTo(0, 0);
    });
  }


})
  ();
