(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('ShopLookup', ShopLookup);

    ShopLookup.$inject = ['$resource'];

    function ShopLookup($resource) {
        var resourceUrl = 'api/_lookup/shops';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();
