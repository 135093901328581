(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('ReceiptClaimController', ReceiptClaimController);

    ReceiptClaimController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$uibModalInstance', 'entity', 'Principal', 'LoginService', 'Contact', 'Receipt'];

    function ReceiptClaimController($timeout, $scope, $state, $stateParams, $uibModalInstance, entity, Principal, LoginService, Contact, Receipt) {
        var vm = this;

        vm.receiptMatcher = entity;
        vm.receiptMatcher.receiptIdentifier;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.find = find;
        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });
        vm.error = null;

        vm.login = LoginService.open;
        vm.register = register;

        vm.contacts = [];
        vm.loggedUser = false;
        vm.checkAccess = checkAccess;
        checkAccess();

        function checkAccess() {
            Principal.identity().then(function (account) {
                if (!Principal.isAuthenticated()) {
                    $state.go("home");
                    return;
                }
                if (account) {
                    console.log("Account=" + account.login);
                    vm.loggedUser = true;
                    vm.contacts = Contact.queryActive();
                } else {
                    console.log("Anonymous access");
                    vm.loggedUser = false;
                }
            }, function () {
                console.log("Principal.identity() failed");
                vm.loggedUser = false;
            });
        }

        function clear() {
            if ($uibModalInstance) {
                $uibModalInstance.dismiss('cancel');
            } else {
                window.location.href = "http://it.garanteasy.com";
            }
        }

        function find() {
            vm.isSaving = true;
            //console.log("Searching shop="+vm.receiptMatcher.shopCode+", counter="+vm.receiptMatcher.counterCode+", receipt="+vm.receiptMatcher.receiptNumber+", email="+vm.receiptMatcher.email);
            vm.results = Receipt.claim(vm.receiptMatcher, onFindSuccess, onFindError);
        }

        function onFindSuccess(result) {
            //console.log("Claim result:");
            //console.log(JSON.stringify(result, null, 4));
            if (result.errorCode) {
                vm.error = "garanteasyApp.receipt.error." + result.errorCode;
            } else {
                vm.error = 0;
            }
            vm.isSaving = false;
        }

        function onFindError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.buyingDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function register() {
            $state.go('register');
        }
    }
})();
