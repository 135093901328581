(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .constant('cmsViewMode', {
            'faq': 'faq',
            'advertising': 'advertising',
            'video': 'video',
            'docs': 'docs'
        });
})();
