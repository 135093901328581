(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('register', {
            parent: 'account',
            url: '/register',
            data: {
                authorities: [],
                pageTitle: 'register.title'
            },
            views: {
                'sidebar@': {
                    templateUrl: 'app/layouts/sidebar/sidebar-none.html',
                    controller: 'SidebarController',
                    controllerAs: 'vm'
                },
                'content@': {
                    templateUrl: 'app/account/merchantRegistration/merchantRegistration.html',
                    controller: 'MerchantRegistrationController',
                    controllerAs: 'vm'
                }
            },
            params:{
                merchantCode: {
                    value: 'GARANTEASY',
                    squash: false
                }
            },
            resolve: {
                pagingParams: ['$state', function ($state) {
                    return {
                        operators: $state.operators
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('merchantRegister');
                    $translatePartialLoader.addPart('merchant');
                    return $translate.refresh();
                }]
            }
        }).state('merchantRegister2', {
            parent: 'account',
            url: '/merchantRegister2',
            data: {
                authorities: [],
                pageTitle: 'register.title'
            },
            views: {
                'sidebar@': {
                    templateUrl: 'app/layouts/sidebar/sidebar-none.html',
                    controller: 'SidebarController',
                    controllerAs: 'vm'
                },
                'content@': {
                    templateUrl: 'app/account/merchantRegistration/merchantRegistration2.html',
                    controller: 'MerchantRegistrationController2',
                    controllerAs: 'vm'
                }
            },
            params:{
                merchantCode: {
                    value: 'GARANTEASY',
                    squash: false

                }
            },
            resolve: {
                pagingParams: ['$state', function ($state) {
                    return {
                        operators: $state.operators
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('merchantRegister');
                    $translatePartialLoader.addPart('merchant');
                    return $translate.refresh();
                }]
            }
        }).state('getLicenceData', {
          parent: 'account',
          url: '/license',
          data: {
              authorities: [],
              pageTitle: 'register.title'
          },
          views: {
              'sidebar@': {
                  templateUrl: 'app/layouts/sidebar/sidebar-none.html',
                  controller: 'SidebarController',
                  controllerAs: 'vm'
              },
              'content@': {
                  templateUrl: 'app/account/merchantRegistration/licenseRegistration.html',
                  controller: 'LicenseRegistrationController',
                  controllerAs: 'vm'
              }
          },
          params:{
              merchantCode: {
                  value: 'GARANTEASY',
                  squash: false

              }
          },
          resolve: {
              pagingParams: ['$state', function ($state) {
                  return {
                      operators: $state.operators
                  };
              }],
              translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                  $translatePartialLoader.addPart('register');
                  $translatePartialLoader.addPart('merchantRegister');
                  $translatePartialLoader.addPart('merchant');
                  return $translate.refresh();
              }]
          }
      });
    }
})();
