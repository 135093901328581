(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('NavbarController', NavbarController);

  NavbarController.$inject = ['$scope', '$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', '$rootScope', 'SelectedMerchantService', '$log'];

  function NavbarController($scope, $state, Auth, Principal, ProfileService, LoginService, $rootScope, SelectedMerchantService, $log) {
    var vm = this;
    vm.model = {
      get merchant() {
        return SelectedMerchantService.merchant;
      },
      set merchant(item) {
        return SelectedMerchantService.merchant = item;
      },
      get shop() {
        return SelectedMerchantService.shop;
      },
      set shop(item) {
        return SelectedMerchantService.shop = item;
      }
    };
    $scope.$watch(function () {
      return $rootScope.showSearchBar;
    }, function () {
      if ($rootScope.showSearchBar) {
        vm.show = true;
      }
      else {
        vm.show = false;
      }
    }, true);
    vm.isNavbarCollapsed = true;
    vm.isAuthenticated = Principal.isAuthenticated;
    /*vm.identity = Account.get({id : $stateParams.id}).$promise;*/

    ProfileService.getProfileInfo().then(function (response) {
      vm.inProduction = response && response.inProduction;
      vm.swaggerEnabled = response && response.swaggerEnabled;
    });

    vm.login = login;
    vm.logout = logout;
    vm.toggleNavbar = toggleNavbar;
    vm.collapseNavbar = collapseNavbar;
    vm.$state = $state;
    vm.selectEnabled = true

    vm.toggled = function (open) {
      $log.log('Dropdown is now: ', open);
    };

    vm.toggleDropdown = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      vm.status.isopen = !$scope.status.isopen;
    };

    $scope.appendToEl = angular.element(document.querySelector('#dropdown-long-content'));

    function login() {
      collapseNavbar();
      LoginService.open();
    }

    function logout() {
      collapseNavbar();
      Auth.logout();
      vm.account = null;
      $state.go('home');
    }

    function toggleNavbar() {
      vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
    }

    function collapseNavbar() {
      vm.isNavbarCollapsed = true;
    }

    $scope.$on('authenticationSuccess', function () {
      getAccount();
    });

    getAccount();

    function getAccount() {
      Principal.identity().then(function (account) {
        vm.account = account;
        vm.isAuthenticated = Principal.isAuthenticated;
      });
    }
    vm.getShops = function () {
      return SelectedMerchantService.shops;
    }

    vm.getMerchants = function () {
      return SelectedMerchantService.merchants;
    };

    $rootScope.$on("enableMerchantSelector", function enableMerchantSelector(evt, state){
      $log.debug("Merchant selector should go", state)
      vm.selectEnabled = state
    })
  }
})();
