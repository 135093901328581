angular
    .module('garanteasyApp')
    .component('listItemShop', {
        templateUrl: 'app/components/list-item/shop/shop.component.html',
        controller: ListItemShopComponentController,
        controllerAs: 'vm',
        bindings: {
            shop: '<'
        }
    });

ListItemShopComponentController.$inject = ['DataUtils'];

function ListItemShopComponentController(DataUtils) {
    var vm = this;
    vm.abbreviate = DataUtils.abbreviate;
}
