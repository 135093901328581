(function() {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('PersistentAuditEvent', PersistentAuditEvent);

    PersistentAuditEvent.$inject = ['$resource', 'DateUtils'];

    function PersistentAuditEvent ($resource, DateUtils) {
        var resourceUrl =  'api/contacts/:id';
        return $resource(resourceUrl, {}, {
            'getPersistenceAuditEvents' : {
            url: 'api/cards/persistentAuditEvents/:userId',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                if (data) {
                    data = angular.fromJson(data);
                }
                return data;
            }
        }
        });
    }
})();
