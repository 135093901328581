(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('gift', {
                parent: 'entity',
                url: '/gift',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.gift.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/gift/gifts.html',
                        controller: 'GiftController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('gift');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('gift-detail', {
                parent: 'entity',
                url: '/gift/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.gift.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/gift/gift-detail.html',
                        controller: 'GiftDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('gift');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Gift', function ($stateParams, Gift) {
                        return Gift.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'gift',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('gift-detail/edit', {
                parent: 'gift-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/gift/gift-dialog.html',
                        controller: 'GiftDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Gift', function (Gift) {
                                return Gift.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: false });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('gift/new', {
                parent: 'gift',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/gift/gift-dialog.html',
                        controller: 'GiftDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    description: null,
                                    notificationDate: null,
                                    giftMakerDescription: null,
                                    notifiedReceiver: 0,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('gift', null, { reload: true });
                    }, function () {
                        $state.go('gift');
                    });
                }]
            })
            .state('gift/edit', {
                parent: 'gift',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/gift/gift-dialog.html',
                        controller: 'GiftDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Gift', function (Gift) {
                                return Gift.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('gift', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('gift/delete', {
                parent: 'gift',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/gift/gift-delete-dialog.html',
                        controller: 'GiftDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Gift', function (Gift) {
                                return Gift.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('gift', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
