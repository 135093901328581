(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('WarrantyClaimDetailController', WarrantyClaimDetailController);

  WarrantyClaimDetailController.$inject = ['$state', 'entity',];

  function WarrantyClaimDetailController($state, entity) {
    var vm = this;
    vm.warrantyClaim = entity;

  }
})();
