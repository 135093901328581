'use strict';
(function () {
  angular
    .module('garanteasyApp')
    .factory('ShopService', ShopService);

  ShopService.$inject = ['$resource', 'DateUtils', '$filter'];

  function ShopService($resource, DateUtils, $filter) {
    var resourceUrl = 'api/shops';

    return $resource(resourceUrl, {}, {
      'query': { method: 'GET', cache: true, isArray: true },
      'get': {
        method: 'GET',
        cache: true,
        transformResponse: function (data) {
          if (data) {
            data = angular.fromJson(data);
          }
          return data;
        }
      },
      'update': {
        method: 'PUT',
        transformRequest: function (data) {
          var dataToSend = angular.copy(data);
          // dataToSend.buyingDate = DateUtils.convertLocalDateToServer(dataToSend.buyingDate);
          // dataToSend.creationDate = DateUtils.convertLocalDateToServer(dataToSend.creationDate);
          return angular.toJson(dataToSend);
        }
      },
      'save': {
        method: 'POST',
        transformRequest: function (data) {
          var dataToSend = angular.copy(data);
          // dataToSend.buyingDate = DateUtils.convertLocalDateToServer(dataToSend.buyingDate);
          // dataToSend.creationDate = DateUtils.convertLocalDateToServer(dataToSend.creationDate);
          return angular.toJson(dataToSend);
        }
      },
      'getShopByMerchants': {
        method: 'GET',
        url: 'api/merchant-dashboard/shops/:merchant_id',
        isArray: true,
        transformResponse: function (data) {
          if (data) {
            data = angular.fromJson(data);
          }
          return data;
        }
      }
    });
  }
})();
