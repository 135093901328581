(function () {
    'use strict';
    angular
        .module('garanteasyApp')
        .controller('SearchbarController', SearchbarController);

    SearchbarController.$inject = ['$stateParams', '$scope', '$rootScope', '$state', '$transitions', '$uibModal'];

    function SearchbarController($stateParams, $scope, $rootScope, $state, $transitions, $uibModal) {
        var initialSortState = {
            customers: { field: 'legalName', type: 'asc' },
          documents: { field: 'shop.owner.name', type: 'desc' },
          shops: { field: 'owner.name', type: 'desc' }
        };
        var s = ($stateParams.s ? JSON.parse($stateParams.s) : initialSortState);
        while (typeof s === 'string' || s instanceof String) {
            s = JSON.parse(s);
        }
        $scope.model = {
            documents: ($stateParams.documents || 'true') === 'true',
            customers: ($stateParams.customers || 'true') === 'true',
            shops: ($stateParams.shops || 'true') === 'true',
            search: $stateParams.search,
            page: $stateParams.page || 0,
            size: $stateParams.size || 20,
            s: s
        };
        $scope.dispatch = function () {
            if (!$scope.model.search) {
                return;
            }
            $scope.model.search = $scope.model.search.replace('@', ' ');
            $rootScope.$broadcast('dashboardSearchChange', $scope.model);
        };
        $scope.clear = function () {
            $scope.model.search = '';
            $state.go('search', $scope.model);
        };
        $scope.openSort = function () {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/layouts/navbar/sort.component.html',
                controller: 'ModalSortContentCtrl',
                size: '',
                resolve: {
                    model: function () {
                        return $scope.model.s;
                    }
                }
            });

            modalInstance.result.then(function (response) {
                $scope.result = response;
            });

        };
        var stateChangeStartListener = $transitions.onSuccess({}, function (trans) {
            const toParams = trans.params();
            $scope.model = {
                documents: (toParams.documents || 'true') === 'true',
                customers: (toParams.customers || 'true') === 'true',
                shops: ($stateParams.shops || 'true') === 'true',
                search: toParams.search,
                page: toParams.page || 0,
                size: toParams.size || 20,
                s: ($stateParams.s ? JSON.parse($stateParams.s) : initialSortState)
            };
        });

        $rootScope.$on('$destroy',
            function () {
                stateChangeStartListener();
            });
    }

    angular
        .module('garanteasyApp')
        .controller('ModalSortContentCtrl', ModalSortContentCtrl);

    ModalSortContentCtrl.$inject = ['$scope', '$uibModalInstance', 'model'];

    ModalSortContentCtrl

    function ModalSortContentCtrl($scope, $uibModalInstance, model) {

        $scope.ok = function () { $uibModalInstance.close($scope.model); }

        $scope.cancel = function () { $uibModalInstance.dismiss(); }

        $scope.model = model;

    }





})();
