(function() {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('Customer', Customer);

    Customer.$inject = ['$resource', 'DateUtils'];

    function Customer($resource, DateUtils) {
        var resourceUrl = 'api/customers/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                url: 'api/merchant-dashboard/customers',
                isArray: true
            },
            'get': {
                method: 'GET',
                url: 'api/merchant-dashboard/customer/:id',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.birthDate = DateUtils.convertLocalDateFromServer(data.birthDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function(data) {
                    var dataToSend = angular.copy(data);
                    dataToSend.birthDate = DateUtils.convertLocalDateToServer(dataToSend.birthDate);
                    return angular.toJson(dataToSend);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function(data) {
                    var dataToSend = angular.copy(data);
                    dataToSend.birthDate = DateUtils.convertLocalDateToServer(dataToSend.birthDate);
                    return angular.toJson(dataToSend);
                }
            },
            'getCustomerDetail': {
                method: 'GET',
                url: 'api/merchant-dashboard/customer/:customer_id',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'saveCustomerFromReceipt': {
                method: 'POST',
                url: 'api/register',
                transformRequest: function(data) {
                    var dataToSend = angular.copy(data);
                    return angular.toJson(dataToSend);
                }
            }
        });
    }
})();