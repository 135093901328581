(function () {
  'use strict';

  var warrantyEditComponent = {
    templateUrl: 'app/entities/warranty/components/warranty-edit-component.html',
    controller: WarrantyEditController,
    controllerAs: 'vm',
    bindings: {
      warranty: '<',
      purchasedProductWarranty: '<',
      type: '<',
      isConventionalStandard: '<',
      onUpdate: '<',
      purchasedProduct: '<',
      isFast: '<',
      isEdit: '<'
    }
  };

  angular
    .module('garanteasyApp')
    .component('warrantyEditComponent', warrantyEditComponent);

  WarrantyEditController.$inject = ['$scope', 'Warranty', '$log'];

  function WarrantyEditController($scope, Warranty, $log) {
    var vm = this;

    vm.loadWarranties = loadWarranties;
    vm.initWarranty = initWarranty;
    vm.setWarrantyType = setWarrantyType;
    vm.changeRequired = changeRequired;
    vm.changeSelected = changeSelected;
    vm.changeStart = changeStart;
    vm.changeGuarantor = changeGuarantor;

    vm.warrantyEditForm;
    vm.warranties = [];
    vm.type = 'CONVENTIONAL';
    vm.collapsed = 'new';
    vm.warrantiesForRequired = [];
    vm.selectedWarranty;
    vm.warrantyConventionalStandard = null;
    vm.warrantyLegal = null;
    vm.startType = null;

    vm.$onInit = function () {
      if (!vm.purchasedProductWarranty || Object.keys(vm.purchasedProductWarranty).length === 0) {
        vm.initWarranty();
      } else {
        if (vm.purchasedProductWarranty.warranty && vm.purchasedProductWarranty.warranty.code === 'CUSTOM' &&
          !vm.purchasedProductWarranty.warranty.official) {
          vm.collapsed = 'search';
        } else if (vm.purchasedProductWarranty.warranty) {
          vm.selectedWarranty = vm.purchasedProductWarranty.warranty;
        }
      }
    };

    $scope.$watch("vm.purchasedProductWarranty", function () {
      var valid = true;
      switch (vm.collapsed) {
        case 'new':
          if (!vm.purchasedProductWarranty || !vm.purchasedProductWarranty.id) {
            valid = false;
          }
          break;
        default:
          if (!$scope.warrantyEditForm || !$scope.warrantyEditForm.$valid) {
            valid = false;
          }
          break;
      }
      if (vm.purchasedProductWarranty) {
        if (vm.purchasedProductWarranty.notifiedApplication === undefined) {
          vm.purchasedProductWarranty.notifiedApplication = 0;
        }
        if (vm.purchasedProductWarranty.notifiedExpiration === undefined) {
          vm.purchasedProductWarranty.notifiedExpiration = 0;
        }
        if (!vm.purchasedProductWarranty.purchasedProductId && vm.purchasedProduct) {
          vm.purchasedProductWarranty.purchasedProductId = vm.purchasedProduct.id;
        }
        initStartType();
      }
      if (typeof vm.onUpdate === 'function') {
        vm.onUpdate(vm.purchasedProductWarranty, valid);
      }

    }, true);

    $scope.$watch("vm.type", function () {
      if (vm.collapsed === 'search') {
        // vm.setWarrantyType();
      }
    });

    $scope.$watch("vm.purchasedProduct", function () {
      if (!vm.purchasedProduct.id) {
        vm.collapsed = 'search';
        vm.initWarranty();
      }
      buildWarrantiesForRequired();
    });

    $scope.$watch("vm.purchasedProduct.warranty", function () {
      buildWarrantiesForRequired();
    }, true);

    function initWarranty() {
      var id = undefined;
      if (vm.purchasedProductWarranty && vm.purchasedProductWarranty.id) {
        id = vm.purchasedProductWarranty.id;
      }
      vm.purchasedProductWarranty = {};
      vm.purchasedProductWarranty.warranty = {};
      vm.purchasedProductWarranty.id = id;
      vm.purchasedProductWarranty.warranty.automatic = 0;
      vm.purchasedProductWarranty.warranty.official = 0;
      vm.purchasedProductWarranty.warranty.legal = 0;
      vm.purchasedProductWarranty.warranty.code = 'CUSTOM';
      /*  vm.purchasedProductWarranty.warranty.type = vm.type ? vm.type : 'CONVENTIONAL'; */
      vm.purchasedProductWarranty.warranty.applicabilityType = null;
      if (vm.isConventionalStandard) {
        vm.purchasedProductWarranty.warranty.applicabilityType = 'STANDARD';
        vm.purchasedProductWarranty.warranty.guarantorType = 'PRODUCER';
        vm.purchasedProductWarranty.warranty.protectedRisk = 'DEFECT';
        vm.purchasedProductWarranty.warranty.warrantyType = 'CONVENTIONAL';
        vm.purchasedProductWarranty.warranty.contractType = 'CONVENTIONAL';
      }
      /*  vm.warranty.type = vm.type === 'STANDARD' ? 'CONVENTIONAL' : 'INSURANCE'; */
    }

    function loadWarranties(query) {
      if (vm.purchasedProduct && vm.purchasedProduct.id) {
        var params = {
          query: query,
          purchasedProductId: vm.purchasedProduct.id
        };
        Warranty.getByPurchasedProduct(params).$promise
          .then(function (result) {
            if (result && Array.isArray(result)) {
              vm.warranties = result;
              if (vm.isConventionalStandard) {
                vm.warranties = vm.warranties.filter(function (val) {
                  return val.type === 'CONVENTIONAL' && val.applicabilityType === 'STANDARD' &&
                    val.protectedRisk === 'DEFECT' && val.guarantorType === 'PRODUCER';
                });
              } else {
                vm.warranties = vm.warranties.filter(function (val) {
                  return val.type !== 'CONVENTIONAL' || val.applicabilityType !== 'STANDARD' ||
                    val.protectedRisk !== 'DEFECT' || val.guarantorType !== 'PRODUCER';
                });
              }
              /* vm.warranties = result.filter(function (val) {
                return val.type === vm.type;
              });
              if (vm.purchasedProductWarranty && vm.purchasedProductWarranty.warranty && vm.purchasedProductWarranty.warranty.applicabilityType) {
                vm.warranties = vm.warranties.filter(function (val) {
                  return val.applicabilityType === vm.purchasedProductWarranty.warranty.applicabilityType;
                });
              } */
            }
          }).catch(function (error) {
            vm.warranties = [];
          });
      } else {
        vm.warranties = [];
      }
    }

    function setWarrantyType() {
      if (!vm.purchasedProductWarranty) {
        vm.initWarranty();
      }
      switch (vm.type) {
        case 'SERVICE_CONTRACT':
          vm.purchasedProductWarranty.warranty.warrantyType = 'SERVICE_CONTRACT';
          vm.purchasedProductWarranty.warranty.applicabilityType = null;
          break;
        case 'INSURANCE':
          vm.purchasedProductWarranty.warranty.warrantyType = 'INSURANCE';
          vm.purchasedProductWarranty.warranty.applicabilityType = null;
          break;
        default:
          vm.purchasedProductWarranty.warranty.warrantyType = 'CONVENTIONAL';
          vm.purchasedProductWarranty.warranty.applicabilityType = 'STANDARD';
          vm.purchasedProductWarranty.warranty.guarantorType = 'PRODUCER';
          vm.purchasedProductWarranty.warranty.protectedRisk = 'DEFECT';
          break;
      }
    }

    function changeRequired() {
      if (!vm.purchasedProductWarranty.warranty) {
        return;
      }
      vm.purchasedProductWarranty.warranty.required = [];
      if (vm.required) {
        vm.purchasedProductWarranty.warranty.required.push(vm.required);
      }
    }

    function changeSelected() {
      if (!vm.purchasedProductWarranty) {
        vm.purchasedProductWarranty = {};
        vm.purchasedProductWarranty.purchasedProductId = vm.purchasedProduct.id;
      }
      vm.purchasedProductWarranty.warranty = vm.selectedWarranty;
      vm.purchasedProductWarranty.warrantyId = vm.selectedWarranty.id;
    }

    function changeStart() {
      switch (vm.startType) {
        case 'STANDARD':
          vm.purchasedProductWarranty.warranty.applicabilityType = 'STANDARD';
          vm.purchasedProductWarranty.warranty.required = [];
          break;
        case 'EXTENSION_LEGAL':
          vm.purchasedProductWarranty.warranty.applicabilityType = 'EXTENSION';
          vm.purchasedProductWarranty.warranty.warrantyType = 'EXTENSION';
          vm.purchasedProductWarranty.warranty.required = [];
          vm.purchasedProductWarranty.warranty.required.push(vm.warrantyLegal);
          break;
        case 'EXTENSION_CONVENTIONAL':
          vm.purchasedProductWarranty.warranty.applicabilityType = 'EXTENSION';
          vm.purchasedProductWarranty.warranty.warrantyType = 'EXTENSION';
          vm.purchasedProductWarranty.warranty.required = [];
          vm.purchasedProductWarranty.warranty.required.push(vm.warrantyConventionalStandard);
          break;
        case 'ARBITRARY':
          vm.purchasedProductWarranty.warranty.applicabilityType = 'ARBITRARY';
          vm.purchasedProductWarranty.warranty.required = [];
          break;
      }
    }

    function buildWarrantiesForRequired() {
      if (typeof vm.purchasedProduct === 'object') {
        vm.warrantiesForRequired = [];
        if (vm.purchasedProduct.purchasedProductWarranties && vm.purchasedProduct.purchasedProductWarranties.length) {
          vm.purchasedProduct.purchasedProductWarranties.forEach(function (item) {
            if (item.warranty.id && item.warranty.warrantyType === 'LEGAL' && (item.warranty.code === 'LAW_GENERAL' || item.warranty.code === 'LAW_BUSINESS')) {
              vm.warrantyLegal = item.warranty;
            }
            if (item.warranty.id && item.warranty.warrantyType === 'CONVENTIONAL' && item.warranty.guarantorType === 'PRODUCER' &&
              item.warranty.applicabilityType === 'STANDARD' && item.warranty.protectedRisk === 'DEFECT') {
              vm.warrantyConventionalStandard = item.warranty;
            }
          });
        }
        /* if (vm.purchasedProduct.warranty && vm.purchasedProduct.warranty.description) {
          vm.warrantiesForRequired.push(vm.purchasedProduct.warranty);
        } */
      }
    }

    function initStartType() {
      if (!vm.purchasedProductWarranty.warranty) {
        return;
      }
      switch (vm.purchasedProductWarranty.warranty.applicabilityType) {
        case 'STANDARD':
          vm.startType = 'STANDARD';
          break;
        case 'EXTENSION':
          vm.startType = 'EXTENSION_LEGAL';
          break;
        case 'ARBITRARY':
          vm.startType = 'ARBITRARY';
          break;
      }
    }

    function changeGuarantor() {
      if (vm.purchasedProductWarranty.warranty && vm.purchasedProductWarranty.warranty.guarantorType) {
        switch (vm.purchasedProductWarranty.warranty.guarantorType) {
          case 'PRODUCER':
            if (vm.purchasedProductWarranty.warranty.applicabilityType !== 'EXTENSION') {
              vm.purchasedProductWarranty.warranty.warrantyType = 'CONVENTIONAL';
            }
            vm.purchasedProductWarranty.warranty.contractType = 'CONVENTIONAL';
            break;
          case 'INSURANCE':
            vm.startType = 'EXTENSION_LEGAL';
            if (vm.purchasedProductWarranty.warranty.applicabilityType !== 'EXTENSION') {
              vm.purchasedProductWarranty.warranty.warrantyType = 'EXTRA';
            }
            vm.purchasedProductWarranty.warranty.contractType = 'INSURANCE_POLICY';
            break;
          case 'ASSISTANCE_CENTER':
            vm.startType = 'ARBITRARY';
            if (vm.purchasedProductWarranty.warranty.applicabilityType !== 'EXTENSION') {
              vm.purchasedProductWarranty.warranty.warrantyType = 'EXTRA';
            }
            vm.purchasedProductWarranty.warranty.contractType = 'SERVICE_CONTRACT';
            break;
        }
      }
    }
  }
})();
