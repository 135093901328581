(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('SelectedMerchantService', SelectedMerchantService);

    SelectedMerchantService.$inject = ['$rootScope', 'Dashboard', 'AlertService', 'Principal'];

    function SelectedMerchantService($rootScope, Dashboard, AlertService, Principal) {

        var merchantChange = 'SelectedMerchantService.merchantChange';
        var shopChange = 'SelectedMerchantService.shopChange';
        var vm = this;
        vm.merchants = [];
        vm.shops = [];
        vm.currentMerchant = null;
        vm.currentShop = null;

        var selectShop = function (shop) {
            vm.currentShop = shop;
            $rootScope.$emit(shopChange, shop);
        };

        var onSuccessShop = function (data) {
            vm.shops = data;
            angular.forEach(vm.shops, function (shop) {
                shop.toParamsJson = JSON.stringify(shop);
                shop.description = "[" + shop.code + "] " + shop.description 
            });

            if (data && data.length > 0) {
                selectShop(data[0]);
            } else
                selectShop(null);
        };

        var onError = function (error) {
            vm.isLoading = false;
            AlertService.error(error.data.message);
        };

        var loadShops = function (force) {
            if (vm.currentMerchant || force) {
                Dashboard.getShops({ merchant_id: vm.currentMerchant.id }, onSuccessShop, onError);
            } else onSuccessShop([]);
        };

        var selectMerchant = function (merchant) {
            vm.currentMerchant = merchant;
            $rootScope.$emit(merchantChange, merchant);
            onSuccessShop( merchant.shops );
            loadShops();
        };


        var onSuccessMerchant = function (data) {
            vm.merchants = data;
            if (data.length >= 1) {
                selectMerchant(data[0]);
            }
        };

        var init = function () {

            vm.merchants = [];
            vm.shops = [];
            vm.currentMerchant = null;
            vm.currentShop = null;
            Dashboard.getMerchants({}, onSuccessMerchant, onError);

        };

        if (Principal.isAuthenticated) {
            init();
        }

        $rootScope.$on('authenticationSuccess',
            function () {
                init();
            }
        );

        return {
            onMerchantChange: function (funcToCall) {
                return $rootScope.$on(merchantChange,
                    function (evt, data) {
                        funcToCall(data);
                    }
                );
            },
            onShopChange: function (funcToCall) {
                return $rootScope.$on(shopChange,
                    function (evt, data) {
                        funcToCall(data);
                    }
                );
            },

            get merchant() { return vm.currentMerchant; },
            set merchant(merchant) {
                selectMerchant(merchant);
            },

            get shop() { return vm.currentShop; },

            set shop(shop) {
                selectShop(shop);
            },
            get merchants() {
                return vm.merchants;
            },
            get shops() {
                return vm.shops;
            },
            loadShops: loadShops
        };
    };
})();
