(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('shop', {
                parent: 'entity',
                url: '/shop?page&sort&search&documents&customers&shops&s',
                ncyBreadcrumb: {
                    label: "{{'garanteasyApp.shop.home.title' | translate}}"
                },
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.shop.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/shop/shops.html',
                        controller: 'ShopController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('shop');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('shop/edit', {
                parent: 'shop',
                url: '/edit/:shop',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_REFERENT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/shop/shops-dialog.html',
                        controller: 'ShopDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('shop');
                        $translatePartialLoader.addPart('receipt');
                        $translatePartialLoader.addPart('address');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('shop/new', {
                parent: 'shop',
                url: '/new',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_REFERENT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/shop/shops-dialog.html',
                        controller: 'ShopDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    shop: function () { return null; },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('shop');
                        $translatePartialLoader.addPart('receipt');
                        $translatePartialLoader.addPart('address');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('shop/delete', {
                parent: 'shop',
                url: '/delete/:id',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_REFERENT']
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl: "app/entities/shop/shop-delete-dialog.html",
                                controller: "ShopDeleteController",
                                controllerAs: "vm",
                                size: "md"
                            })
                            .result.then(
                                function () {
                                    $state.go("shop", null, { reload: true });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    }
                ]
            });
    }
})();
