(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('BarCodeFileReaderController', BarCodeFileReaderController);

    BarCodeFileReaderController.$inject = ['$scope', 'AlertService', '$stateParams', 'ShopService', '$uibModalInstance'];

    function BarCodeFileReaderController($scope, AlertService, $stateParams, ShopService, $uibModalInstance) {
        var vm = this;
        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.save = function () {
            onSaveSuccess();
        }


        function onSaveSuccess(result) {
            $uibModalInstance.close('ok');
        }

        function onSaveError(error) {
            AlertService.error(error.data.message);
        }

        vm.onFileImputChange = function (e) {
            if (e.target.files && e.target.files.length) {
                vm.decode(URL.createObjectURL(e.target.files[0]));
            }
        }
        vm.decode = function (src) {
            var self = vm,
                config = $.extend({}, self.state, { src: src });

            Quagga.decodeSingle(config, function (result) { });
        }
    }
})();
