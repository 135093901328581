(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('ReceiptDialogController', ReceiptDialogController);

    ReceiptDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Principal', 'Receipt', 'Customer', 'Contact', 'PurchasedProduct', 'Shop', 'Dashboard', 'SelectedMerchantService', 'Card', 'CustomerSearch'];

    function ReceiptDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Principal, Receipt, Customer, Contact, PurchasedProduct, Shop, Dashboard, SelectedMerchantService, Card, CustomerSearch) {
        var vm = this;

        vm.receipt = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.customer = [];
        vm.shops = [];
        vm.merchants = [];
        vm.merchantWrap = function () {
            return {
                get merchant() {
                    if (vm.receipt && vm.receipt.shop)
                        return vm.receipt.shop.merchant;
                    else
                        return null;
                },
                set merchant(merchant) {
                    vm.receipt.shop = { merchant: merchant };
                    SelectedMerchantService.merchant = merchant;
                }
            };
        }
        var _currentMerchant = null;

        var onSuccessCustomers = function (data) {
            vm.customers = data;
        };

        var onError = function (error) {
            vm.isLoading = false;
            AlertService.error(error.data.message);
        };

        var onSuccessShop = function (data) {
            vm.shops = SelectedMerchantService.shops;
        };

        var onSuccessMerchants = function (data) {
            vm.merchants = SelectedMerchantService.merchants;
        };

        vm.loadCustomers = function (query) {
            if (SelectedMerchantService.merchant) {
                Dashboard.getMerchantCustomers({ merchant_id: SelectedMerchantService.merchant.id, query: query }, onSuccessCustomers);
            } else onSuccessCustomers([]);

        };

        SelectedMerchantService.onMerchantChange(function (data) {
            onSuccessMerchants();
            vm.loadCustomers();
            // onSuccessShop();
        });

        SelectedMerchantService.onShopChange(function (data) {
            onSuccessShop();
        });



        onSuccessMerchants();

        vm.loadCustomers();

        /*vm.cards = Card.queryActive(vm.receipt);*/
        vm.contacts = Contact.queryActive();


        $q.all([vm.receipt.$promise]).then(function () {
            if (!vm.receipt.shopId) {
                onSuccessShop();
                return $q.reject();
            }
            return Shop.get({ id: vm.receipt.shopId }).$promise;
        }).then(function (shop) {
            vm.shops = [];
            vm.shops.push(shop);
        });

        /* $q.all([vm.receipt.$promise, vm.cards.$promise]).then(function() {
             if (!vm.receipt.buyingDateng || !vm.customerId) {
                 return $q.reject();
             }
             return Card.queryActive({receipt : vm.receipt}).$promise;
         }).then(function(card) {
             vm.cards.push(card);
         });*/

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });
        vm.isValidInput = isValidInput;
        vm.validMerchant = null;
        vm.validAddress = null;

        // Le ricevute inviate da un negozio ufficiale (o in attesa di conferma dopo un claim) sono quasi totalmente readonly
        vm.isOfficial = vm.receipt.source == 'MERCHANT' || vm.receipt.source == 'CLAIM' || vm.receipt.source == 'CLAIM_RESOLVED';
        vm.isAdmin = null;
        vm.checkAccess = checkAccess;
        checkAccess();

        function checkAccess() {
            Principal.identity().then(function (account) {
                if (!Principal.isAuthenticated()) {
                    $state.go("home");
                    return;
                }
                Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                    if (result) {
                        vm.isAdmin = true;
                    } else {
                        vm.isAdmin = false;
                    }
                });
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.resetShop = function () {
            vm.receipt.shop = null;
        }

        $scope.resetMerchant = function () {
            if (vm.receipt.shop !== null) {
                vm.receipt.shop.merchant = null;
            }
        }


        $scope.loadCustomers = function (searchQuery) {
            if (searchQuery && searchQuery.trim().length > 1) {
                CustomerSearch.query({ query: searchQuery }, function (result) {
                    $scope.customers = result;
                }, function (response) {
                    if (response.status === 404) {
                        $scope.customers = Customer.query();
                    }
                });
            } else {
                $scope.customers = CustomerSearch.query();
            }
        };





        $scope.loadActiveCards = function () {
            if (vm.receipt.customer)
                vm.receipt.customerId = vm.receipt.customer.id;
            if (vm.receipt.customer && vm.receipt.buyingDate)
                vm.cards = Card.queryActive(vm.receipt);
        };

        // Constraints beyond the ones in the form
        function isValidInput() {
            // No shop or existing shop are ok
            if (vm.receipt.shop == null || vm.receipt.shop.id != null) {
                return true;
            }

            // New shop must be validated
            // Merchant must be valid
            vm.validMerchant = vm.receipt.shop.merchant != null &&
                (vm.receipt.shop.merchant.id != null || (vm.receipt.shop.owner.name != null && vm.receipt.shop.owner.name.trim().length > 0));
            // And either ecommerce url or address must be valid
            vm.validAddress =
                (vm.receipt.shop.ecommerceUrl != null && vm.receipt.shop.ecommerceUrl.trim().length > 0)
                ||
                (vm.receipt.shop.address != null &&
                    vm.receipt.shop.address.street != null && vm.receipt.shop.address.street.trim().length > 0
                    && vm.receipt.shop.address.city != null && vm.receipt.shop.address.city.trim().length > 0);

            return vm.validMerchant && vm.validAddress;
        }

        function save() {
            vm.isSaving = true;
            if (vm.receipt.id !== null) {
                Receipt.update(vm.receipt, onSaveSuccess, onSaveError);
            } else {
                Receipt.save(vm.receipt, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('garanteasyApp:receiptUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.buyingDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
