(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('garanteasyApp')
        .constant('VERSION', "1.9.0")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('APPKEY', "58dd1674e73542b061f03fed54c1a11d8f0deb3ee5aed669a67ac5fdf51f7972")
        .constant('DEFAULT_BARCODE_ENCODING', "EAN13")
        .constant('CMS_URI', "/cms/")
;
})();
