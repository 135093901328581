(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('warranty-claim', {
                parent: 'entity',
                url: '/warranty-claim',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.warrantyClaim.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/warranty-claim/warranty-claims.html',
                        controller: 'WarrantyClaimController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('warrantyClaim');
                        $translatePartialLoader.addPart('feedback');
                        $translatePartialLoader.addPart('warrantyClaimStatus');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('receipt');
                        return $translate.refresh();
                    }]
                }
            })
            .state('warranty-claim-detail', {
                parent: 'entity',
                url: '/warranty-claim/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.warrantyClaim.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/warranty-claim/warranty-claim-detail.html',
                        controller: 'WarrantyClaimDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('warrantyClaim');
                        $translatePartialLoader.addPart('warrantyClaimStatus');
                        $translatePartialLoader.addPart('warrantyClaimFile');
                        $translatePartialLoader.addPart('feedback');
                        $translatePartialLoader.addPart('receipt');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'WarrantyClaim', function ($stateParams, WarrantyClaim) {
                        return WarrantyClaim.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'warranty-claim',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('warranty-claim-new', {
                parent: 'warranty-claim',
                url: '/{receiptId}/new',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.warrantyClaim.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/warranty-claim/warranty-claim-new.html',
                        controller: 'WarrantyClaimNewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('warrantyClaim');
                        $translatePartialLoader.addPart('feedback');
                        $translatePartialLoader.addPart('warrantyClaimStatus');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('receipt');
                        console.log("RESOLVE");
                        return $translate.refresh();
                    }],
                    entity: [
                        "$stateParams",
                        "Receipt",
                        function ($stateParams, Receipt) {
                            return Receipt.get({ id: $stateParams.receiptId }).$promise;
                        }
                    ],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'warranty-claim',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            }).state('warranty-claim-product-new', {
              parent: 'warranty-claim',
              url: '/purchasedProduct/{idPurchasedProduct}/new',
              data: {
                  authorities: ['ROLE_USER'],
                  pageTitle: 'garanteasyApp.warrantyClaim.home.title'
              },
              views: {
                  'content@': {
                      templateUrl: 'app/entities/warranty-claim/warranty-claim-product-new.html',
                      controller: 'WarrantyClaimProductNewController',
                      controllerAs: 'vm'
                  }
              },
              resolve: {
                  translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                      $translatePartialLoader.addPart('warrantyClaim');
                      $translatePartialLoader.addPart('feedback');
                      $translatePartialLoader.addPart('warrantyClaimStatus');
                      $translatePartialLoader.addPart('global');
                      $translatePartialLoader.addPart('receipt');
                      return $translate.refresh();
                  }],
                  previousState: ["$state", function ($state) {
                      var currentStateData = {
                          name: $state.current.name || 'warranty-claim',
                          params: $state.params,
                          url: $state.href($state.current.name, $state.params)
                      };
                      return currentStateData;
                  }]
              }
          });
    }
})();
