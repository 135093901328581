angular
    .module('garanteasyApp')
    .component('cardReceipt', {
        templateUrl: 'app/components/cards/receipt/receipt.component.html',
        controller: CardReceiptComponentController,
        controllerAs: 'vm',
        bindings: {
            receipt: '<'
        }
    });

CardReceiptComponentController.$inject = ['AuthServerProvider', 'DataUtils'];

function CardReceiptComponentController(AuthServerProvider, DataUtils) {
    var vm = this;
    vm.showCustomerName = function (customer) {
        return DataUtils.showCustomerName(customer);
    };
    vm.getImageUrl = function (receipt) {
        if (((receipt || {}).receiptFiles || {}).length > 0) {
            var image = _.find(receipt.receiptFiles, function (o) { return o.fileContentType.startsWith('image/'); });
            if (image) {
                return "api/receipt-files/content/" + image.id + "?width=-&height=150&token=" + AuthServerProvider.getToken();
            } else {
                return null;
            }
        }
    };
}
