(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('mobile-navigation-info', {
                parent: 'entity',
                url: '/mobile-navigation-info',
                data: {
                    authorities: []
                },
                views: {
                    'sidebar@': {
                        templateUrl: 'app/layouts/sidebar/sidebar.html',
                        controller: 'SidebarController',
                        controllerAs: 'vm'
                    },
                    'content@': {
                        templateUrl: 'app/layouts/mobile-navigation/info.html',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('receipt');
                        return $translate.refresh();
                    }]
                }
            })
            .state('mobile-navigation-profile', {
                parent: 'entity',
                url: '/mobile-navigation-profile',
                data: {
                    authorities: []
                },
                views: {
                    'sidebar@': {
                        templateUrl: 'app/layouts/sidebar/sidebar.html',
                        controller: 'SidebarController',
                        controllerAs: 'vm'
                    },
                    'content@': {
                        templateUrl: 'app/layouts/mobile-navigation/profile.html',
                        controller: 'ProfileController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('receipt');
                        return $translate.refresh();
                    }]
                }
            })
            .state('mobile-navigation-archive', {
                parent: 'entity',
                url: '/mobile-navigation-archive',
                data: {
                    authorities: []
                },
                views: {
                    'sidebar@': {
                        templateUrl: 'app/layouts/sidebar/sidebar.html',
                        controller: 'SidebarController',
                        controllerAs: 'vm'
                    },
                    'content@': {
                        templateUrl: 'app/layouts/mobile-navigation/archive.html',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('receipt');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
