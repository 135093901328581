(function () {
  'use strict';
  angular
    .module('garanteasyApp')
    .factory('MerchantRegistration', MerchantRegistration);

  MerchantRegistration.$inject = ['$resource', 'DateUtils'];

  function MerchantRegistration($resource, DateUtils) {
    var resourceUrl = '/api/merchant-register/:id';

    return $resource(resourceUrl, {}, {
      'searchCategories': {
        url: 'api/merchant-register/categories/search',
        method: 'GET',
        isArray: true
      },
      'searchShop': {
        url: 'api/merchant-register/shop/search/:merchant_id',
        method: 'GET',
        isArray: true
      },
      'searchMerchant': {
        url: 'api/merchant-register/merchant/search',
        method: 'GET',
        isArray: true,
        transformResponse: function (data, headers) {
          var response = {};
          response = angular.fromJson(data);
          return response;
        }
      },
      'update': {
        method: 'PUT',
        transformRequest: function (data) {
          var dataToSend = angular.copy(data);
          if (dataToSend.activationDate) {
            dataToSend.activationDate = DateUtils.convertLocalDateToServer(dataToSend.activationDate);
          }
          if (dataToSend.expirationDate) {
            dataToSend.expirationDate = DateUtils.convertLocalDateToServer(dataToSend.expirationDate);
          }
          return angular.toJson(dataToSend);
        }
      },
      'getCode': {
        method: 'POST',
        url: 'api/merchant-register/merchant/code',
        transformResponse: function (data, headers) {
          var response = {};
          response = angular.fromJson(data);
          return response;
        }
      },
      'saveRequired': {
        method: 'POST',
        transformRequest: function (data) {
          var dataToSend = angular.copy(data);
          return angular.toJson(dataToSend);

        },
        transformResponse: function (data, headers) {
          var response = {};
          response = angular.fromJson(data);
          return response;
        }
      }
    });
  }
})();
