(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('MerchantRegistrationController2', MerchantRegistrationController2);


  MerchantRegistrationController2.$inject = ['$translate', '$timeout', 'Auth', 'LoginService', '$location', '$filter', '$state', 'MerchantRegistration', '$scope', 'AlertService'];

  function MerchantRegistrationController2($translate, $timeout, Auth, LoginService, $location, $filter, $state, MerchantRegistration, $scope, AlertService) {
    var vm = this;
    vm.doNotMatch = null;
    vm.error = null;
    vm.errorUserExists = null;
    vm.errorServiceConsent = null;
    vm.merchantRegistration = {};
    vm.merchantRegistration.operators = [{}];
    vm.success = null;
    vm.error = false;
    vm.save = save;
    vm.isValidInput = isValidInput;
    vm.merchantRegistration.webSite = false;
    vm.merchantRegistration.eCommerce = false;
    vm.merchantRegistration.questionPhone = true;
    vm.merchantRegistration.questionMail = true;

    if ($state.operators) {
      vm.merchantRegistration.operators = $state.operators;
    }
    if ($location.search().merchantCode) {
      vm.merchantCode = $location.search().merchantCode.toUpperCase();
    } else {
      vm.merchantCode = 'GARANTEASY';
    }

    $timeout(function () { angular.element('#login').focus(); });

    $scope.loadCategories = function (searchQuery) {
      if (searchQuery && searchQuery.trim().length > 1) {
        MerchantRegistration.searchCategories({ query: searchQuery }, onLoadCategoriesSuccess, onLoadCategoriesError)
      }
    };

    function onLoadCategoriesSuccess(result) {
      $scope.categories = result;
    }

    function onLoadCategoriesError(result) {

    }

    $scope.resetCategory = function () {
      vm.merchantRegistration.category = null;
    }


    $scope.resetMerchant = function () {
      vm.merchantRegistration.merchant = null;
    }

    $scope.loadMerchants = function (searchQuery) {
      if (searchQuery) {
        MerchantRegistration.searchMerchant({ query: searchQuery }, onLoadSuccess, onLoadError);
      }
    };

    function onLoadSuccess(result) {
      $scope.merchants = result;
    }

    function onLoadError(error) {
      AlertService.error(error.data.message);
    }


    $scope.resetShop = function () {
      vm.merchantRegistration.shop = null;
    }

    $scope.loadShops = function (searchQuery) {
      if (searchQuery) {
        MerchantRegistration.searchShop({ merchant_id: vm.merchantRegistration.merchant.id, query: searchQuery }, function (result) {
          $scope.shops = result;
        }
          /*, function(response) {
              if(response.status === 404) {
                  $scope.shops = Shop.query({isActive: true});
              }
          };*/
        );
      }
      /*else {
          $scope.shops = Shop.query({isActive: true});
      }*/
    };

    function onGetCodeSuccess(result) {
      vm.merchantRegistration.merchant.code = result.code;
      if (vm.merchantRegistration.operators[0] != null && vm.merchantRegistration.operators[0].name != null) {
        vm.merchantRegistration.operators[0].type = 'REFERENT';
      }
      if (vm.merchantRegistration.operators[1] != null && vm.merchantRegistration.operators[1].name != null) {
        vm.merchantRegistration.operators[1].type = 'OPERATING_REFERENT';
      }
      MerchantRegistration.save(vm.merchantRegistration, onSaveSuccess, onSaveError);
    }

    function onGetCodeError(error) {
      vm.error = error;
    }

    function save() {
      if (vm.merchantRegistration.merchant.email && vm.merchantRegistration.shop) {
        vm.merchantRegistration.shop.email = vm.merchantRegistration.merchant.email;
      }
      if (vm.merchantRegistration.merchant.phoneNumber && vm.merchantRegistration.merchant.shop) {
        vm.merchantRegistration.shop.phoneNumber = vm.merchantRegistration.merchant.phoneNumber;
      }
      if (vm.merchantRegistration.merchant.phoneNumber && vm.merchantRegistration.merchant.shop) { vm.merchantRegistration.shop.phoneNumber = vm.merchantRegistration.merchant.phoneNumber; }
      if (vm.category) {
        vm.merchantRegistration.merchant.categories = [];
        vm.merchantRegistration.merchant.categories[0] = vm.category;
      }


      if (angular.isUndefined(vm.merchantRegistration.shop)) {
        vm.error = true;
        $translate("merchantRegister2.error.shopRequired").then(function (t) {
          vm.errorMessage = t;
        });
      } else {
        // se id --> null sto creando un nuovo merchant e devo generare il code
        if (vm.merchantRegistration.merchant.id == null) {
          MerchantRegistration.getCode(vm.merchantRegistration.merchant, onGetCodeSuccess, onGetCodeError);
        } else {
          MerchantRegistration.save(vm.merchantRegistration, onSaveSuccess, onSaveError);
        }
      }
    }

    function onSaveSuccess(result) {
      $translate("merchantRegister2.saveSuccess").then(function (t) {
        $state.messageSave = t;
      });
      vm.isSaving = false;
      $state.go('home');
    }

    function onSaveError(error) {
      vm.error = true;
      if (error.headers()['x-garanteasyapp-error']) {
        var errorType = error.headers()['x-garanteasyapp-error'];
        $translate("merchantRegister2." + errorType).then(function (t) {
          vm.errorMessage = t;
        });
      } else {
        $translate("merchantRegister2.error.genericSaveError").then(function (t) {
          vm.errorMessage = t;
        });
      }
    }

    function isValidInput() {
      if (vm.merchantRegistration.merchant && vm.merchantRegistration.merchant.companyName && vm.merchantRegistration.merchant.vatCode) {
        if (vm.merchantRegistration.operators.length > 0) {
          return true;
        }
      }
    }


  }
})();
