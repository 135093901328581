(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('CustomerController', CustomerController);

    CustomerController.$inject = ['$state', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Dashboard', 'Customer', '$log', '$rootScope', 'DataUtils'];

    function CustomerController($state, ParseLinks, AlertService, pagingParams, paginationConstants, Dashboard, Customer, $log, $rootScope, DataUtils) {
        var vm = this;
        vm.customers = [];
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.links = {
            last: 0
        };
        vm.isLoading = false;
        vm.name = 'CUSTOMERS';
        vm.gridView = $rootScope.gridView;
        loadAll();

        vm.showCustomerName = function (customer) {
            return DataUtils.showCustomerName(customer);
        };
        function loadAll() {
            if (vm.isLoading) return;
            vm.isLoading = true;
            Dashboard.getCustomers({
                page: vm.page,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            if (headers('link')) {
                vm.links = ParseLinks.parse(headers('link'));
            }
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.itemsPerPage = 20;
            vm.customers = vm.customers.concat(data);
            angular.forEach(vm.customers, function (customer) {
                Customer.getCustomerDetail({ customer_id: customer.id }, function (detail) {
                    $log.debug(detail);
                    customer.cards = detail.cards;
                    countReceiptAndFindLast(customer, detail);
                },
                    function (err) {
                    });
            });
            vm.isLoading = false;
        }

        function onError(error) {
            vm.isLoading = false;
            AlertService.error(error.data.message);
        }

        function loadPage(page) {
            vm.page = page;
            vm.loadAll();
        }

        function transition() {
            reset();
            vm.loadAll();
        }

        function reset() {
            vm.page = 0;
            vm.customers = [];
            loadAll();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function countReceiptAndFindLast(customer, detail) {
          var receiptIds = [];
          var lastReceipt;
          if (Array.isArray(detail.garanteasyProducts)) {
            detail.garanteasyProducts.forEach(function(item) {
              if (item.receipt && item.receipt.id) {
                if (receiptIds.indexOf(item.receipt.id) === -1) {
                  receiptIds.push(item.receipt.id);
                }
                if (!lastReceipt) {
                  lastReceipt = item.receipt;
                } else {
                  if (item.receipt.creationDate > lastReceipt.creationDate) {
                    lastReceipt = item.receipt;
                  }
                }
              }
            });
          }
          customer.receiptCount = receiptIds.length;
          customer.lastReceipt = lastReceipt;
        }
    }
})();
