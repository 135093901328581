(function() {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('PrivacyPolicy', PrivacyPolicy);

    PrivacyPolicy.$inject = ['$resource'];

    function PrivacyPolicy($resource) {
        var resourceUrl = 'api/privacy-policies/:id';

        return $resource(resourceUrl, {}, {

            'reg': {
                method: 'GET',
                isArray: true,
                url: 'api/privacy-policies/reg',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'regMerchantCode': {
                method: 'GET',
                isArray: true,
                url: 'api/privacy-policies/reg/:merchant_code',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'requiredGet': {
                method: 'GET',
                isArray: true,
                url: 'api/privacy-policies/required',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'requiredPost': {
                method: 'POST',
                url: 'api/privacy-policies/required',
                isArray: true
                    /* transformRequest: function (data) {
                         var dataToSend = angular.copy(data);
                         return angular.toJson(dataToSend);
                     }*/
            }
        });
    }
})();