'use strict';
(function () {

  angular
    .module('garanteasyApp')
    .controller('CmsController', CmsController);


  CmsController.$inject = ['$state', '$stateParams', '$translate', 'CmsService', '$log', 'cmsViewMode', 'CMS_URI', '$sce', 'SelectedMerchantService'];

  function CmsController($state, $stateParams, $translate, CmsService, $log, cmsViewMode, CMS_URI, $sce, SelectedMerchantService) {
    var vm = this;

    vm.node = '';
    vm.lang = 'eng';

    vm.data = [];
    vm.init = init;
    vm.cmsViewMode = cmsViewMode;
    vm.CMS_URI = CMS_URI;
    function toDTO(element) {
      return {
        id: element.id,
        title: element.title,
        body: element.body,
        field_media_file: element.field_media_file,
        name: element.name
      };
    }
    vm.trustSrc = function (src) {
      return $sce.trustAsResourceUrl(src);
    }
    vm.loadData = function (node, lang, merchantId) {
      CmsService.getCmsData(node, lang, merchantId).then(function (res) {
        if (node == cmsViewMode.video) {
          res.data.map(function (element) {
            var c = element.field_media_video_embed_field;
            var start = c.indexOf('src="') + 5;
            var end = c.indexOf('"></iframe>');
            var s = c.substring(start, end);
            element.field_media_video_embed_field = s;
          });
        }
        vm.data = res.data;
      });
    };
    init();
    function init() {
      vm.node = $stateParams.node;
      if ($translate.use()) {
        vm.lang = $translate.use();
      }
      if (!SelectedMerchantService.merchant) {
        SelectedMerchantService.onMerchantChange(function (data) {
          vm.merchantId = data.cmsReference;
          vm.loadData(vm.node, vm.lang, vm.merchantId);
        });
      } else {
        vm.merchantId = SelectedMerchantService.merchant.cmsReference;
        vm.loadData(vm.node, vm.lang, vm.merchantId);
      }
    }
  }
})();
