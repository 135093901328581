(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('ProductDetailController', ProductDetailController);

    ProductDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'Principal', 'entity', 'Product', 'User', 'Brand', 'DataUtils', 'ProductResources', 'Maintenance', 'DEFAULT_BARCODE_ENCODING'];

    function ProductDetailController($scope, $rootScope, $stateParams, previousState, Principal, entity, Product, User, Brand, DataUtils, ProductResources, Maintenance, DEFAULT_BARCODE_ENCODING) {
        var vm = this;



        vm.barCodeOpt = undefined;
        vm.getBCOptions = function (format) {

            if (!vm.barCodeOpt) {

                vm.barCodeOpt = {
                    format: (format || DEFAULT_BARCODE_ENCODING).toUpperCase(),
                    lineColor: '#000000',
                    width: 2,
                    height: 100,
                    displayValue: true,
                    fontOptions: '',
                    font: 'monospace',
                    textAlign: 'center',
                    textPosition: 'bottom',
                    textMargin: 2,
                    fontSize: 20,
                    background: '#ffffff',
                    margin: 0,
                    marginTop: undefined,
                    marginBottom: undefined,
                    marginLeft: undefined,
                    marginRight: undefined,
                    valid: function (valid) {
                    }
                };
            }

            return vm.barCodeOpt
        };
        vm.product = entity;

        if (vm.product.images.length > 0) {
            vm.image = vm.product.images[0];
        }

        vm.changeMainImage = function (image) {
            if (vm.product.images != undefined && vm.product.images.length > 1) {
                $('.zoomContainer').remove();
                vm.image = image;
            }
        }

        if ($stateParams.from) {
            $scope.from = $stateParams.from
        }
        if ($stateParams.from2nd) {
            $scope.from2nd = $stateParams.from2nd
        }

        vm.isOwner = null;
        vm.isAdmin = null;




        checkAccess();

        function checkAccess() {
            Principal.identity().then(function (account) {
                if (!Principal.isAuthenticated()) {
                    $state.go("home");
                    return;
                }
                vm.isOwner = vm.product.createdBy.id == account.id;
                Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                    if (result) {
                        vm.isAdmin = true;
                    } else {
                        vm.isAdmin = false;
                    }
                });
            });
        }

        vm.uploadFile = function ($file) {
            vm.productResource = {};

            vm.byteSize = DataUtils.byteSize;
            vm.openFile = DataUtils.openFile;
            vm.fileTooLarge = false;
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                if ($file.size > 5120000) {
                    vm.fileTooLarge = true;
                    // Reset data in case there was a previous image selected
                    vm.productResource.description = null;
                    vm.productResource.productId = null;
                    vm.productResource.fileData = null;
                    vm.productResource.contentType = null;
                    vm.productResource.type = null;
                } else {
                    vm.fileTooLarge = false;
                    DataUtils.toBase64($file, function (base64Data) {
                        $scope.$apply(function () {
                            vm.productResource.description = $file.name;
                            vm.productResource.productId = vm.product.id;
                            vm.productResource.fileData = base64Data;
                            vm.productResource.contentType = $file.type;
                            vm.productResource.type = ProductResources.RType.IMAGE;
                            ProductResources.save(vm.productResource, function (result) {
                                vm.product.images.push(result);
                                if (result) {
                                    vm.image = result;
                                    $('.zoomContainer').remove();
                                }
                            });
                        });
                    });
                }
            }
        };





        vm.deleteImage = function (image) {
            ProductResources.delete({ id: image.id }, function () {
                var ind = vm.product.images.indexOf(image);
                vm.product.images.splice(ind, ind + 1);
                $('.zoomContainer').remove();
                vm.image = vm.product.images[0];
            });
        };

        var unsubscribe = $rootScope.$on('garanteasyApp:productUpdate', function (event, result) {
            vm.product = result;
        });
        $scope.$on('$destroy', unsubscribe);
        $scope.$on('$destroy', function (event, next, current) {
            $('.zoomContainer').remove();
        });
    }
})();
