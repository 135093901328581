(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .filter('receiptUse', receiptUse);

    receiptUse.$inject = ['$translate'];
    function receiptUse($translate) {
        return receiptUseFilter;

        function receiptUseFilter(input) {
            if (!input) {
                input = null;
            }
            else {
                input = 'garanteasyApp.ReceiptUse.' + input;
            }
            return input;
        }
    }
})();
