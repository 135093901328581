(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('customer', {
                parent: 'entity',
                url: '/customer?page&sort&search&documents&customers&shops&s',
                /*ncyBreadcrumb: {
    
                    parent: function ($scope) {
                        return 'dashboard';
                    }
                },*/
                ncyBreadcrumb: {
                    label: "{{'garanteasyApp.customer.home.title' | translate}}"
                },
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.customer.home.title'
                },
                views: {
                    'sidebar@': {
                        templateUrl: 'app/layouts/sidebar/sidebarCustomers.html'
                    },
                    'content@': {
                        templateUrl: 'app/entities/customer/customers.html',
                        controller: 'CustomerController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '0',
                        squash: true
                    },
                    sort: {
                        value: 'customer.legalName,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('customer');
                        $translatePartialLoader.addPart('dashboard');
                        $translatePartialLoader.addPart('address');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('customer-detail', {
                parent: 'entity',
                url: '/customer/{id}?from',
                ncyBreadcrumb: {
                    label: "{{'garanteasyApp.customer.detail.title' | translate}}",
                    parent: function ($scope) {
                        return $scope.from || 'customer';
                    }
                },
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.customer.detail.title'
                },
                views: {
                    'sidebar@': {
                        templateUrl: 'app/layouts/sidebar/sidebarCustomers.html'
                    },
                    'content@': {
                        templateUrl: 'app/entities/customer/customer-detail.html',
                        controller: 'CustomerDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('customer');
                        $translatePartialLoader.addPart('purchasedProduct');
                        $translatePartialLoader.addPart('address');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('contact');
                        $translatePartialLoader.addPart('card');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Customer', function ($stateParams, Customer) {
                        return Customer.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'customer',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('customer-detail/edit', {
                parent: 'customer-detail',
                url: '/detail/edit',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/customer/customer-dialog.html',
                        controller: 'CustomerDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Customer', function (Customer) {
                                return Customer.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: false });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('customer/new', {
                parent: 'customer',
                url: '/new',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/customer/customer-dialog.html',
                        controller: 'CustomerDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    legalName: null,
                                    birthDate: null,
                                    vatid: null,
                                    business: false,
                                    gender: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('customer', null, { reload: true });
                    }, function () {
                        $state.go('customer');
                    });
                }]
            })
            .state('customer/edit', {
                parent: 'customer',
                url: '/{id}/edit',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/customer/customer-dialog.html',
                        controller: 'CustomerDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Customer', function (Customer) {
                                return Customer.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('customer', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('customer/delete', {
                parent: 'customer',
                url: '/{id}/delete',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/customer/customer-delete-dialog.html',
                        controller: 'CustomerDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Customer', function (Customer) {
                                return Customer.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('customer', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
