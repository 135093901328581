(function() {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['Principal', 'Auth', 'Customer', 'Contact', 'PhoneNumber', 'JhiLanguageService', '$translate', 'Card'];

    function SettingsController (Principal, Auth, Customer, Contact, PhoneNumber, JhiLanguageService, $translate, Card) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.settingsAccount = null;
        vm.success = null;
        vm.customers = [];
        vm.contacts = [];
        vm.phoneNumbers = [];
        vm.cards = [];

        vm.isOwner=null;
        vm.isAdmin=null;

        checkAccess();

        function checkAccess() {
            Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                if (result) {
                    vm.isAdmin=true;
                } else {
                    vm.isAdmin=false;
                    }
                });
        }


        loadCustomers();
        loadContacts();
        loadPhoneNumbers();
        loadCards();

        function loadCustomers() {
            Customer.query(function(result) {
                vm.customers = result;
            });
        }

        function loadContacts() {
            Contact.query(function(result) {
                vm.contacts = result;
            });
        }

        function loadPhoneNumbers() {
            PhoneNumber.query(function(result) {
                vm.phoneNumbers = result;
            });
        }

        function loadCards() {
            Card.query(function(result) {
                vm.cards = result;
            });
        }

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                serviceConsent: account.serviceConsent,
                commInfoConsent: account.commInfoConsent,
                commInfo3rdConsent: account.commInfo3rdConsent,
                /*
                firstName: account.firstName,
                lastName: account.lastName,
                */
                langKey: account.langKey,
                login: account.login
            };
        };

        Principal.identity().then(function(account) {
            vm.settingsAccount = copyAccount(account);
        });

        function save () {
            Auth.updateAccount(vm.settingsAccount).then(function() {
                vm.error = null;
                vm.success = 'OK';
                Principal.identity(true).then(function(account) {
                    vm.settingsAccount = copyAccount(account);
                });
                JhiLanguageService.getCurrent().then(function(current) {
                    if (vm.settingsAccount.langKey !== current) {
                        $translate.use(vm.settingsAccount.langKey);
                    }
                });
            }).catch(function() {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }

        vm.isOwner = function(card) {
            var ret = false;
            var account = Principal.identity();
               if(card.contact && card.contact.user && account && account.$$state && account.$$state.value && account.$$state.value.id)
                   ret = card.contact.user.id == account.$$state.value.id;
            return ret;
        }
    }
})();
