angular
    .module('garanteasyApp')
    .component('listItemCustomer', {
        templateUrl: 'app/components/list-item/customer/customer.component.html',
        controller: listItemCustomerComponentController,
        controllerAs: 'vm',
        bindings: {
            customer: '<'
        }
    });

listItemCustomerComponentController.$inject = ['DataUtils'];

function listItemCustomerComponentController(DataUtils) {
    var vm = this;
    vm.showCustomerName = function (customer) {
        return DataUtils.showCustomerName(customer);
    };
}
