(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('LicenseRegistrationController', LicenseRegistrationController);


  LicenseRegistrationController.$inject = ['$translate', '$timeout', 'Auth', 'LoginService', '$location', '$filter', '$state', 'CmsService', '$scope', 'AlertService', 'SelectedMerchantService'];

  function LicenseRegistrationController($translate, $timeout, Auth, LoginService, $location, $filter, $state, CmsService, $scope, AlertService, SelectedMerchantService) {
    var vm = this;
    vm.lang = 'eng';
    vm.textSellCondition = '';
    vm.textPrivacyCondition = '';
    vm.merchantRegistration = {};
    vm.merchantRegistration.operators = [{}];
    vm.merchantCode = null;
    vm.copyToCliboard = copyToCliboard;
    vm.save = save;
    vm.model = {
      get merchant() {
          return SelectedMerchantService.merchant;
      },
      set merchant(item) {
          return SelectedMerchantService.merchant = item;
      },
      get shop() {
          return SelectedMerchantService.shop;
      },
      set shop(item) {
          return SelectedMerchantService.shop = item;
      }
  };

    init();

    if ($state.operators) {
      vm.merchantRegistration.operators = $state.operators;
    }
    if ($location.search().merchantCode) {
      vm.merchantCode = $location.search().merchantCode.toUpperCase();
    } else {
      vm.merchantCode = 'GARANTEASY';
    }

    function init() {
      if ($translate.use()) {
        vm.lang = $translate.use();
      }
      CmsService.getLicenceData(vm.lang).then(function (res) {
        if (res && Array.isArray(res)) {
          res.forEach(function (item) {
            if (typeof item.title === 'string' && item.title.toLowerCase().indexOf('vendita') !== -1) {
              vm.textSellCondition = item.body;
            }
            if (typeof item.title === 'string' && item.title.toLowerCase().indexOf('privacy') !== -1) {
              vm.textPrivacyCondition = item.body;
            }
          });
        }
        console.log(vm);
      });
    }

    function copyToCliboard(copyText) {
      var div = document.createElement("div");
      div.innerHTML = copyText;
      var text = div.textContent || div.innerText || "";

      var el = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

    }

    function save() {

    }


  }
})();
