(function () {
  "use strict";

  angular.module("garanteasyApp").config(stateConfig);

  stateConfig.$inject = ["$stateProvider"];

  function stateConfig($stateProvider) {
    $stateProvider
      .state("receipt", {
        parent: "entity",
        url: "/receipt?page&sort&search&documents&customers&shops&s",
        ncyBreadcrumb: {
          label: "{{'garanteasyApp.receipt.home.title' | translate}}"
        },
        data: {
          authorities: ["ROLE_USER"],
          pageTitle: "garanteasyApp.receipt.home.title"
        },
        views: {
          "sidebar@": {
            templateUrl: "app/entities/receipt/sidebar-receipts.html"
          },
          "content@": {
            templateUrl: "app/entities/receipt/receipts.html",
            controller: "ReceiptController",
            controllerAs: "vm"
          }
        },
        params: {
          page: {
            value: "0",
            squash: true
          },
          sort: {
            value: "id,desc",
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: [
            "$stateParams",
            "PaginationUtil",
            function ($stateParams, PaginationUtil) {
              return {
                page: PaginationUtil.parsePage($stateParams.page),
                sort: $stateParams.sort,
                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                ascending: PaginationUtil.parseAscending($stateParams.sort),
                search: $stateParams.search
              };
            }
          ],
          translatePartialLoader: [
            "$translate",
            "$translatePartialLoader",
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart("receipt");
              $translatePartialLoader.addPart("paymentType");
              $translatePartialLoader.addPart("dataSource");
              $translatePartialLoader.addPart("warranty");
              $translatePartialLoader.addPart("global");
              $translatePartialLoader.addPart("receiptType");
              $translatePartialLoader.addPart("receiptUse");
              return $translate.refresh();
            }
          ]
        }
      })
      .state("receiptFiltered", {
        parent: "entity",
        url: "/receipt",
        params: { filterWarranty: null },
        ncyBreadcrumb: {
          label: "{{'garanteasyApp.receipt.home.title' | translate}}"
        },
        data: {
          authorities: ["ROLE_USER"],
          pageTitle: "garanteasyApp.receipt.home.title"
        },
        views: {
          "sidebar@": {
            templateUrl: "app/entities/receipt/sidebar-receipts.html"
          },
          "content@": {
            templateUrl: "app/entities/receipt/receipts.html",
            controller: "ReceiptController",
            controllerAs: "vm"
          }
        },
        resolve: {
          translatePartialLoader: [
            "$translate",
            "$translatePartialLoader",
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart("receipt");
              $translatePartialLoader.addPart("paymentType");
              $translatePartialLoader.addPart("dataSource");
              $translatePartialLoader.addPart("warranty");
              $translatePartialLoader.addPart("global");
              $translatePartialLoader.addPart("receiptType");
              return $translate.refresh();
            }
          ]
        }
      })
      .state("claimChiccoReceipt", {
        parent: "entity",
        url: "/claimChiccoReceipt",
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          pageTitle: "garanteasyApp.receipt.claim.title"
        },
        views: {
          "content@": {
            templateUrl: "app/entities/receipt/receipt-claim-dialog.html",
            controller: "ReceiptClaimController",
            controllerAs: "vm"
          }
        },
        resolve: {
          translatePartialLoader: [
            "$translate",
            "$translatePartialLoader",
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart("receipt");
              $translatePartialLoader.addPart("home");
              $translatePartialLoader.addPart("global");
              return $translate.refresh();
            }
          ],
          //Definisco una modal instance nulla per usare lo stesso controller in versione modale e non modale
          $uibModalInstance: function () {
            return null;
          },
          // search parameters, will be mapped to ReceiptMatcherDTO
          entity: function () {
            return {
              shopCode: null,
              counterCode: null,
              receiptNumber: null,
              errorCode: null,
              email: null
            };
          }
        }
      })
      /* Equivalente dello stato claimChiccoReceipt chiamato con una dialog modale da dentro l'area riservata. Al momento si è deciso di non renderlo disponibile.
        .state('receiptClaim', {
            parent: 'entity',
            url: '/receiptClaim',
			ncyBreadcrumb: {
				skip: true // Never display this state in breadcrumb.
			},
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/receipt/receipt-claim-dialog.html',
                    controller: 'ReceiptClaimController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('receipt');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }],
                        // search parameters, will be mapped to ReceiptMatcherDTO
                        entity: function () {
                            return {
                            	shopCode: null,
                            	counterCode: null,
                            	receiptNumber: null,
                            	receiptId: null,
                            	email: null
                            };
                        }
                    }
                }).result.then(function(receipt) {
                	console.log("Claimed receipt with id " + receipt.id);
                    $state.go('receipt.detail', {"id": receipt.id}, { reload: true });
                }, function() {
                    $state.go('receipt');
                });
            }]
        })
        */
      .state("receipt/detail", {
        parent: "entity",
        url: "/receipt/{id}?from",
        ncyBreadcrumb: {
          label: "{{'garanteasyApp.receipt.detail.title' | translate}}",
          parent: function ($scope) {
            return $scope.from || "receipt";
          }
        },
        data: {
          authorities: ["ROLE_USER"],
          pageTitle: "garanteasyApp.receipt.detail.title"
        },
        views: {
          "sidebar@": {
            templateUrl: "app/entities/receipt/sidebar-receipt-detail.html",
            controller: "ReceiptDetailController",
            controllerAs: "vm"
          },
          "content@": {
            templateUrl: "app/entities/receipt/receipt-detail.html",
            controller: "ReceiptDetailController",
            controllerAs: "vm"
          }
        },
        resolve: {
          translatePartialLoader: [
            "$translate",
            "$translatePartialLoader",
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader
                .addPart("address")
                .addPart("brand")
                .addPart("customer")
                .addPart("dataSource")
                .addPart("feedback")
                .addPart("merchant")
                .addPart("paymentType")
                .addPart("product")
                .addPart("purchasedProduct")
                .addPart("receipt")
                .addPart("receiptFile")
                .addPart("receiptType")
                .addPart("shop")
                .addPart("warranty")
                .addPart("warrantyClaim");
              return $translate.refresh();
            }
          ],
          entity: [
            "$stateParams",
            "Receipt",
            function ($stateParams, Receipt) {
              return Receipt.get({ id: $stateParams.id }).$promise;
            }
          ],
          previousState: [
            "$state",
            function ($state) {
              var currentStateData = {
                name: $state.current.name || "receipt",
                params: $state.params,
                url: $state.href($state.current.name, $state.params)
              };
              return currentStateData;
            }
          ]
        }
      })
      .state("receipt/detail/edit", {
        parent: "receipt/detail",
        url: "/detail/edit",
        data: {
          authorities: ["ROLE_USER"]
        },
        onEnter: [
          "$stateParams",
          "$state",
          "$uibModal",
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: "app/entities/receipt/receipt-dialog.html",
                controller: "ReceiptDialogController",
                controllerAs: "vm",
                backdrop: "static",
                size: "lg",
                resolve: {
                  entity: [
                    "Receipt",
                    function (Receipt) {
                      return Receipt.get({ id: $stateParams.id }).$promise;
                    }
                  ]
                }
              })
              .result.then(
                function () {
                  $state.go("^", {}, { reload: false });
                },
                function () {
                  $state.go("^");
                }
              );
          }
        ]
      })
      .state("receipt/detail/editGift", {
        parent: "receipt/detail",
        url: "/detail/editGift",
        params: { id: null, listSelected: null },
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ["ROLE_USER"]
        },
        onEnter: [
          "$stateParams",
          "$state",
          "$uibModal",
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: "app/entities/receipt/receipt-gift-dialog.html",
                controller: "ReceiptGiftDialogController",
                controllerAs: "vm",
                backdrop: "static",
                size: "lg",
                resolve: {
                  entity: [
                    "Receipt",
                    function (Receipt) {
                      return Receipt.get({ id: $stateParams.id }).$promise;
                    }
                  ]
                }
              })
              .result.then(
                function () {
                  $state.go("^", {}, { reload: false });
                },
                function () {
                  $state.go("^");
                }
              );
          }
        ]
      })
      .state("receipt/new", {
        parent: "receipt",
        url: "/new",
        data: {
          authorities: ["ROLE_USER"]
        },
        onEnter: [
          "$stateParams",
          "$state",
          "$uibModal",
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: "app/entities/receipt/receipt-dialog.html",
                controller: "ReceiptDialogController",
                controllerAs: "vm",
                backdrop: "static",
                size: "lg",
                resolve: {
                  entity: function () {
                    return {
                      receiptNumber: null,
                      counterCode: null,
                      description: null,
                      creationDate: null,
                      buyingDate: null,
                      paymentType: null,
                      source: "DATAENTRY",
                      id: null
                    };
                  }
                }
              })
              .result.then(
                function () {
                  $state.go("receipt", null, { reload: true });
                },
                function () {
                  $state.go("receipt");
                }
              );
          }
        ]
      })
      /* Modifica dall'elenco attualmente disabilitata
            .state('receipt.edit', {
                parent: 'receipt',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/receipt/receipt-dialog.html',
                        controller: 'ReceiptDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Receipt', function(Receipt) {
                                return Receipt.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('receipt', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            */

      .state("receipt/delete", {
        parent: "receipt/detail",
        url: "/{id}/delete",
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ["ROLE_USER"]
        },
        onEnter: [
          "$stateParams",
          "$state",
          "$uibModal",
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: "app/entities/receipt/receipt-delete-dialog.html",
                controller: "ReceiptDeleteController",
                controllerAs: "vm",
                size: "md",
                resolve: {
                  entity: [
                    "Receipt",
                    function (Receipt) {
                      return Receipt.get({ id: $stateParams.id }).$promise;
                    }
                  ]
                }
              })
              .result.then(
                function (arg) {
                  if ($state.params.receiptId == undefined)
                    $state.go("receipt", null, { reload: true });
                  else
                    $state.go(
                      "receipt/detail",
                      { id: $state.params.receiptId },
                      {
                        reload: false,
                        location: true,
                        relative: false,
                        inherit: false
                      }
                    );
                },
                function () {
                  $state.go("^");
                }
              );
            /*    .result.then(function() {
                        $state.go('receipt', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });*/
          }
        ]
      })
      .state("receipt/feedback-new", {
        parent: "receipt/detail",
        url: "/{id}/addReceiptFeedback",
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ["ROLE_USER"]
        },
        onEnter: [
          "$stateParams",
          "$state",
          "$uibModal",
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: "app/entities/feedback/feedback-dialog.html",
                controller: "FeedbackDialogController",
                controllerAs: "vm",
                backdrop: "static",
                size: "lg",
                resolve: {
                  entity: function () {
                    return {
                      description: null,
                      creationDate: new Date(),
                      feedbackType: "BUYING",
                      id: null,
                      parentId: $stateParams.id
                    };
                  }
                }
              })
              .result.then(
                function () {
                  $state.go("receipt/detail", null, { reload: true });
                },
                function () {
                  $state.go("receipt/detail");
                }
              );
          }
        ]
      })
      .state("receipt/feedback-edit", {
        parent: "receipt/detail",
        url: "/{id}/editReceiptFeedback/{feedbackId}",
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ["ROLE_USER"]
        },
        onEnter: [
          "$stateParams",
          "$state",
          "$uibModal",
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: "app/entities/feedback/feedback-dialog.html",
                controller: "FeedbackDialogController",
                controllerAs: "vm",
                backdrop: "static",
                size: "lg",
                resolve: {
                  entity: [
                    "Feedback",
                    function (Feedback) {
                      return Feedback.get({ id: $stateParams.feedbackId })
                        .$promise;
                    }
                  ]
                }
              })
              .result.then(
                function () {
                  $state.go("receipt/detail", null, { reload: true });
                },
                function () {
                  $state.go("receipt/detail");
                }
              );
          }
        ]
      })
      .state("receipt/feedback-delete", {
        parent: "receipt/detail",
        url: "/{id}/deleteReceiptFeedback/{feedbackId}",
        ncyBreadcrumb: {
          skip: true // Never display this state in breadcrumb.
        },
        data: {
          authorities: ["ROLE_USER"]
        },
        onEnter: [
          "$stateParams",
          "$state",
          "$uibModal",
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl:
                  "app/entities/feedback/feedback-delete-dialog.html",
                controller: "FeedbackDeleteController",
                controllerAs: "vm",
                size: "md",
                resolve: {
                  entity: [
                    "Feedback",
                    function (Feedback) {
                      return Feedback.get({ id: $stateParams.feedbackId })
                        .$promise;
                    }
                  ]
                }
              })
              .result.then(
                function () {
                  $state.go("receipt/detail", null, { reload: true });
                },
                function () {
                  $state.go("receipt/detail");
                }
              );
          }
        ]
      })
      .state("receipt/receipt-file-new", {
        parent: "receipt/detail",
        url: "/{id}/addReceiptFile",
        data: {
          authorities: ["ROLE_USER"]
        },
        onEnter: [
          "$stateParams",
          "$state",
          "$uibModal",
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl:
                  "app/entities/receipt-file/receipt-file-dialog.html",
                controller: "ReceiptFileDialogController",
                controllerAs: "vm",
                backdrop: "static",
                size: "lg",
                resolve: {
                  entity: function () {
                    return {
                      fileName: null,
                      fileContentType: null,
                      receiptId: $stateParams.id,
                      id: null
                    };
                  }
                }
              })
              .result.then(
                function () {
                  $state.go("receipt/detail", null, { reload: true });
                },
                function () {
                  $state.go("receipt/detail");
                }
              );
          }
        ]
      })
      .state("receipt/receipt-file-delete", {
        parent: "receipt/detail",
        url: "/{rfId}/deleteReceiptFile",
        data: {
          authorities: ["ROLE_USER"]
        },
        onEnter: [
          "$stateParams",
          "$state",
          "$uibModal",
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl:
                  "app/entities/receipt-file/receipt-file-delete-dialog.html",
                controller: "ReceiptFileDeleteController",
                controllerAs: "vm",
                size: "md",
                resolve: {
                  entity: [
                    "ReceiptFile",
                    function (ReceiptFile) {
                      return ReceiptFile.get({ id: $stateParams.rfId })
                        .$promise;
                    }
                  ]
                }
              })
              .result.then(
                function () {
                  $state.go("receipt/detail", null, { reload: true });
                },
                function () {
                  $state.go("receipt/detail");
                }
              );
          }
        ]
      })
      .state("receipt/purchased-product-new", {
        parent: "receipt/detail",
        url: "/{id}/addPurchasedProduct/{addClaim}",
        data: {
          authorities: ["ROLE_USER"]
        },
        onEnter: [
          "$stateParams",
          "$state",
          "$uibModal",
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl:
                  "app/entities/purchased-product/purchased-product-dialog.html",
                controller: "PurchasedProductDialogController",
                controllerAs: "vm",
                backdrop: "static",
                size: "lg",
                resolve: {
                  translatePartialLoader: [
                    "$translate",
                    "$translatePartialLoader",
                    function ($translate, $translatePartialLoader) {
                      $translatePartialLoader.addPart("purchasedProduct");
                      $translatePartialLoader.addPart("product");
                      $translatePartialLoader.addPart("global");
                      return $translate.refresh();
                    }
                  ],
                  entity: function () {
                    return {
                      serialNumber: null,
                      giftReceiverEmail: null,
                      giftReceiverDescription: null,
                      receiptId: $stateParams.id
                    };
                  }
                }
              })
              .result.then(
                function (entity) {
                  if ($stateParams.addClaim) {
                    $state.go(
                      "purchased-product/warranty-claim-new",
                      { id: entity.id },
                      { reload: false }
                    );
                  } else {
                    $state.go("receipt/detail", null, { reload: true });
                  }
                },
                function () {
                  $state.go("receipt/detail");
                }
              );
          }
        ]
      })
      /* Modifica dall'elenco attualmente disabilitata
		.state('receipt.purchased-product-edit', {
		    parent: 'receipt.detail',
		    url: '/{ppId}/editPurchasedProduct',
		    data: {
		        authorities: ['ROLE_USER']
		    },
		    onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
		        $uibModal.open({
		            templateUrl: 'app/entities/purchased-product/purchased-product-dialog.html',
		            controller: 'PurchasedProductDialogController',
		            controllerAs: 'vm',
		            backdrop: 'static',
		            size: 'lg',
		            resolve: {
		                entity: ['PurchasedProduct', function(PurchasedProduct) {
		                    return PurchasedProduct.get({id : $stateParams.ppId}).$promise;
		                }]
		            }
		        }).result.then(function() {
		            $state.go('receipt.detail', null, { reload: true });
		        }, function() {
		            $state.go('receipt.detail');
		        });
		    }]
		})
		*/
      .state("receipt/purchased-product-delete", {
        parent: "receipt/detail",
        url: "/{ppId}/deletePurchasedProduct",
        data: {
          authorities: ["ROLE_USER"]
        },
        onEnter: [
          "$stateParams",
          "$state",
          "$uibModal",
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl:
                  "app/entities/purchased-product/purchased-product-delete-dialog.html",
                controller: "PurchasedProductDeleteController",
                controllerAs: "vm",
                size: "md",
                resolve: {
                  entity: [
                    "PurchasedProduct",
                    function (PurchasedProduct) {
                      return PurchasedProduct.get({ id: $stateParams.ppId })
                        .$promise;
                    }
                  ]
                }
              })
              .result.then(
                function () {
                  $state.go("receipt/detail", null, { reload: true });
                },
                function () {
                  $state.go("receipt/detail");
                }
              );
          }
        ]
      })
      .state("receipt/purchased-product-reso", {
        parent: "receipt/detail",
        url: "/{ppId}/resoPurchasedProduct",
        params: { listSelected: null },
        data: {
          authorities: ["ROLE_USER"]
        },
        onEnter: [
          "$stateParams",
          "$state",
          "$uibModal",
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl:
                  "app/entities/purchased-product/purchased-product-reso-dialog.html",
                controller: "PurchasedProductResoController",
                controllerAs: "vm",
                size: "md",
                resolve: {
                  entity: [
                    "PurchasedProduct",
                    function (PurchasedProduct) {
                      return PurchasedProduct.get({ id: $stateParams.ppId })
                        .$promise;
                    }
                  ]
                }
              })
              .result.then(
                function () {
                  $state.go("receipt/detail", null, { reload: true });
                },
                function () {
                  $state.go("receipt/detail");
                }
              );
          }
        ]
      })
      .state("receipt/new_page", {
        parent: "entity",
        url: "/new",
        data: {
          authorities: ["ROLE_USER"]
        },
        views: {
          "sidebar@": {
            templateUrl: "app/entities/receipt/sidebar-receipts.html"
          },
          "content@": {
            templateUrl: "app/entities/receipt/receipt-new-page.html",
            controller: "ReceiptDialogControllerPage",
            controllerAs: "vm"
          }
        },
        resolve: {
          entity: function () {
            return {
              receiptNumber: null,
              counterCode: null,
              description: null,
              creationDate: null,
              buyingDate: null,
              paymentType: null,
              source: "DATAENTRY",
              id: null
            };
          },
          translatePartialLoader: [
            "$translate",
            "$translatePartialLoader",
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart("receipt");
              $translatePartialLoader.addPart("paymentType");
              $translatePartialLoader.addPart("dataSource");
              $translatePartialLoader.addPart("warranty");
              $translatePartialLoader.addPart("global");
              $translatePartialLoader.addPart("receiptType");
              $translatePartialLoader.addPart("address");
              return $translate.refresh();
            }
          ]
        }
      })
      .state("receipt/gift-delete", {
        parent: "receipt/detail",
        url: "/{giftId}/deleteGift",
        data: {
          authorities: ["ROLE_USER"]
        },
        onEnter: [
          "$stateParams",
          "$state",
          "$uibModal",
          function ($stateParams, $state, $uibModal) {
            $uibModal
              .open({
                templateUrl: "app/entities/gift/gift-delete-dialog.html",
                controller: "GiftDeleteController",
                controllerAs: "vm",
                size: "md",
                resolve: {
                  translatePartialLoader: [
                    "$translate",
                    "$translatePartialLoader",
                    function ($translate, $translatePartialLoader) {
                      $translatePartialLoader.addPart("purchasedProduct");
                      return $translate.refresh();
                    }
                  ],
                  entity: [
                    "Gift",
                    function (Gift) {
                      return Gift.get({ id: $stateParams.giftId }).$promise;
                    }
                  ]
                }
              })
              .result.then(
                function () {
                  //After deletion go to all receipts, since most of the times the receipt that contained the gift is
                  //no longer visible to the gift receiver
                  $state.go("receipt", null, { reload: true });
                },
                function () {
                  //If operation is canceled stay where you are
                  $state.go("receipt/detail");
                }
              );
          }
        ]
      }).state("receipt/fast_track", {
        parent: "entity",
        url: "/fast/:type",
        data: {
          authorities: ["ROLE_USER"]
        },
        views: {
          "sidebar@": {
            templateUrl: "app/entities/receipt/sidebar-receipts.html"
          },
          "content@": {
            templateUrl: "app/entities/receipt/receipt-fast-track.html",
            controller: "ReceiptFastTrackController",
            controllerAs: "vm"
          }
        },
        resolve: {
          entity: function () {
            return {
              receiptNumber: null,
              counterCode: null,
              description: null,
              creationDate: null,
              buyingDate: null,
              paymentType: null,
              source: "DATAENTRY",
              id: null
            };
          },
          translatePartialLoader: [
            "$translate",
            "$translatePartialLoader",
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart("receipt");
              $translatePartialLoader.addPart("paymentType");
              $translatePartialLoader.addPart("dataSource");
              $translatePartialLoader.addPart("warranty");
              $translatePartialLoader.addPart("global");
              $translatePartialLoader.addPart("receiptType");
              $translatePartialLoader.addPart("receiptUse");
              $translatePartialLoader.addPart("address");
              $translatePartialLoader.addPart("timeUnit");
              $translatePartialLoader.addPart("bar-code-reader");
              return $translate.refresh();
            }
          ]
        }
      }).state("receipt/customer", {
        parent: "entity",
        url: "/receipt/customer/{customerId}",
        ncyBreadcrumb: {
          label: "{{'garanteasyApp.receipt.home.title' | translate}}"
        },
        data: {
          authorities: ["ROLE_USER"],
          pageTitle: "garanteasyApp.receipt.home.title"
        },
        views: {
          "sidebar@": {
            templateUrl: "app/entities/receipt/sidebar-receipts.html"
          },
          "content@": {
            templateUrl: "app/entities/receipt/receipts.html",
            controller: "ReceiptController",
            controllerAs: "vm"
          }
        },
        params: {
          page: {
            value: "0",
            squash: true
          },
          sort: {
            value: "id,desc",
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: [
            "$stateParams",
            "PaginationUtil",
            function ($stateParams, PaginationUtil) {
              return {
                page: PaginationUtil.parsePage($stateParams.page),
                sort: $stateParams.sort,
                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                ascending: PaginationUtil.parseAscending($stateParams.sort),
                search: $stateParams.search
              };
            }
          ],
          translatePartialLoader: [
            "$translate",
            "$translatePartialLoader",
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart("receipt");
              $translatePartialLoader.addPart("paymentType");
              $translatePartialLoader.addPart("dataSource");
              $translatePartialLoader.addPart("warranty");
              $translatePartialLoader.addPart("global");
              $translatePartialLoader.addPart("receiptType");
              $translatePartialLoader.addPart("receiptUse");
              return $translate.refresh();
            }
          ]
        }
      });
  }
})();
