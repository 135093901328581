(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('CardDialogController', CardDialogController);

    CardDialogController.$inject = ['$http', '$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Card', 'CardType', 'Contact', 'ContactLookup', 'PhoneNumberLookup', 'PhoneNumber', 'Principal', '$state', '$rootScope', 'CardCustomer'];

    function CardDialogController($http, $timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Card, CardType, Contact, ContactLookup, PhoneNumberLookup, PhoneNumber, Principal, $state, $rootScope, CardCustomer) {
        var vm = this;

        vm.card = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.cardTypes = [];
        vm.cardTypes = CardType.query();
        vm.contacts = Contact.query();
        vm.phoneNumbers = PhoneNumber.query();
        vm.isOwner = false;
        vm.isAdmin = false;

        checkAccess();

        function checkAccess() {
            Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                if (result) {
                    vm.isAdmin = true;
                }
            });
            var account = Principal.identity();
            if (vm.card && vm.card.contact && vm.card.contact.user && account.$$state && account.$$state.value.id)
                if (vm.card.contact.user.id == account.$$state.value.id) {
                    vm.isOwner = true;
                }
        }

        vm.disableEdit = function () {
            if (vm.card.flowMerchant && vm.isAdmin) {
                return false;
            }
            if (!vm.card.flowMerchant && vm.isOwner) {
                return false;
            }
            if (vm.card.id) {
                return true;
            }
        }

        $q.all([vm.card.$promise, vm.contacts.$promise]).then(function () {
            if (!vm.card.contactId) {
                return $q.reject();
            }
            return Contact.get({ id: vm.card.contactId }).$promise;
        }).then(function (contact) {
            vm.contacts.push(contact);
        });

        $q.all([vm.card.$promise, vm.phoneNumbers.$promise]).then(function () {
            if (!vm.card.phoneNumberId) {
                return $q.reject();
            }
            return PhoneNumber.get({ id: vm.card.phoneNumberId }).$promise;
        }).then(function (phoneNumber) {
            vm.phoneNumbers.push(phoneNumber);
        });

        $scope.resetContact = function () {
            vm.card.contact = null;
        }

        $scope.resetPhoneNumber = function () {
            vm.card.phoneNumber = null;
        }

        $scope.loadContacts = function (searchQuery) {
            if (searchQuery && searchQuery.trim().length > 1) {
                ContactLookup.query({ query: searchQuery }, function (result) {
                    $scope.contacts = result;
                }, function (response) {
                    if (response.status === 404) {
                        $scope.contacts = Contact.query({ isActive: true }, onSuccess, onError);
                    }
                });
            } else {
                $scope.contacts = Contact.query({ isActive: true }, onSuccess, onError);
            }
        };

        function onSuccess(data, headers) {
            if ($scope.contacts) {
                $scope.contacts.forEach(function (item) {
                    if (item.primaryContact && !vm.card.contact)
                        vm.card.contact = item;
                });
            }
        }

        function onError(data, headers) {

        }

        $scope.loadPhoneNumbers = function (searchQuery) {
            if (searchQuery && searchQuery.trim().length > 1) {
                PhoneNumberLookup.query({ query: searchQuery }, function (result) {
                    $scope.phoneNumbers = result;
                }, function (response) {
                    if (response.status === 404) {
                        $scope.phoneNumbers = PhoneNumber.query({ isActive: true });
                    }
                });
            } else {
                $scope.phoneNumbers = PhoneNumber.query({ isActive: true });
            }
        };

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {

            vm.isSaving = true;
            if (vm.card.id !== null) {
                Card.update(vm.card, onSaveSuccess, onSaveError);
            } else {
                Card.save(vm.card, onSaveSuccess, onSaveError);
            }
        }
        function onSaveSuccess(result) {
            $scope.$emit('garanteasyApp:cardUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }


        function onSaveError(error) {
            vm.isSaving = false;
            if (error && error.data && error.data.errCode && 'ERR_EXIST_CARD' == error.data.errCode) {
                vm.resp = error.data.errCode;
                $state.go('settings/card-requestUse', { 'dataCard': error.data, 'card': vm.card });
                // $uibModalInstance.close();
            }

        }

        vm.closeWindow = function () {
            $uibModalInstance.close();
        }

        vm.datePickerOpenStatus.activationDate = false;
        vm.datePickerOpenStatus.expirationDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
