'use strict';
(function () {
  angular
    .module('garanteasyApp')
    .controller('ShopDeleteController', ShopDeleteController);

  ShopDeleteController.$inject = ['$uibModalInstance', '$stateParams', 'ShopService'];

  function ShopDeleteController($uibModalInstance, $stateParams, ShopService) {
    var vm = this;

    vm.shopId = $stateParams.id;
    vm.clear = clear;
    vm.confirmDelete = confirmDelete;
    console.log('shopId', vm.shopId);
    function clear() {
      $uibModalInstance.dismiss('cancel');
    }

    function confirmDelete() {
      ShopService.delete({ id: vm.shopId },
        function () {
          $uibModalInstance.close(true);
        });
    }
  }
})();
