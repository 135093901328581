(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('settings', {
                parent: 'account',
                url: '/settings',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'global.menu.account.settings'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/settings/settings.html',
                        controller: 'SettingsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settings');
                        $translatePartialLoader.addPart('customer');
                        $translatePartialLoader.addPart('address');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('contact');
                        $translatePartialLoader.addPart('phoneNumber');
                        $translatePartialLoader.addPart('card');
                        return $translate.refresh();
                    }]
                }
            })
            .state('settings-customer-new', {
                parent: 'settings',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/customer/customer-dialog.html',
                        controller: 'CustomerDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    legalName: null,
                                    birthDate: null,
                                    vatid: null,
                                    business: false,
                                    gender: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('settings', null, { reload: true });
                    }, function () {
                        $state.go('settings');
                    });
                }]
            })
            .state('settings-customer-edit', {
                parent: 'settings',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/customer/customer-dialog.html',
                        controller: 'CustomerDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Customer', function (Customer) {
                                return Customer.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('settings', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('settings-customer-delete', {
                parent: 'settings',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/customer/customer-delete-dialog.html',
                        controller: 'CustomerDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Customer', function (Customer) {
                                return Customer.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('settings', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('settings-contact-new', {
                parent: 'settings',
                url: '/newContact',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/contact/contact-dialog.html',
                        controller: 'ContactDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    channel: null,
                                    primaryContact: false,
                                    activated: false,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('settings', null, { reload: true });
                    }, function () {
                        $state.go('settings');
                    });
                }]
            })
            .state('settings-contact-edit', {
                parent: 'settings',
                url: '/{id}/editContact',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/contact/contact-dialog.html',
                        controller: 'ContactDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Contact', function (Contact) {
                                return Contact.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('settings', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('settings-contact-delete', {
                parent: 'settings',
                url: '/{id}/deleteContact',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/contact/contact-delete-dialog.html',
                        controller: 'ContactDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Contact', function (Contact) {
                                return Contact.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('settings', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('settings-card-new', {
                parent: 'settings',
                url: '/newCard',
                data: {

                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/card/card-dialog.html',
                        controller: 'CardDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    number: null,
                                    activationDate: null,
                                    expirationDate: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('settings', $stateParams, {
                            reload: 'settings', inherit: false, notify: true
                        });
                    }, function () {
                        $state.go('settings');
                    });
                }]
            })
            .state('settings-card-edit', {
                parent: 'settings',
                url: '/{id}/editCard',
                data: {

                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/card/card-dialog.html',
                        controller: 'CardDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Card', function (Card) {
                                return Card.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('settings', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('settings-card-delete', {
                parent: 'settings',
                url: '/{id}/deleteCard',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/card/card-delete-dialog.html',
                        controller: 'CardDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Card', function (Card) {
                                return Card.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('settings', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('settings-phone-number-new', {
                parent: 'settings',
                url: '/newPhoneNumber',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/phone-number/phone-number-dialog.html',
                        controller: 'PhoneNumberDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    phoneNumber: null,
                                    activated: false,
                                    activationKey: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('settings', null, { reload: true });
                    }, function () {
                        $state.go('settings');
                    });
                }]
            })
            .state('settings-phone-number-edit', {
                parent: 'settings',
                url: '/{id}/editPhoneNumber',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/phone-number/phone-number-dialog.html',
                        controller: 'PhoneNumberDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['PhoneNumber', function (PhoneNumber) {
                                return PhoneNumber.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('settings', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('settings-phone-number-delete', {
                parent: 'settings',
                url: '/{id}/deletePhoneNumber',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/phone-number/phone-number-delete-dialog.html',
                        controller: 'PhoneNumberDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PhoneNumber', function (PhoneNumber) {
                                return PhoneNumber.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('settings', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            }).state('settings/card-requestUse', {
                parent: 'settings',
                url: '/requestUseCard',
                params: { dataCard: null, card: null },
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/card/card-request-use-card-dialog.html',
                        controller: 'CardRequestUseController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    legalName: null,
                                    birthDate: null,
                                    vatid: null,
                                    business: false,
                                    gender: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('settings', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });

    }
})();
