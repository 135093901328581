(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('PurchasedProductDialogController', PurchasedProductDialogController);

    PurchasedProductDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Principal', 'PurchasedProduct', 'Receipt', 'Brand', 'BrandLookup', 'Product', 'ProductLookup', 'PurchasedProductWarranty', 'WarrantyClaim', 'IceProductLookup', 'IcecatSimpleProduct'];

    function PurchasedProductDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Principal, PurchasedProduct, Receipt, Brand, BrandLookup, Product, ProductLookup, PurchasedProductWarranty, WarrantyClaim, IceProductLookup, IcecatSimpleProduct) {
        var vm = this;

        vm.purchasedProduct = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.brands = Brand.query();
        vm.products = Product.query({ filter: 'purchasedproduct-is-null' });

        $q.all([vm.purchasedProduct.$promise, vm.products.$promise]).then(function () {
            if (!vm.purchasedProduct.productId) {
                return $q.reject();
            }
            return Product.get({ id: vm.purchasedProduct.productId }).$promise;
        }).then(function (product) {
            vm.products.push(product);
        });

        vm.purchasedproductwarranties = PurchasedProductWarranty.query();
        vm.warrantyclaims = WarrantyClaim.query();
        vm.isValidInput = isValidInput;
        vm.hasProduct = null;
        vm.validProduct = null;
        vm.hasBrand = null;
        vm.validBrand = null;

        vm.isOwner = null;
        vm.isAdmin = null;
        vm.isGiftReceiver = null;

        vm.receiptIsOfficial = vm.purchasedProduct.receipt && (vm.purchasedProduct.receipt.source == 'MERCHANT' || vm.purchasedProduct.receipt.source == 'CLAIM' || vm.purchasedProduct.receipt.source == 'CLAIM_RESOLVED');

        vm.checkAccess = checkAccess;
        checkAccess();

        function checkAccess() {
            Principal.identity().then(function (account) {
                if (!Principal.isAuthenticated()) {
                    $state.go("home");
                    return;
                }
                vm.isOwner = !vm.purchasedProduct.id || vm.purchasedProduct.receipt.customer.user.id == account.id;
                vm.isGiftReceiver = vm.purchasedProduct.giftReceiverUserId == account.id;
                Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                    if (result) {
                        vm.isAdmin = true;
                    } else {
                        vm.isAdmin = false;
                    }
                });
            });
        }

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.resetProduct = function () {
            vm.purchasedProduct.product = null;
        }

        $scope.resetBrand = function () {
            if (vm.purchasedProduct.product !== null) {
                vm.purchasedProduct.product.brand = null;
            }
        }

        $scope.loadProducts = function (searchQuery) {
            if (searchQuery && searchQuery.trim().length > 0) {
                ProductLookup.query({ query: searchQuery }, function (result) {
                    $scope.products = result;
                }, function (response) {
                    if (response.status === 404) {
                        $scope.products = Product.query({ isActive: true });
                    }
                });
            } else {
                $scope.products = Product.query({ isActive: true });
            }
        };

        /*        $scope.loadIceProducts = function(searchQuery) {
                    if (searchQuery && searchQuery.trim().length > 0){
                        IceProductLookup.query({query: searchQuery}, function(result) {
                            $scope.products = result;
                        }, function(response) {
                            if(response.status === 404) {
                                $scope.products = Product.query({isActive: true});
                            }
                        });
                    } else {
                        $scope.products = Product.query({isActive: true});
                    }
                };*/

        $scope.loadBrands = function (searchQuery) {
            if (searchQuery) {
                BrandLookup.query({ query: searchQuery }, function (result) {
                    $scope.brands = result;
                }, function (response) {
                    if (response.status === 404) {
                        $scope.brands = Brand.query({ isActive: true });
                    }
                });
            } else {
                $scope.brands = Brand.query({ isActive: true });
            }
        };

        function save() {
            vm.isSaving = true;
            var pp = angular.copy(vm.purchasedProduct);
            if (pp.product.idxId) {
                var allProductId = pp.product.idxId;
                pp.product = {};
                pp.allProductId = allProductId;
            }

            if (vm.purchasedProduct.id !== null) {
                PurchasedProduct.update(pp, onSaveSuccess, onSaveError);
            } else {
                PurchasedProduct.save(pp, onSaveSuccess, onSaveError);
            }
        }

        // Constraints beyond the ones in the form
        function isValidInput() {
            vm.hasProduct = vm.purchasedProduct.product != null;
            vm.validProduct = vm.hasProduct && vm.purchasedProduct.product.name != null && vm.purchasedProduct.product.name.trim().length > 0;
            vm.hasBrand = vm.hasProduct && vm.purchasedProduct.product.brand != null;
            /*vm.validBrand = vm.hasBrand && vm.purchasedProduct.product.brand.name != null && vm.purchasedProduct.product.brand.name.trim().length > 0;*/
            vm.validBrand = vm.hasBrand && vm.purchasedProduct.product.brand != null;
            return vm.validProduct && vm.validBrand;
        }

        function onSaveSuccess(result) {
            $scope.$emit('garanteasyApp:purchasedProductUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.deliveryDate = false;
        vm.datePickerOpenStatus.giftNotificationDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
