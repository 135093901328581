(function () {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('Receipt', Receipt);

    Receipt.$inject = ['$resource', 'DateUtils', '$filter'];

    function Receipt($resource, DateUtils, $filter) {
        var resourceUrl = 'api/receipts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.creationDate = DateUtils.convertLocalDateFromServer(data.creationDate);
                        data.buyingDate = DateUtils.convertLocalDateFromServer(data.buyingDate);
			if (!data.purchasedProducts) {
                          data.purchasedProducts = [];
                        }
                    }
                    return data;
                }
            },
            'getReceipts': {
                method: 'GET',
                isArray: true,
                url: 'api/merchant-dashboard/receipts',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getReceiptsByMerchant': {
                method: 'GET',
                isArray: true,
                url: 'api/merchant-dashboard/receipts/:merchant_id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getReceiptsByMerchantAndShop': {
                method: 'GET',
                isArray: true,
                url: 'api/merchant-dashboard/receipts/:merchant_id/:shop_id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'searchReceipts': {
                method: 'GET',
                isArray: true,
                url: 'api/merchant-dashboard/receipts/search',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getValid': {
                method: 'GET',
                url: 'api/receipts/validReceipts',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    dataToSend.buyingDate = DateUtils.convertLocalDateToServer(dataToSend.buyingDate);
                    dataToSend.creationDate = DateUtils.convertLocalDateToServer(dataToSend.creationDate);
                    return angular.toJson(dataToSend);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    dataToSend.buyingDate = DateUtils.convertLocalDateToServer(dataToSend.buyingDate);
                    dataToSend.creationDate = DateUtils.convertLocalDateToServer(dataToSend.creationDate);
                    return angular.toJson(dataToSend);
                }
            },
            'getMerchants': {
                method: 'GET',
                url: 'api/merchant-dashboard/merchants',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'claim': {
                method: 'POST',
                url: 'api/receipts/claim',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    if (dataToSend.receiptIdentifier) {
                        //IT100003007000012562120161205
                        //01234567890123456789012345678
                        //          1111111111222222222
                        dataToSend.shopCode = dataToSend.receiptIdentifier.substr(4, 5);
                        dataToSend.counterCode = dataToSend.receiptIdentifier.substr(9, 2);
                        var pattern = /(\d{4})(\d{2})(\d{2})/;
                        dataToSend.buyingDate = new Date(dataToSend.receiptIdentifier.substr(21, 8).replace(pattern, '$1-$2-$3'));
                    } else {
                        dataToSend.receiptIdentifier = "IT10" + dataToSend.shopCode + dataToSend.counterCode + dataToSend.receiptNumber + $filter('date')(dataToSend.buyingDate, 'yyyyMMdd');
                    }
                    dataToSend.receiptNumber = dataToSend.receiptIdentifier;
                    dataToSend.buyingDate = DateUtils.convertLocalDateToServer(dataToSend.buyingDate);
                    return angular.toJson(dataToSend);
                }
            },
            'getCustomerReceipts': {
              method: 'GET',
              isArray: true,
              url: 'api/merchant-dashboard/customer_receipts/:customer_id/?',
              transformResponse: function (data) {
                  if (data) {
                      data = angular.fromJson(data);
                  }
                  return data;
              }
            }
        });
    }
})();
