(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('CustomerDetailController', CustomerDetailController);

    CustomerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Principal', 'Customer', 'User', 'Contact', 'Card', 'PersistentAuditEvent', 'WarrantyStatus', 'AlertService', 'DataUtils'];

    function CustomerDetailController($scope, $rootScope, $stateParams, previousState, entity, Principal, Customer, User, Contact, Card, PersistentAuditEvent, WarrantyStatus, AlertService, DataUtils) {
        var vm = this;

        vm.customer = entity;
        vm.purchasedProducts = [];
        vm.contacts = [];
        vm.userId = entity.user.id;
        vm.cards = [];
        vm.persistenceAuditEvents = [];
        vm.isEdit = false;
        vm.receiptCount = 0;
        vm.lastReceipt = {};
        //WarrantyStatus.getWarrantyStatusCustomer({'customer_id' : vm.customer.id}, onSuccessW, onErrorW);
        vm.showCustomerName = function (customer) {
            return DataUtils.showCustomerName(customer);
        };
        function onSuccessW(data, headers) {
            vm.warrantyStatus = data;
            vm.totalNumExpired = data.numExpired;
            vm.totalNumExpiring = data.numExpiring;
            vm.totalNumValid = data.numValid;
        }
        function onErrorW(error) {
            AlertService.error(error.data.message);
        }

        $scope.stateData.vm = vm;
        vm.name = 'CUSTOMER-DETAIL';

        vm.buy = function (value) {
            if (!value) {
                return 'Privato';
            }
            else { return 'Business'; }
        }



        function loadContacts() {
            Contact.getUserContacts({ userId: vm.userId }, onSuccessContact, onError);
        }

        function onSuccessContact(data, headers) {
            vm.contacts = data;
            for (var i = 0; i < vm.contacts; i++) {
                if (vm.contacts[i].primaryContact) {
                    vm.primaryContact = vm.contacts[i];
                }
            }
        }

        function loadCards() {
            Card.getCards4Customer({ customerId: entity.id }, function (result) {
                vm.cards = result;
            });
        }

        function loadPersistenceAuditEvents() {
            PersistentAuditEvent.getPersistenceAuditEvents({ userId: vm.userId }, function (result) {
                vm.persistenceAuditEvents = result;
            });
        }

        loadContacts();
        loadCards();
        loadPersistenceAuditEvents();
        countReceiptAndFindLast();

        Customer.getCustomerDetail({ 'customer_id': entity.id }, onSuccess, onError);

        function onSuccess(data, headers) {
            vm.customer = data;
            vm.purchasedProducts = vm.customer.garanteasyProducts;
            for (var i = 0; i < vm.purchasedProducts.length; i++) {
                vm.totalNumExpired += vm.purchasedProducts[i].totalWarranties.numExpired;
                vm.totalNumValid += vm.purchasedProducts[i].totalWarranties.numValid;
                vm.totalNumExpiring += vm.purchasedProducts[i].totalWarranties.numExpiring;
            }
        }

        function onError(error) {
            console.log('Error in load customer!');
        }

        function countReceiptAndFindLast() {
          var receiptIds = [];
          var lastReceipt;
          if (Array.isArray(vm.customer.garanteasyProducts)) {
            vm.customer.garanteasyProducts.forEach(function(item) {
              if (item.receipt && item.receipt.id) {
                if (receiptIds.indexOf(item.receipt.id) === -1) {
                  receiptIds.push(item.receipt.id);
                }
                if (!lastReceipt) {
                  lastReceipt = item.receipt;
                } else {
                  if (item.receipt.creationDate > lastReceipt.creationDate) {
                    lastReceipt = item.receipt;
                  }
                }
              }
            });
          }
          vm.receiptCount = receiptIds.length;
          vm.lastReceipt = lastReceipt;
        }

        if ($stateParams.from) {
            $scope.from = $stateParams.from;
        }

        var unsubscribe = $rootScope.$on('garanteasyApp:customerUpdate', function (event, result) {
            vm.customer = result;
        });
        $scope.$on('$destroy', unsubscribe);

        $rootScope.$emit("enableMerchantSelector", false);
        $scope.$on('$destroy', function dismissed() {
              $rootScope.$emit("enableMerchantSelector", true);
        })
    }
})();
