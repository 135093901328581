(function () {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('WarrantyClaim', WarrantyClaim);

    WarrantyClaim.$inject = ['$resource', '$stateParams'];

    function WarrantyClaim($resource, $stateParams) {
        var resourceUrl = '/api/merchant-dashboard/support/garanteasy/';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                url: 'api/merchant-dashboard/support/garanteasy/',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    return angular.toJson(dataToSend);
                }
            },
            'send': {
                method: 'PUT',
                url: 'api/warranty-claims/:id/send',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    return angular.toJson(dataToSend);
                }
            }
        });
    }
})();
