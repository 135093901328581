angular
    .module('garanteasyApp')
    .component('barCodeReader', {
        templateUrl: 'app/components/bar-code-reader/bar-code-reader.component.html',
        controller: BarCodeReaderComponentController,
        controllerAs: 'vm',
        bindings: {
            myModel: '=',
            btnClass: '<'
        }
    });

BarCodeReaderComponentController.$inject = ['$uibModal'];

function BarCodeReaderComponentController($uibModal) {
    var vm = this;
    vm.onReadBarCodeFile = function () {
        $uibModal
            .open({
                templateUrl: "app/components/bar-code-reader/bar-code-file-reader.html",
                controller: "BarCodeFileReaderController",
                controllerAs: "vm",
                size: "md"
            })
            .result.then(
                function () {
                    vm.myModel = "OK";
                },
                function () {
                    vm.myModel = "CANCEL";
                }
            );
    };
    vm.onReadBarCodeCamera = function () {
        $uibModal
            .open({
                templateUrl: "app/components/bar-code-reader/bar-code-camera-reader.html",
                controller: "BarCodeCameraReaderController",
                controllerAs: "vm",
                size: "md"
            })
            .result.then(
                function () {
                    vm.myModel = "OK";
                },
                function () {
                    vm.myModel = "CANCEL";
                }
            );
    };
}
