(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('SidebarController', SidebarController);

  SidebarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'cmsViewMode'];

  function SidebarController($state, Auth, Principal, ProfileService, LoginService, cmsViewMode) {
    var vm = this;
    vm.showSuggestions = false;
    vm.name = 'SidebarController';
    vm.isNavbarCollapsed = true;
    vm.isAuthenticated = Principal.isAuthenticated;
    vm.triggerObj = {};
    vm.triggerObj.trigger = false;
    vm.cmsViewMode = cmsViewMode;
    ProfileService.getProfileInfo().then(function (response) {
      vm.inProduction = response && response.inProduction;
      vm.swaggerEnabled = response && response.swaggerEnabled;
    });

    vm.login = login;
    vm.logout = logout;
    vm.toggleNavbar = toggleNavbar;
    vm.collapseNavbar = collapseNavbar;
    vm.$state = $state;
    vm.isSupermedia = false;
    vm.showSidebarGrey = showSidebarGrey;
    Principal.countAuthorities().then(function (c) {
      if (c === 1) {
        Principal.hasAnyAuthority(['MERCHANT_ROSSETTI', 'MERCHANT_SUPERMEDIA']).then(function (result) {
          vm.isSupermedia = result;
        });
      }
    });

    function showSidebarGrey() {
      if (vm.triggerObj.trigger == true) {
        vm.triggerObj.trigger = false;
      }
      else {
        vm.triggerObj.trigger = true;
      }
    }

    function login() {
      collapseNavbar();
      LoginService.open();
    }

    function logout() {
      console.log('ciaso');
      collapseNavbar();
      Auth.logout();
      $state.go('home');
    }

    function toggleNavbar() {
      vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
    }

    function collapseNavbar() {
      vm.isNavbarCollapsed = true;
    }
  }
})();
