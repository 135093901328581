(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('ReceiptFastTrackController', ReceiptFastTrackController);

  ReceiptFastTrackController.$inject = ['$timeout', '$scope', '$q', 'entity', 'Principal', 'Receipt', 'Customer', 'Contact', 'ShopService', 'Merchant', 'MerchantLookup', 'Card', '$state', 'DataUtils', 'ReceiptFile', 'AlertService', 'ProductLookup', 'BrandLookup', 'AuthServerProvider', '$log', /* 'Warranty', */ '$rootScope', '$translate', 'bsScreenSize', 'CustomerSearch', 'uibDateParser', 'ProductSearch', 'PurchasedProductWarranty', 'Warranty', 'APPKEY', '$stateParams', 'PrivacyPolicy'];

  function ReceiptFastTrackController($timeout, $scope, $q, entity, Principal, Receipt, Customer, Contact, ShopService, Merchant, MerchantLookup, Card, $state, DataUtils, ReceiptFile, AlertService, ProductLookup, BrandLookup, AuthServerProvider, $log, /* Warranty, */ $rootScope, $translate, bsScreenSize, CustomerSearch, uibDateParser, ProductSearch, PurchasedProductWarranty, Warranty, APPKEY, $stateParams, PrivacyPolicy) {
    var vm = this;
    vm.slides = ['customer', 'receipt', 'receiptImage', 'productType', 'productFestina'];
    vm.activeSlide = 1;
    vm.ssize = bsScreenSize.state;
    vm.receipt = entity;
    vm.receipt.purchasedProducts = [];
    vm.receipt.receiptType = "RECEIPT";
    vm.clear = clear;
    vm.datePickerOpenStatus = {};
    vm.openCalendar = openCalendar;
    vm.save = save;
    vm.images = [];
    vm.documents = [];
    vm.customer = {};
    vm.merchantPrivacyPolicies = [];
    vm.isValidForm = false;
    vm.typeCodes = ["GTIN", "EAN", "UPC"];
    vm.isDisabled = isDisabled;
    vm.contPurchasedProducts = 0;
    vm.receipt.purchasedProducts = [];
    getAccount();
    getAccountMerchants();
    vm.disableAddProduct = disableAddProduct;
    vm.addImageToProduct = addImageToProduct;
    vm.getImageProductUrl = getImageProductUrl;
    vm.addPurchasedProduct = addPurchasedProduct;
    vm.onUpdateConventionalWarranty = onUpdateConventionalWarranty;
    vm.checkValidMail = checkValidMail;
    vm.noFestina = noFestina;
    vm.ccc13 = ccc13;
    vm.saveStepReceiptFile = saveStepReceiptFile;
    vm.isValidCode = false;
    vm.isFestina = false;
    // vm.sysdate = new Date();
    vm.receipt.buyingDate = new Date();
    vm.receipt.purchasedProduct = {};
    vm.receiptFiles = [];
    vm.datePickerOptions = {
      maxDate: new Date(),
      initDate: uibDateParser.parse(new Date())
    };

    vm.isNewCustomer = false;
    vm.isNewProduct = false;
    vm.isPlus = false;
    vm.isBasic = false;

    switch ($stateParams.type) {
      case 'festina':
        vm.isFestina = true;
        break;
      case 'plus':
        vm.isPlus = true;
        break;
      default:
        vm.isBasic = true;
        break
    }

    function getAccountMerchants() {
      Receipt.getMerchants({},
        function (result) {
          vm.accountMerchants = result;
          if (vm.accountMerchants && vm.accountMerchants.length) {
            vm.merchant = vm.accountMerchants[0];
            PrivacyPolicy.regMerchantCode({
              merchant_code: vm.merchant.code
            },
              onPpSuccess,
              onPpError
            );
            vm.getShopByMerchants();
            $translate('garanteasyApp.receipt.descriptionPlaceholder', vm.merchant).then(function (headline) {
              vm.receipt.description = headline;
            }, function (translationId) {
              $log.error("Missing translation for", translationId);
            });

          }
        },
        function (response) {
          if (response.status === 404) {
            vm.accountMerchants = {};
          }
        }
      );
    }

    vm.getShopByMerchants = function () {
      if (angular.isDefined(vm.merchant)) {
        ShopService.getShopByMerchants({
          merchant_id: vm.merchant.id
        },
          function (result) {
            vm.shops = result;
            if (vm.shops && vm.shops.length) {
              vm.receipt.shop = vm.shops[0];
            }
          },
          function (response) {
            if (response.status === 404) {
              vm.shops = {};
            }
          }
        );
      }
    };

    function getAccount() {
      Principal.identity().then(function (account) {
        vm.account = account;
        vm.isAuthenticated = Principal.isAuthenticated;
      });
    }

    vm.isValidPurchasedProduct = function () {
      if (!vm.receipt.purchasedProduct || !vm.receipt.purchasedProduct.product || !vm.receipt.purchasedProduct.product.name || !vm.receipt.purchasedProduct.product.brand) {
        return false;
      }
      return true;
    };

    vm.contacts = Contact.queryActive();


    $timeout(function () {
      angular.element(".form-group:eq(1)>input").focus();
    });
    vm.isValidInput = isValidInput;
    vm.validMerchant = null;
    vm.validAddress = null;

    // Le ricevute inviate da un negozio ufficiale (o in attesa di conferma dopo un claim) sono quasi totalmente readonly
    vm.receipt.source = "MERCHANT";
    vm.isOfficial = true;
    vm.isAdmin = null;
    vm.checkAccess = checkAccess;
    checkAccess();

    function checkAccess() {
      Principal.identity().then(function (account) {
        if (!Principal.isAuthenticated()) {
          $state.go("home");
          return;
        }
        Principal.hasAuthority("ROLE_ADMIN").then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });
      });
    }

    function clear() {
      $uibModalInstance.dismiss("cancel");
    }

    $scope.loadBrands = function (searchQuery) {
      BrandLookup.query({
        query: searchQuery
      },
        function (result) {
          $scope.brands = result;
        }
      );
    };

    $scope.loadActiveCards = function () {
      if (vm.receipt.customer) vm.receipt.customerId = vm.receipt.customer.id;
      if (vm.receipt.customer && vm.receipt.buyingDate)
        vm.cards = Card.queryActive(vm.receipt);
    };

    $scope.loadProducts = function (searchQuery) {
      if (vm.isFestina) {
        var query = '(brand.code:FESTINA)';
        if (searchQuery && searchQuery.trim().length > 0) {
          query += ' AND *' + searchQuery + '*';
        }
        ProductSearch.query({
          query: query
        },
          function (result) {
            $scope.products = result;
          },
          function (response) {
            if (response.status === 404) {
              $scope.products = Product.query({
                isActive: true
              },
                onSuccess,
                onError
              );
            }
          }
        );
      } else {
        if (searchQuery && searchQuery.trim().length > 0) {
          ProductLookup.query({
            query: searchQuery
          },
            function (result) {
              $scope.products = result;
            },
            function (response) {
              if (response.status === 404) {
                $scope.products = Product.query({
                  isActive: true
                },
                  onSuccess,
                  onError
                );
              }
            }
          );
        } else {
          ProductLookup.query({});
        }
      }
    };

    function onSuccess(result) {
      $scope.products = result;
      vm.isSaving = false;
    }

    function onError(result) {
      // $uibModalInstance.close(result);
    }

    // Constraints beyond the ones in the form
    function isValidInput() {
      if (
        vm.receipt.receiptType != null &&
        vm.receipt.description != null &&
        vm.receipt.buyingDate != null &&
        vm.receipt.receiptNumber != null &&
        vm.receipt.shop != null
      ) {
        return true;
      }

    }

    vm.datePickerOpenStatus.buyingDate = false;

    function openCalendar(date) {
      vm.datePickerOpenStatus[date] = true;
    }

    vm.canShow = function (contentType) {
      return (
        contentType != null &&
        contentType.toLowerCase().indexOf("image") == 0 &&
        contentType.toLowerCase().indexOf("tiff") == -1
      );
    };

    vm.uploadFile = function ($file, typeForProduct) {
      vm.receiptFile = {};
      vm.byteSize = DataUtils.byteSize;
      vm.openFile = DataUtils.openFile;
      vm.fileTooLarge = false;
      if ($file && $file.$error === 'pattern') {
        return;
      }
      if ($file) {
        if ($file.size > 5120000) {
          vm.fileTooLarge = true;
          // Reset data in case there was a previous image selected
          vm.receiptFile.receiptId = null;
          vm.receiptFile.fileData = null;
          vm.receiptFile.contentType = null;
          vm.receiptFile.type = null;
        } else {
          vm.fileTooLarge = false;
          DataUtils.toBase64($file, function (base64Data) {
            $scope.$apply(function () {
              vm.receiptFile.fileData = base64Data;
              vm.receiptFile.fileContentType = $file.type;
              vm.receiptFile.fileName = $file.name;
              if (typeForProduct) {
                var extension = $file.name.substr($file.name.lastIndexOf('.'));
                switch (typeForProduct) {
                  case 'warranty':
                    vm.receiptFile.fileName = 'garanzia' + extension;
                    vm.receiptFile.typeForProduct = 'warranty';
                    break;
                  case 'codes':
                    vm.receiptFile.fileName = 'codici' + extension;
                    vm.receiptFile.typeForProduct = 'codes';
                    break;
                  default:
                    vm.receiptFile.typeForProduct = 'default';
                }
                vm.activeSlide = 5;
              } else {
                vm.receiptFiles.push(vm.receiptFile);
                vm.receiptFile = null;
                vm.activeSlide = 3;
              }
            });
          });
        }
      }
    };

    vm.getImageTest = function (data) {
      return "data:image/jpeg;base64," + data;
    };

    var imagesPath = {};
    vm.getImageUrl = function (receiptFile) {
      if (!receiptFile || !receiptFile.id) {
        imagesPath[receiptFile.id] = null;
      } else if (_.isUndefined(imagesPath[receiptFile.id])) {
        imagesPath[receiptFile.id] =
          "api/receipt-files/content/" +
          receiptFile.id +
          "?width=-&height=377&token=" +
          AuthServerProvider.getToken();
      }
      return imagesPath[receiptFile.id];
    };
    if (vm.receipt.receiptFiles != null && vm.receipt.receiptFiles.length > 0) {
      vm.receipt.receiptFiles.forEach(function (item) {
        if (
          item.fileContentType &&
          item.fileContentType.toLowerCase().indexOf("image") == 0
        )
          vm.images.push(item);
        else vm.documents.push(item);
      });
    }

    if (vm.images.length > 0) {
      vm.image = vm.images[0];
    }

    $scope.loadCustomers = function (searchQuery) {
      if (searchQuery && searchQuery.trim().length > 1) {
        CustomerSearch.getCustomerSearch({
          query: searchQuery,
          sort: 'legalName,asc'
        },
          function (result) {
            vm.customers = result;
            var customer = result.find(function (item) {
              return item.legalName === searchQuery.trim();
            });
            if (searchQuery && !customer) {
              result.unshift(transformCustomer(searchQuery));
            }
          },
          function (response) {
            if (response.status === 404) {
              vm.customers = Customer.query();
            }
          }
        );
      } else {
        Customer.query({}, function (result) {
          vm.customers = result;
        });
      }
    };

    // se il customer è stato selezionato in pagina bene, altrimenti settare le privacy policy ed inviare tutto al salvataggio

    function save() {
      if (vm.receipt && vm.receipt.shop && vm.receipt.shop.shopSignboard) {
        delete vm.receipt.shop.shopSignboard;
      }
      vm.receipt.creationDate = vm.receipt.buyingDate;
      if (vm.customerSelected && vm.customerSelected.id) {
        vm.receipt.customerId = vm.customerSelected.id;
        vm.isSaving = true;
        if (vm.receipt.purchasedProducts && vm.receipt.purchasedProducts.length) {
          vm.receipt.purchasedProducts = vm.receipt.purchasedProducts.map(function (purchasedProduct) {
            if (purchasedProduct.product.idxId) {
              purchasedProduct.allProductId = purchasedProduct.product.idxId;
              purchasedProduct.product = {};
            }
            return purchasedProduct;
          });
        }
        if (vm.receipt.id !== null) {
          Receipt.update(vm.receipt, onSaveSuccessReceipt, onSaveErrorReceipt);
        } else {
          Receipt.save(vm.receipt, onSaveSuccessReceipt, onSaveErrorReceipt);
        }
      } else {
        saveNewCustomer();
      }
    }

    function onSaveSuccessReceipt(result) {
      var receiptId = result.id;
      vm.receipt.id = result.id;
      var promiseArray = [];
      if (vm.receipt.purchasedProducts && vm.receipt.purchasedProducts.length &&
        result.purchasedProducts && result.purchasedProducts.length) {
        for (var i = 0; i < result.purchasedProducts.length; i++) {
          if (vm.receipt.purchasedProducts[i] && vm.receipt.purchasedProducts[i].receiptFiles &&
            vm.receipt.purchasedProducts[i].receiptFiles.length && result.purchasedProducts[i].id) {
            vm.receipt.purchasedProducts[i].receiptFiles.map(function (file) {
              file.purchasedProductId = result.purchasedProducts[i].id;
              return file;
            });
            vm.receiptFiles = vm.receiptFiles.concat(vm.receipt.purchasedProducts[i].receiptFiles);
          }
          if (vm.receipt.purchasedProducts[i].warrantyConventional && result.purchasedProducts[i].id) {
            vm.receipt.purchasedProducts[i].warrantyConventional.purchasedProductId = result.purchasedProducts[i].id;
            vm.receipt.purchasedProducts[i].warrantyConventional.receiptId = receiptId;
            promiseArray.push(saveUpdatePurchasedProductWarranty(vm.receipt.purchasedProducts[i].warrantyConventional));
          }
        }
      }
      if (vm.receiptFiles && vm.receiptFiles.length) {
        vm.receiptFiles.forEach(function (receiptFile) {
          if (receiptFile.fileDataChanged) {
            receiptFile.fileData = receiptFile.fileDataChanged;
          }
          delete receiptFile.fileDataChanged;
          delete receiptFile.changed;
          receiptFile.receiptId = receiptId;
          promiseArray.push(ReceiptFile.save(receiptFile, function (result) {
            vm.images.push(result);
            if (result) {
              vm.image = result;
              vm.images.push(result);
            }
          }).$promise);
        });
      }
      $q.all(promiseArray).then(function () {
        $scope.$emit("garanteasyApp:receiptUpdate", result);
        vm.isSaving = false;
        $state.go("receipt", null, {
          reload: true
        });
      });
    }

    function onSaveErrorReceipt(error) {
      vm.error = error;
      vm.isSaving = false;
      AlertService.error(error.data.message);
    }

    function saveNewCustomer() {
      vm.customer.legalName = '';
      if (vm.customer.surname) {
        vm.customer.legalName += vm.customer.surname + ' ';
      }
      if (vm.customer.name) {
        vm.customer.legalName += vm.customer.name;
      }
      vm.customer = {
        email: vm.customer.email,
        login: vm.customer.email,
        legalName: vm.customer.legalName,
        business: false,
        appKey: APPKEY,
        langKey: "it",
        merchantCode: vm.merchant.code,
        merchantPrivacyPolicies: vm.merchantPrivacyPolicies
      };
      Customer.saveCustomerFromReceipt(
        vm.customer,
        onSaveCustomerSuccess,
        onSaveCustomerError
      );
    }

    function onSaveCustomerSuccess(data) {
      vm.customerSelected = data;
      save();
    }

    function onSaveCustomerError(error) {
      vm.error = error;
      $log.error(error);
    }

    function isDisabled() {
      if (!vm.receipt.buyingDate ||
        !vm.receipt.receiptNumber ||
        !vm.receipt.shop ||
        !vm.merchant
      ) {
        return true;
      } else {
        if (vm.customerSelected) {
          return false;
        } else {
          if (!vm.customer.surname || !vm.customer.email) {
            return true;
          }
        }
      }
    }

    vm.updateCustomerData = function () {
      $log.debug("customer selezionato", vm.customerSelected);
      if (vm.customerSelected) {
        if (!vm.customerSelected.id) {
          vm.customer.email = vm.customerSelected.legalName;
          vm.customer.legalName = "";
          return;
        }
        vm.customer.name = vm.customerSelected.legalName
          .split(" ")
          .slice(0, -1)
          .join(" ");
        vm.customer.surname = vm.customerSelected.legalName
          .split(" ")
          .slice(-1)
          .join(" ");
        vm.customer.email = vm.customerSelected.user.login;
      } else {
        vm.customer.name = "";
        vm.customer.surname = "";
        vm.customer.email = "";
      }
    };

    vm.removePurchasedProduct = function (index) {
      vm.receipt.purchasedProducts.splice(index, 1);
    };

    function disableAddProduct() {
      if (vm.receipt.purchasedProduct == null || vm.receipt.purchasedProduct.product == null || vm.receipt.purchasedProduct.product.name == null ||
        (vm.receipt.purchasedProduct.product.eanCodes == null && vm.receipt.purchasedProduct.product.eanCode == null) ||
        vm.receipt.purchasedProduct.product.brand == null ||
        (vm.receipt.gift === 'Y' && vm.receipt.purchasedProduct.giftReceiverEmail == null) ||
        (vm.receipt.purchasedProduct.typeCode !== null && vm.receipt.purchasedProduct.typeCode === 'EAN' && vm.isValidCode === false)
      ) {
        return true;
      } else return false;
    }

    $translate('garanteasyApp.receipt.detail.required').then(function (headline) {
      vm.messageValidationRequired = headline;
      vm.message = headline;
    }, function (translationId) {
      $log.error("Missing translation for", translationId);
    });

    $translate('garanteasyApp.receipt.receiptNewPage.codeValidation').then(function (headline) {
      vm.messageValidationCodeNotValid = headline;
    }, function (translationId) {
      $log.error("Missing translation for", translationId);
    });

    vm.deleteFile = function (receiptFile, isProduct) {
      var index;
      if (isProduct) {
        index = vm.receipt.purchasedProduct.receiptFiles.indexOf(receiptFile);
        return vm.receipt.purchasedProduct.receiptFiles.splice(index, 1);
      }
      index = vm.receiptFiles.indexOf(receiptFile);
      if (!receiptFile.id) {
        vm.receiptFile = {};
        vm.receiptFiles.splice(index, 1);
      } else {
        ReceiptFile.delete({
          id: receiptFile.id
        }, function () {
          vm.receiptFiles.splice(index, 1);
        });
      }
    };



    function ccc13() {
      if (vm.receipt.purchasedProduct.typeCode !== null && vm.receipt.purchasedProduct.typeCode === 'EAN') {
        var factor = 3;
        var sum = 0;
        // var form = {};
        var number = '' + vm.receipt.purchasedProduct.product.eanCode;
        if (number === 'null') {
          vm.message = vm.messageValidationRequired;
        } else
          if (number.length !== 13) {
            vm.isValidCode = false;
            vm.message = vm.messageValidationCodeNotValid;
          } else
            if (number.length === 13) {
              vm.result = number.substring(0, 12);
              for (var index = 12; index > 0; --index) {
                sum = sum + number.substring(index - 1, index) * factor;
                factor = 4 - factor;
              }
              if (number.length !== 13) {
                vm.validationCodeMessage = 'FAI ATTENZIONE! Introdurre 12 digits';
              } else {
                var cc = ((1000 - sum) % 10);
                vm.result = vm.result + cc;
              }
              if (number === vm.result) {
                vm.isValidCode = true;
              } else {
                vm.isValidCode = false;
                vm.message = vm.messageValidationCodeNotValid;
              }
            }
      } else vm.isValidCode = true;
    }

    function addImageToProduct() {
      if (!vm.receipt.purchasedProduct.receiptFiles) {
        vm.receipt.purchasedProduct.receiptFiles = [];
      }
      var index = vm.receipt.purchasedProduct.receiptFiles.indexOf(vm.receiptFile);
      if (index !== -1) {
        vm.receipt.purchasedProduct.receiptFiles[index] = vm.receiptFile;
      } else {
        vm.receipt.purchasedProduct.receiptFiles.push(vm.receiptFile);
      }
      vm.receiptFile = null;
      vm.activeSlide = 4;
    }

    function getImageProductUrl(type) {
      var image;
      if (type === 'product-resource') {
        if (!vm.receipt.purchasedProduct.product || !vm.receipt.purchasedProduct.product.images || !vm.receipt.purchasedProduct.product.images.length) {
          return 'content/images/img-placeholder.jpg';
        } else {
          image = vm.receipt.purchasedProduct.product.images[0];
          return 'api/product-resources/image/' + image.id;
        }
      }
      if (!vm.receipt.purchasedProduct.receiptFiles || !vm.receipt.purchasedProduct.receiptFiles.length) {
        return null;
      }
      image = vm.receipt.purchasedProduct.receiptFiles.find(function (file) {
        return file.typeForProduct === type;
      });
      if (image && image.fileData && image.fileContentType) {
        return 'data:' + image.fileContentType + ';base64,' + image.fileData;
      }
      return;
    }

    function addPurchasedProduct(saveReceipt) {
      vm.addProduct = false;
      if (
        angular.isDefined(vm.receipt.purchasedProduct) &&
        angular.isDefined(vm.receipt.purchasedProduct.product)
      ) {
        /* if (angular.isUndefined(vm.receipt.purchasedProduct.giftMakerDescription)) {
          vm.receipt.purchasedProduct.giftMakerDescription = vm.receipt.customer.legalName;
        } */
        var index = vm.receipt.purchasedProducts.indexOf(vm.receipt.purchasedProduct);
        if (index === -1) {
          vm.receipt.purchasedProducts.push(vm.receipt.purchasedProduct);
        } else {
          vm.receipt.purchasedProducts[index] = vm.receipt.purchasedProduct;
        }
        vm.receipt.purchasedProduct = {};
      }
      vm.receipt.numProducts = vm.receipt.purchasedProducts.length;
      vm.noFestina();
      if (saveReceipt) {
        save();
      } else {
        vm.addProduct = true;
        vm.activeSlide = 4;
      }
    }

    function onUpdateConventionalWarranty(purchasedProductWarranty) {
      vm.receipt.purchasedProduct.warrantyConventional = Object.assign({}, purchasedProductWarranty);
    }

    function saveUpdatePurchasedProductWarranty(purchasedProductWarranty) {
      var promise = new Promise(function (resolve) {
        resolve();
      });
      if (!purchasedProductWarranty.warranty.id) {
        if (!purchasedProductWarranty.warranty.description) {
          purchasedProductWarranty.warranty.description = '';
        }
        promise = Warranty.save(purchasedProductWarranty.warranty).$promise;
      } else if (purchasedProductWarranty.warranty.id && purchasedProductWarranty.warranty.code === 'CUSTOM') {
        promise = Warranty.update(purchasedProductWarranty.warranty).$promise;
      }
      if (!purchasedProductWarranty.id) {
        promise = promise.then(function (warranty) {
          purchasedProductWarranty.warrantyId = purchasedProductWarranty.warrantyId || warranty.id;
          return PurchasedProductWarranty.save(purchasedProductWarranty).$promise;
        });
      } else {
        promise = promise.then(function (warranty) {
          purchasedProductWarranty.warrantyId = purchasedProductWarranty.warrantyId || warranty.id;
          return PurchasedProductWarranty.update(purchasedProductWarranty).$promise;
        });
      }
      return promise.then(function (result) {
        purchasedProductWarranty = result;
        return purchasedProductWarranty;
      });
    }

    function transformCustomer(new_value) {
      var new_customer = {};
      new_customer.legalName = new_value;

      return new_customer;
    }

    function checkValidMail() {
      var exp = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]*\.([a-z]{2,4})$/;
      var email;
      if (vm.customerSelected && vm.customerSelected.legalName) {
        email = vm.customerSelected.legalName;
      }
      if (exp.test(email)) {
        vm.validEmail = true;
      } else {
        vm.validEmail = false;
      }
    }

    function onPpSuccess(privacyPolicy) {
      if (privacyPolicy) {
        vm.merchantPrivacyPolicies = [];
        vm.merchantPrivacyPolicies[0] = {
          approved: true,
          code: privacyPolicy[0].code,
          mandatory: true,
          merchant: vm.merchant,
          merchantCode: vm.merchant.code,
          policyId: privacyPolicy[0].id
        };
      }
    }

    function onPpError(error) {
      vm.error = error;
      $log.error(error);
    }

    function noFestina() {
      vm.isFestina = false;
      $scope.products = [];
    }

    function saveStepReceiptFile() {
      if(vm.isBasic) {
        vm.save();
      } else {
        vm.activeSlide = 4;
        vm.addProduct = true;
      }
    }

  }
})();
