(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('ShopController', ShopController);

    ShopController.$inject = ['$scope', '$state', 'Principal', 'AlertService', 'SelectedMerchantService', 'bsScreenSize', 'DataUtils'];

    function ShopController($scope, $state, Principal, AlertService, SelectedMerchantService, bsScreenSize, DataUtils) {
        var vm = this;
        vm.accountId = null;
        vm.isAdmin = null;
        vm.gridView = true;
        vm.abbreviate = DataUtils.abbreviate;
        vm.predicate = 'id';


        if (bsScreenSize.state === 'md' || bsScreenSize.state === 'lg') {
            vm.gridView = false;
        }
        vm.model = {
            get merchant() {
                return SelectedMerchantService.merchant;
            },
            set merchant(item) {
                return SelectedMerchantService.merchant = item;
            },
            get shop() {
                return SelectedMerchantService.shop;
            },
            set shop(item) {
                return SelectedMerchantService.shop = item;
            }
        };
        checkAccess();

        function checkAccess() {
            Principal.identity().then(function (account) {
                if (!Principal.isAuthenticated()) {
                    $state.go("home");
                    return;
                }
                vm.accountId = account.id;
                Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                    if (result) {
                        vm.isAdmin = true;
                    } else {
                        vm.isAdmin = false;
                    }
                });
            });
        }
        function compare(a, b) {
            var arr = vm.predicate.split(".");
            var objA = a[arr[0]];
            var objB = b[arr[0]];
            if (arr[1]) {
                objA = objA[arr[1]];
                objB = objB[arr[1]];
            }

            if (objA < objB) {
                return vm.reverse ? -1 : 1;
            }
            if (objA > objB) {
                return vm.reverse ? 1 : -1;
            }
            return 1;
        }
        vm.shops = SelectedMerchantService.shops;
        vm.transition = function () {
            vm.shops.sort(compare);
        }

        SelectedMerchantService.onShopChange(function (data) {
            vm.shops = SelectedMerchantService.shops;
        });
    }
})();
