(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('ShopDialogController', ShopDialogController);

    ShopDialogController.$inject = ['$scope', 'AlertService', '$stateParams', 'ShopService', '$window', 'BrandLookup', 'Brand', 'SelectedMerchantService'];

    function ShopDialogController($scope, AlertService, $stateParams, ShopService, $window, BrandLookup, Brand, SelectedMerchantService) {
        var vm = this;
        vm.currentBrand = {};
        vm.currentShop = $stateParams.shop ? JSON.parse($stateParams.shop) : {};
        vm.selectedMerchant;
        vm.merchantListner;
        if (vm.currentShop) {
            vm.shop_ecommerce = vm.currentShop.ecommerceUrl ? 'ecommerce' : 'shop';
            vm.currentBrand = vm.currentShop.shopSignboard;
        }
        vm.getMerchants = function () {
            return SelectedMerchantService.merchants;
        }
        setDefaultMerchant();
        function setDefaultMerchant() {
            vm.selectedMerchant = SelectedMerchantService.merchant;
            vm.merchantListner = SelectedMerchantService.onMerchantChange(function (data) {
                vm.selectedMerchant = data;
                vm.merchantListner();
            })
            $scope.$on('$destroy', function () {
                vm.merchantListner();
            });

        }
        vm.clear = function () {
            $window.history.back();
        }

        vm.save = function () {
            vm.isSaving = true;
            vm.currentShop.signboard = vm.getBrand();
            vm.currentShop.merchant = vm.selectedMerchant;
            vm.currentShop.code = vm.currentShop.vatCode;
            delete (vm.currentShop.shopSignboard);
            if (vm.currentShop.id) {
                ShopService.update(vm.currentShop, onSaveSuccess, onSaveError);
            } else {
                ShopService.save(vm.currentShop, onSaveSuccess, onSaveError);
            }
        }

        vm.findBrands = function (searchQuery) {
            if (searchQuery) {
                BrandLookup.query({ query: searchQuery }, function (result) {
                    vm.brands = result;
                }, function (response) {
                    if (response.status === 404) {
                        vm.brands = Brand.query({ isActive: true });
                    }
                });
            } else {
                vm.brands = Brand.query({ isActive: true });
            }
            (vm.currentBrand || {}).name = searchQuery;
        };

        vm.getBrand = function () {
            if ((vm.currentBrand || {}).id) {
                return {
                    id: vm.currentBrand.id
                };
            } else {
                return {
                    name: (vm.currentBrand || {}).name
                };
            }
        };
        vm.brandIsSelected = function () {
            return (vm.currentBrand || {}).id || (vm.currentBrand || {}).name;
        }
        function onSaveSuccess(result) {
            vm.isSaving = false;
            SelectedMerchantService.loadShops(true);
            $window.history.back();
        }

        function onSaveError(error) {
            vm.isSaving = false;
            AlertService.error(error.data.message);
        }
    }
})();
