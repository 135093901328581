(function () {
  'use strict';

  var app = angular
    .module('garanteasyApp', [
      'ngStorage',
      'puigcerber.countryPicker',
      'tmh.dynamicLocale',
      'pascalprecht.translate',
      'ngResource',
      'ngCookies',
      'ngAria',
      'ngCacheBuster',
      'ngFileUpload',
      'ui.bootstrap',
      'ui.bootstrap.datetimepicker',
      'ui.router',
      'infinite-scroll',
      'ui.select',
      'ngSanitize',
      'ncy-angular-breadcrumb',
      // jhipster-needle-angularjs-add-module JHipster will add new module here
      'angular-loading-bar',
      'angular-elevate-zoom',
      'rt.debounce',
      'bs.screenSize',
      // 'textAngular',
      'angular-barcode',
      'jkuri.gallery'
    ])
    .filter('iconify', ['$sce', function ($sce) {
      return function (input, type) {
        var iconType = 'fas fa-file-invoice-dollar';
        if (input) {
          if (input.startsWith('image/')) {
            iconType = 'fas fa-file-image';
          } else if (input === 'application/pdf') {
            iconType = 'fas fa-file-pdf';
          } else if (input === 'application/msword' || input == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            iconType = 'fas fa-file-word';
          } else if (input === 'application/zip') {
            iconType = 'fas fa-file-archive';
          } else if (input.startsWith('text/')) {
            iconType = 'fas fa-file-alt';
          } else if (input === 'application/vnd.ms-excel' || input == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            iconType = 'fas fa-file-excel';
          }
        } else {
          input = '';
        }

        var out = '<i class="' + input + ' fa fa-2x ' + iconType + '" aria-hidden="true"></i>';
        return $sce.trustAs(type || 'html', out);
      }
    }])
    .run(run);
  app.config(['$locationProvider', function ($locationProvider) {
    $locationProvider.hashPrefix('!');
  }]);
  app.config(['pvpCountriesProvider', function (pvpCountriesProvider) {
    pvpCountriesProvider.setCountries(
      [
        {
          name: "Åland Islands",
          alpha2: "AX"
        },
        {
          name: "Austria",
          alpha2: "AT"
        },
        {
          name: "Belgium",
          alpha2: "BE"
        },
        {
          name: "Bulgaria",
          alpha2: "BG"
        },
        {
          name: "Croatia",
          alpha2: "HR"
        },
        {
          name: "Cyprus",
          alpha2: "CY"
        },
        {
          name: "Czech Republic",
          alpha2: "CZ"
        },
        {
          name: "Denmark",
          alpha2: "DK"
        },
        {
          name: "Estonia",
          alpha2: "EE"
        },
        {
          name: "Faroe Islands",
          alpha2: "FO"
        },
        {
          name: "Finland",
          alpha2: "FI"
        },
        {
          name: "France",
          alpha2: "FR"
        },
        {
          name: "French Guiana",
          alpha2: "GF"
        },
        {
          name: "Germany",
          alpha2: "DE"
        },
        {
          name: "Gibraltar",
          alpha2: "GI"
        },
        {
          name: "Greece",
          alpha2: "GR"
        },
        {
          name: "Hungary",
          alpha2: "HU"
        },
        {
          name: "Ireland",
          alpha2: "IE"
        },
        {
          name: "Isle of Man",
          alpha2: "IM"
        },
        {
          name: "Italy",
          alpha2: "IT"
        },
        {
          name: "Latvia",
          alpha2: "LV"
        },
        {
          name: "Lithuania",
          alpha2: "LT"
        },
        {
          name: "Luxembourg",
          alpha2: "LU"
        },
        {
          name: "Malta",
          alpha2: "MT"
        },
        {
          name: "Netherlands",
          alpha2: "NL"
        },
        {
          name: "Poland",
          alpha2: "PL"
        },
        {
          name: "Portugal",
          alpha2: "PT"
        },
        {
          name: "Romania",
          alpha2: "RO"
        },
        {
          name: "Slovakia",
          alpha2: "SK"
        },
        {
          name: "Slovenia",
          alpha2: "SI"
        },
        {
          name: "Spain",
          alpha2: "ES"
        },
        {
          name: "Sweden",
          alpha2: "SE"
        },
        {
          name: "United Kingdom of Great Britain and Northern Ireland",
          alpha2: "GB"
        }
      ]
    );
  }]);

  run.$inject = ['stateHandler', 'translationHandler'];

  function run(stateHandler, translationHandler) {
    stateHandler.initialize();
    translationHandler.initialize();
  }

  app.run(['$rootScope', '$http', '$location', '$state', function ($rootScope, $http, $location, $state) {

    $state.defaultErrorHandler(function (error) {
      // This is a naive example of how to silence the default error handler.
      console.log(error);
    });
    //Variabile in root scope usata per condividere dati nei diversi stati tra sidebar/content
    $rootScope.stateData = {};
    $rootScope._ = window._;
    $rootScope.showSearchBar = true;
    $rootScope.inputSearch = true;
    $rootScope.gridView = $rootScope.bsScreenSize.state === 'sm' || $rootScope.bsScreenSize.state === 'xs';

    var globalSearchChange = $rootScope.$on('dashboardSearchChange', function (evt, data) {
      console.log('dispatch');
      if (!(typeof data.s === 'string' || data.s instanceof String)) {
        data.s = JSON.stringify(data.s);
      }
      // add random item to force router change
      data.r = Math.random();
      if ((data || {}).search) {
        $state.go("search", data);
      }
    });

    $rootScope.$on('$destroy', function (event, next, current) {
      globalSearchChange();
    });

    $rootScope.datepickerOptions = {
      showWeeks: false
    };

    $rootScope.getFromUrl = function (path, destObj, field) {

      if (field) {
        if (!destObj[field])
          destObj[field] = {};
        destObj = destObj[field];
      }

      if (destObj && angular.isDefined(destObj.data))
        return destObj;

      destObj.data = null;

      $http.get(path, {
        responseType: 'arraybuffer',
        headers: {
          'accept': 'image/webp,image/*,*/*;q=0.8'
        }
      })
        .then(function (response) {
          var blob = new Blob(
            [response.data],
            { type: response.headers('Content-Type') }
          );
          destObj.data = URL.createObjectURL(blob);

        });

    };

    $rootScope.printFromPage = function () {
      print();
    }

    $rootScope.noprint = function () {
      $(".no-print").remove();
    }
    $rootScope.getClass = function (path) {
      return ($location.path().substr(0, path.length) === path) ? 'active' : '';
    }

  }]);


})();
