(function () {
  "use strict";

  angular
    .module("garanteasyApp")
    .controller("ReceiptDetailController", ReceiptDetailController);



  ReceiptDetailController.$inject = [
    "$state",
    "$scope",
    "$rootScope",
    "$stateParams",
    "previousState",
    "ReceiptFile",
    "DataUtils",
    "entity",
    "Principal",
    "Receipt",
    "Customer",
    "Contact",
    "PurchasedProduct",
    "AuthServerProvider",
    '$window',
    'CmsService',
    '$translate'
  ];

  function ReceiptDetailController(
    $state,
    $scope,
    $rootScope,
    $stateParams,
    previousState,
    ReceiptFile,
    DataUtils,
    entity,
    Principal,
    Receipt,
    Customer,
    Contact,
    PurchasedProduct,
    AuthServerProvider,
    $window,
    CmsService,
    $translate
  ) {
    var vm = this;
    vm.reasons = [
      "EMAIL_UPDATE",
      "EMAIL_CHANGE",
      "RECEIPT",
      "DELETE_PROFILE",
      "OTHER"
    ];
    vm.printPdf = function () {
      var pdf = new $window.jsPDF();
      var content = document.getElementById('receipt-image-build-panel');
      pdf.addHTML(content, function () {
        pdf.save(vm.receipt.id + '.pdf');
      });
    };


    vm.receipt = entity;
    vm.customers = Customer.query();
    vm.contacts = Contact.queryActive();
    vm.saveGift = saveGift;
    vm.showBuildReceiptImage = showBuildReceiptImage;
    vm.printReceipt = printReceipt;
    vm.getImageUrl = getImageUrl;
    // Le ricevute inviate da un negozio ufficiale (o in attesa di conferma dopo un claim) sono quasi totalmente readonly
    vm.isOfficial =
      vm.receipt.source === "MERCHANT" ||
      vm.receipt.source === "CLAIM" ||
      vm.receipt.source === "CLAIM_RESOLVED";

    vm.isOwner = null;
    vm.isAdmin = null;
    vm.currentUserId;
    vm.images = [];
    vm.documents = [];
    vm.listSelected = [];
    vm.receiptFiles = [];
    vm.imagesPath = {};
    vm.cmsShop;

    vm.loadCmsShop = function (lang, cmsReference) {
      if (cmsReference) {
        CmsService.getShop(lang, cmsReference).then(function (res) {
          vm.cmsShop = res.data;
          var address = '';

          if (res.data.address_address_line1) {
            address += ' ' + res.data.address_address_line1;
          }
          if (res.data.address_address_line2) {
            address += ' ' + res.data.address_address_line2;
          }
          if (res.data.address_postal_code) {
            address += ' ' + res.data.address_postal_code;
          }
          if (res.data.address_locality) {
            address += ' ' + res.data.address_locality;
          }
          if (res.data.address_country_code) {
            address += ' ' + res.data.address_country_code;
          }

          vm.cmsShop.address = address;

        });
      }
    };
    vm.addPurchasedProductToListSelected = function () {
      if (
        vm.receipt.purchasedProducts &&
        vm.receipt.purchasedProducts.length === 1
      ) {
        vm.listSelected.splice(0, 0, vm.receipt.purchasedProducts[0]);
      }
    };
    vm.showCustomerName = function (customer) {
      return DataUtils.showCustomerName(customer);
    };
    vm.addPurchasedProductToListSelected();

    vm.enableButtonGift = function () {
      if (vm.receipt.purchasedProducts.length > 1) {
        return vm.listSelected.length > 0;
      } else return true;
    };

    vm.addToListSelected = function (index, pp) {
      if (vm.listSelected && vm.listSelected.indexOf(pp) < 0) {
        vm.listSelected.splice(index, 0, pp);
      } else if (vm.listSelected && vm.listSelected.indexOf(pp) >= 0) {
        for (var i = 0; i < vm.listSelected.length; i++) {
          if (vm.listSelected[i].id == pp.id) vm.listSelected.splice(i, 1);
        }
      }
    };
    init();
    /*        angular.forEach(vm.receipt.purchasedProducts, function (value) {
                    if (value) {
                        value.selected = false;
                    }
                });*/

    vm.goToGift = function () {
      $state.go("receipt/detail/editGift", {
        id: vm.receipt.id,
        listSelected: vm.listSelected
      });
    };

    vm.evaluateBusiness = function () {
      var value = vm.receipt.customer.business;
      if (angular.isUndefined(value) || value === null) {
        return 'nd';
      }
      if (value) {
        return 'professional';
      } else {
        return 'private';
      }
    }


    vm.actionReso = function () {
      if (vm.listSelected && vm.listSelected.length > 0) {
        $state.go("receipt/purchased-product-reso", {
          listSelected: vm.listSelected,
          id: vm.receipt.id,
          ppId: vm.listSelected[0].id
        });
      }
    };

    function saveGift() {
      vm.isSaving = true;
      if (vm.purchasedProduct.id !== null) {
        PurchasedProduct.update(
          vm.purchasedProduct,
          onSaveSuccess,
          onSaveError
        );
      } else {
        PurchasedProduct.save(vm.purchasedProduct, onSaveSuccess, onSaveError);
      }
    }

    function onSaveSuccess(result) {
      $scope.$emit("garanteasyApp:purchasedProductUpdate", result);
      // $uibModalInstance.close(result);
      vm.isSaving = false;
    }

    function onSaveError() {
      vm.isSaving = false;
    }

    if (vm.receipt.receiptFiles && vm.receipt.receiptFiles.length > 0) {
      vm.receipt.receiptFiles.forEach(function (item) {
        if (
          item.fileContentType &&
          item.fileContentType.toLowerCase().indexOf("image") == 0
        ) {
          vm.images.push(item);
        } else vm.documents.push(item);
      });
    }

    if (vm.images.length > 0) {
      vm.image = vm.images[0];
    }

    vm.changeMainImage = function (image) {
      $(".zoomContainer").remove();
      vm.image = image;
    };

    vm.checkAccess = checkAccess;
    checkAccess();

    vm.byteSize = DataUtils.byteSize;
    vm.openFile = DataUtils.openFile;

    function checkAccess() {
      Principal.identity().then(function (account) {
        if (!Principal.isAuthenticated()) {
          $state.go("home");
          return;
        }
        vm.currentUserId = account.id;
        if (vm.receipt.id && vm.receipt.customer && vm.receipt.customer.user) {
          vm.isOwner = vm.receipt.customer.user.id == account.id;
        } else {
          // New receipt
          vm.isOwner = true;
        }
        Principal.hasAuthority("ROLE_ADMIN").then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });
      }, onIdentityError());
    }

    function onIdentityError() {
      console.log("ERROR");
    }

    vm.canShow = function (contentType) {
      return (
        contentType != null &&
        contentType.toLowerCase().indexOf("image") == 0 &&
        contentType.toLowerCase().indexOf("tiff") == -1
      );
    };

    vm.deleteImage = function (image) {
      ReceiptFile.delete({
        id: image.id
      }, function () {
        var ind = vm.receipt.receiptFiles.indexOf(image);
        vm.receipt.receiptFiles.splice(ind, ind + 1);
        $(".zoomContainer").remove();
        vm.image = vm.receipt.receiptFiles[0];
      });
    };

    var unsubscribe = $rootScope.$on("garanteasyApp:receiptUpdate", function (
      event,
      result
    ) {
      vm.receipt = result;
    });

    $scope.$on("$destroy", unsubscribe);

    $(document).ready(function () {
      window.scrollTo(0, 0);
    });

    function init() {
      if (vm.receipt.receiptFiles && vm.receipt.receiptFiles.length > 0) {
        vm.receipt.receiptFiles.forEach(function (item) {
          if (!item.purchasedProductId) {
            item.src = getImageUrl(item);
            vm.receiptFiles.push(item);
          }
        });
        if ((vm.receipt.receiptType === 'RECEIPT' || !vm.receipt.receiptType || vm.receipt.receiptType === 'OFFICIAL_RECEIPT') && vm.receipt.shop) {
          vm.receiptFiles.push({
            fileContentType: 'build',
            receipt: vm.receipt
          });
        }
      }
      vm.loadCmsShop($translate.use(), vm.receipt.shop.cmsReference);
      $rootScope.$emit("enableMerchantSelector", false);
    }

    $scope.$on('$destroy', function dismissed() {
          $rootScope.$emit("enableMerchantSelector", true);
    })

    function showBuildReceiptImage() {
      return !vm.receiptFiles.length && vm.receipt.shop && !vm.isEdit && (vm.receipt.receiptType === 'RECEIPT' || !vm.receipt.receiptType || vm.receipt.receiptType === 'OFFICIAL_RECEIPT');
    }

    function printReceipt() {
      var content = document.getElementById('receiptPrintContent').innerHTML;
      var headContent = document.getElementsByTagName('head')[0].innerHTML;
      var popupWin = window.open('', '_blank');
      popupWin.document.open();
      var style = '.receipt-print{ max-width: 600px; } @media print {@page {size: A4 portrait; } .receipt-print{ max-width: 300px; margin: auto; padding: 0; }	}';
      popupWin.document.write('<html><head>' + headContent + '<style type="text/css">' + style + '</style></head><body onload="window.print()"><div class="receipt-print">' + content + '</div></body></html>');
      popupWin.document.close();
    }

    function getImageUrl(receiptFile) {
      if (!receiptFile || !receiptFile.id) {
        vm.imagesPath[receiptFile.id] = null;
      } else if (_.isUndefined(vm.imagesPath[receiptFile.id])) {
        vm.imagesPath[receiptFile.id] =
          "api/receipt-files/content/" +
          receiptFile.id +
          "?token=" +
          AuthServerProvider.getToken();
      }
      return vm.imagesPath[receiptFile.id];
    }

  }
})();
