angular
    .module('garanteasyApp')
    .component('cardCustomer', {
        templateUrl: 'app/components/cards/customer/customer.component.html',
        controller: CardCustomerComponentController,
        controllerAs: 'vm',
        bindings: {
            customer: '<'
        }
    });

CardCustomerComponentController.$inject = ['AuthServerProvider', 'DataUtils'];

function CardCustomerComponentController(AuthServerProvider, DataUtils) {
    var vm = this;
    vm.showCustomerName = function (customer) {
        return DataUtils.showCustomerName(customer);
    };

}
