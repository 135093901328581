(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .directive('backImg', function () {
            return function (scope, element, attrs) {
                var defaultImg = '/content/images/empty-product-placeholder.png';
                if (attrs.backImgDefault === 'shop') {
                    defaultImg = '/content/images/empty-shop-placeholder.png';
                } else if (attrs.backImgDefault === 'warranty') {
                    defaultImg = '/content/images/empty-warranty-placeholder.png';
                }
                var url = attrs.backImg ? attrs.backImg : defaultImg;
                element.css({
                    'background-image': 'url(' + url + ')'
                });
            };
        })
        .controller('ReceiptController', ReceiptController);
    ReceiptController.$inject = ['$state', 'DataUtils', 'Receipt', 'Principal', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', '$rootScope', 'Searchbar', 'bsScreenSize', 'SelectedMerchantService', '$scope'];

    function ReceiptController($state, DataUtils, Receipt, Principal, ParseLinks, AlertService, pagingParams, paginationConstants, $rootScope, Searchbar, bsScreenSize, SelectedMerchantService, $scope) {
        var vm = this;
        vm.receipts = [];
        vm.loadPage = loadPage;
        vm.page = 0;
        vm.gridView = $rootScope.gridView;
        vm.links = {
            last: 0
        };
        vm.isLoading = false;
        vm.reset = reset;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.screenSize = bsScreenSize.state;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.page = pagingParams.page;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.abbreviate = DataUtils.abbreviate;
        vm.transition = transition;
        vm.currentUserId = null;
        vm.isAdmin = null;

        vm.model = {
            get merchant() {
                return SelectedMerchantService.merchant;
            },
            set merchant(item) {
                return SelectedMerchantService.merchant = item;
            },
            get shop() {
                return SelectedMerchantService.shop;
            },
            set shop(item) {
                return SelectedMerchantService.shop = item;
            }
        };

        checkSize();

        vm.validWarranties = 0;
        vm.expiredWarranties = 0;
        vm.checkAccess = checkAccess;
        checkAccess();
        vm.showCustomerName = function (customer) {
            return DataUtils.showCustomerName(customer);
        };
        function checkAccess() {
            Principal.identity().then(function (account) {
                if (!Principal.isAuthenticated()) {
                    $state.go("home");
                    return;
                }
                vm.currentUserId = account.id;
                Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
                    if (result) {
                        vm.isAdmin = true;
                    } else {
                        vm.isAdmin = false;
                    }
                });
            });
        }

        vm.totalNumExpired = 0;
        vm.totalNumValid = 0;
        vm.totalNumExpiring = 0;
        loadAll();
        function checkSize() {
            if (vm.screenSize === 'md' || vm.screenSize === 'lg') {
                vm.gridView = false;
            }
        }

        function loadData(){
          vm.receipts = []
          loadAll()
        }

        function loadAll(stringSearch) {
            if (vm.isLoading) return;
            vm.isLoading = true;
            if (!$state.params.customerId) {
                Receipt.getReceiptsByMerchantAndShop({
                    merchant_id: vm.model.merchant && vm.model.merchant.id,
                    shop_id: vm.model.shop && vm.model.shop.id,
                    page: vm.page,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Receipt.getCustomerReceipts({
                    customer_id: $state.params.customerId,
                    page: vm.page,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            }

        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            if (headers('link')) {
                vm.links = ParseLinks.parse(headers('link'));
            }
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.itemsPerPage = 20;
            vm.receipts = vm.receipts.concat(data);
            vm.isLoading = false;
        }

        function onError(error) {
            vm.isLoading = false;
            AlertService.error(error.data.message);
        }

        vm.getValidReceipts = function () {
            Receipt.getValid({}, onSuccess, onError);
            vm.valid = vm.validReceipt;

            function onSuccess(data) {
                vm.validWarranties = data.valid;
                vm.expiredWarranties = data.expired;
                vm.expiringWarranties = data.expiring;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

        }

        vm.getValidReceipts();

        function loadPage(page) {
            vm.page = page;
            vm.loadAll();
        }

        function transition() {
            reset();
            vm.loadAll();
        }

        function reset() {
            vm.page = 0;
            vm.receipts = [];
        }

        function clear() {
            vm.links = null;
            vm.page = 0;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        vm.listenerMerchant = SelectedMerchantService.onMerchantChange(loadData);
        vm.listenerShop = SelectedMerchantService.onShopChange(loadData);

        $scope.$on('$destroy', function () {
            vm.listenerMerchant();
            vm.listenerShop();
        });

    }
})();
